import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ExportDefaultSection } from "."
import { ExportTeacherMotType } from "./ExportTeacher"

const ExportTeacherMOTStyles = StyleSheet.create({
  container: {
    marginBottom: "24pt",
  },
  row_container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5pt",
  },
  row_header: {
    fontWeight: "bold",
  },
  name: {
    width: 170,
  },
  type: {
    width: 130,
  },
  hours: {
    width: 50,
  },
  op: {
    width: 100,
  },
})

type ExportTeacherMOTProps = {
  data: ExportTeacherMotType[]
}
const ExportTeacherMOT = (props: ExportTeacherMOTProps) => {
  const { data } = props
  const styles = ExportTeacherMOTStyles

  return (
    <View style={styles.container}>
      <ExportDefaultSection name="MOT taken">
        <View style={{ ...styles.row_container, ...styles.row_header }}>
          <Text style={styles.name}>Naam</Text>
          <Text style={styles.type}>Type</Text>
          <Text style={styles.hours}>Uren</Text>
          <Text style={styles.op}>OP</Text>
        </View>
        {data.map((mot, key) => (
          <View key={key} style={styles.row_container}>
            <Text style={styles.name}>{mot.name}</Text>
            <Text style={styles.type}>{mot.type}</Text>
            <Text style={styles.hours}>{mot.hours}</Text>
            <Text style={styles.op}>
              {[mot.op1, mot.op2, mot.op3, mot.op4].map((op, i, a) =>
                op
                  ? `OP${i + 1}${
                      a.filter((o) => o).length > 1 && i !== 3 ? ", " : ""
                    }`
                  : ""
              )}
            </Text>
          </View>
        ))}
      </ExportDefaultSection>
    </View>
  )
}

export default ExportTeacherMOT
