import "./Loader.css"
import { CircularProgress, CircularProgressProps } from "@material-ui/core"
import React from "react"

type LoaderProps = {
  size?: CircularProgressProps["size"]
  style?: CircularProgressProps["style"]
}

export const Loader = (props: LoaderProps) => {
  const { size, style } = props

  return (
    <div className="loader">
      <CircularProgress size={size} style={style} />
    </div>
  )
}
