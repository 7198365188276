import { Divider, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import { object, string, ValidationError } from "yup"
import { snackbarTextState, snackbarVisibleState } from "../../state/app"
import {
  checkValidationErrors,
  Errors,
  ExceptionResponse,
  useFetchState,
} from "../../utils"
import { ErrorState } from "../ErrorState"
import { Loader } from "../Loader"
import { TeacherNote } from "../TeacherNote"
import { Button } from "../Buttons"

export type TeacherNoteType = {
  id: number
  curriculum_name: string
  note: string
  date: Date
}

export const TeacherNotes = () => {
  let { id }: { id: string } = useParams()

  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [note, setNote] = useState<string>("")
  const [errors, setErrors] = useState<Errors<{ note: string }>[]>([])

  const [createNoteStatus, setCreateNoteStatus] = useFetchState()
  const { data: dataTeacherNotes, error: errorTeacherNotes } = useSWR<
    TeacherNoteType[]
  >(`/api/teacher/${id}/notes`)
  const {
    data: dataTeacherNotesFromPreviousCurricula,
    error: errorTeacherNotesFromPreviousCurricula,
  } = useSWR<TeacherNoteType[]>(
    `/api/teacher/${id}/notes-from-previous-curricula`
  )

  const newNoteValidationObject = object({
    note: string()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
  })

  const saveNewNote = () => {
    newNoteValidationObject
      .validate({ note }, { abortEarly: false })
      .then(() => {
        setCreateNoteStatus("loading")
        fetch(`/api/teacher/${id}/note`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ note }),
        }).then(async (r) => {
          if (r.ok) {
            setCreateNoteStatus("success")
            setSnackbarText("De notitie is aangemaakt")
            setSnackbarVisible(true)
            mutate(`/api/teacher/${id}/notes`)
            setNote("")
          } else {
            setCreateNoteStatus("error")
            setSnackbarText("Er was een probleem bij het maken van een notitie")
            setSnackbarVisible(true)
          }
        })
      })
      .catch((e: ValidationError) => checkValidationErrors(e, setErrors))
  }

  return (
    <>
      <div className="card-content">
        <h2>Notities</h2>
        <div>
          <TextField
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            error={errors.some((e) => e.path === "note")}
            helperText={errors.find((e) => e.path === "note")?.message}
          />
        </div>
        <div>
          <Button
            fetchStatus={[createNoteStatus, setCreateNoteStatus]}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => saveNewNote()}
          >
            Sla notitie op
          </Button>
        </div>
        <Divider />
        <div>
          {!dataTeacherNotes ? (
            <Loader />
          ) : errorTeacherNotes ? (
            <ErrorState />
          ) : (
            <>
              {dataTeacherNotes.length <= 0 ? (
                <>
                  <span className="teacher-note-empty-state">
                    Er zijn geen notities in het{" "}
                    <strong>huidige curriculum</strong>. Maak er een aan.
                  </span>
                </>
              ) : (
                <>
                  {dataTeacherNotes.map((note, key) => (
                    <TeacherNote
                      key={`${key.toString()}-${note.id}`}
                      editable
                      note={note}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <Divider />
        <h6 className="subkop">Notities uit andere curricula</h6>
        <div>
          {!dataTeacherNotesFromPreviousCurricula ? (
            <Loader />
          ) : errorTeacherNotesFromPreviousCurricula ? (
            <ErrorState />
          ) : (
            <>
              {dataTeacherNotesFromPreviousCurricula.length <= 0 ? (
                <>
                  <span className="teacher-note-empty-state">
                    Er zijn geen notities in <strong>andere curricula</strong>.
                  </span>
                </>
              ) : (
                <>
                  {dataTeacherNotesFromPreviousCurricula.map((note, key) => (
                    <TeacherNote key={key} note={note} />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
