import "./CreateTeacherDialog.css"
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import {
  createDialogState,
  currentCurriculumState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import {
  checkArrayValidationErrors,
  checkValidationErrors,
  Errors,
  ErrorsArray,
  ExceptionResponse,
  useFetchState,
} from "../../utils"
import { Dialog } from "../Dialog"
import { ErrorState } from "../ErrorState"
import { Loader } from "../Loader"
import { CreateTeacherDialogMOTTable } from "./MOT"
import {
  teacherAanstellingMOTArrayValidationObject,
  teacherAanstellingMOTValidationObject,
  teacherAanstellingValidationObject,
  teacherDetailsValidationObject,
} from "./ValidationObjects"
import { ValidationError } from "yup"
import { Button } from "../Buttons"

export type TeacherDetailsType = {
  personnel_code: string
  first_name: string
  last_name: string
  isPlaceholder: boolean
  organisation: Organisation
  team: Team
  position: Position
}

export type Organisation = {
  id: number
  name: string
}

export type Team = {
  id: number
  name: string
}

export type Position = {
  id: number
  name: string
}

export type TeacherAanstellingType = {
  contract: "Vast" | "Tijdelijk"
  months_active: number
  fte_op1: number
  fte_op2: number
  fte_op3: number
  fte_op4: number
  di_op1: number
  di_op2: number
  di_op3: number
  di_op4: number
  organisational: number
  professional: number
  mot_tasks: MOTTask[]
}

export type MOTTask = {
  id: number
  name: string
  type: string
  hours: number
  active_op1: boolean
  active_op2: boolean
  active_op3: boolean
  active_op4: boolean
}

export type defaultFTE = {
  fte: number
}

export type defaultDI = {
  di: number
}

export type defaultOrganisational = {
  organisational: number
}

export type defaultProfessional = {
  professional: number
}

const getSteps = () => {
  return ["Docent details", "Aanstelling"]
}

export const CreateTeacherDialog = () => {
  const [createDialog, setCreateDialog] = useRecoilState(createDialogState)
  const currentCurriculum = useRecoilValue(currentCurriculumState)
  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)

  const [createTeacherStatus, setCreateTeacherStatus] = useFetchState()
  const [teacherDetails, setTeacherDetails] = useState<TeacherDetailsType>({
    personnel_code: "",
    first_name: "",
    last_name: "",
    isPlaceholder: false,
    organisation: { id: -1, name: "" },
    team: { id: -1, name: "" },
    position: { id: -1, name: "" },
  })
  const [teacherAanstelling, setTeacherAanstelling] = useState<
    TeacherAanstellingType
  >({
    contract: "Vast",
    months_active: 12,
    fte_op1: -1,
    fte_op2: -1,
    fte_op3: -1,
    fte_op4: -1,
    di_op1: -1,
    di_op2: -1,
    di_op3: -1,
    di_op4: -1,
    organisational: -1,
    professional: -1,
    mot_tasks: [],
  })
  const [detailsErrors, setDetailsErrors] = useState<
    Errors<TeacherDetailsType>[]
  >([])
  const [aanstellingErrors, setAanstellingErrors] = useState<
    Errors<TeacherAanstellingType>[]
  >([])
  const [errorsMOT, setErrorsMOT] = useState<ErrorsArray<MOTTask>[]>([])

  const {
    data: dataAvailableOrganisations,
    error: errorAvailableOrganisations,
  } = useSWR<Organisation[]>(`api/teacher/available-organisations`)
  const { data: dataAvailableTeams, error: errorAvailableTeams } = useSWR<
    Team[]
  >(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/available-teams`
      : null
  )
  const {
    data: dataAvailablePositions,
    error: errorAvailablePositions,
  } = useSWR<Position[]>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/available-positions`
      : null
  )
  const { data: dataDefaultFTE, error: errorDefaultFTE } = useSWR<defaultFTE>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/default-fte`
      : null
  )
  const { data: dataDefaultDI, error: errorDefaultDI } = useSWR<defaultDI>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/default-di`
      : null
  )
  const {
    data: dataDefaultOrganisational,
    error: errorDefaultOrganisational,
  } = useSWR<defaultOrganisational>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/default-organisational`
      : null
  )
  const {
    data: dataDefaultProfessional,
    error: errorDefaultProfessional,
  } = useSWR<defaultProfessional>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/default-professional`
      : null
  )

  useEffect(() => {
    if (createDialog) {
      setTeacherAanstelling({
        ...teacherAanstelling,
        fte_op1: dataDefaultFTE ? dataDefaultFTE.fte : -1,
        fte_op2: dataDefaultFTE ? dataDefaultFTE.fte : -1,
        fte_op3: dataDefaultFTE ? dataDefaultFTE.fte : -1,
        fte_op4: dataDefaultFTE ? dataDefaultFTE.fte : -1,
        di_op1: dataDefaultDI ? dataDefaultDI.di : -1,
        di_op2: dataDefaultDI ? dataDefaultDI.di : -1,
        di_op3: dataDefaultDI ? dataDefaultDI.di : -1,
        di_op4: dataDefaultDI ? dataDefaultDI.di : -1,
        organisational: dataDefaultOrganisational
          ? dataDefaultOrganisational.organisational
          : -1,
        professional: dataDefaultProfessional
          ? dataDefaultProfessional.professional
          : -1,
      })
    } else {
      setActiveStep(0)
      setTeacherDetails({
        personnel_code: "",
        first_name: "",
        last_name: "",
        isPlaceholder: false,
        organisation: { id: -1, name: "" },
        team: { id: -1, name: "" },
        position: { id: -1, name: "" },
      })
      setTeacherAanstelling({
        contract: "Vast",
        months_active: 12,
        fte_op1: -1,
        fte_op2: -1,
        fte_op3: -1,
        fte_op4: -1,
        di_op1: -1,
        di_op2: -1,
        di_op3: -1,
        di_op4: -1,
        organisational: -1,
        professional: -1,
        mot_tasks: [],
      })
    }
  }, [createDialog, dataDefaultFTE, dataDefaultDI])

  useEffect(() => {
    teacherDetailsValidationObject
      .validate(teacherDetails, { abortEarly: false })
      .then(() => setNextButtonDisabled(false))
      .catch(() => setNextButtonDisabled(true))
  }, [teacherDetails])

  const handleChangeDetails = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setTeacherDetails({
      ...teacherDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeNumberDetails = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setTeacherDetails({
      ...teacherDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const handleChangeCheckboxDetails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTeacherDetails({
      ...teacherDetails,
      [event.target.name]: event.target.checked,
    })
  }

  const handleChangeNumberAanstelling = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setTeacherAanstelling({
      ...teacherAanstelling,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const createTeacher = () => {
    teacherDetailsValidationObject
      .validate(teacherDetails, { abortEarly: false })
      .then(() => {
        setDetailsErrors([])
        teacherAanstellingValidationObject
          .validate(teacherAanstelling, { abortEarly: false })
          .then(() => {
            setAanstellingErrors([])
            teacherAanstellingMOTArrayValidationObject
              .validate(teacherAanstelling.mot_tasks, { abortEarly: false })
              .then(() => {
                setCreateTeacherStatus("loading")
                setErrorsMOT([])
                fetch(`/api/teacher/${currentCurriculum.id}/create`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ...teacherDetails,
                    ...teacherAanstelling,
                  }),
                }).then(async (r) => {
                  if (r.ok) {
                    setCreateTeacherStatus("success")
                    snackbarText("De docent is aangemaakt")
                    snackbarVisible(true)
                    setCreateDialog(false)
                    mutate(`/api/teacher/all/${currentCurriculum.id}`)
                  } else {
                    setCreateTeacherStatus("error")
                    const json = await r.json()
                    console.log(json)
                    snackbarText(
                      "Er was een probleem bij het aanmaken van de docent"
                    )
                    snackbarVisible(true)
                  }
                })
              })
              .catch((e: ValidationError) => {
                console.log(e)
                checkArrayValidationErrors(e, setErrorsMOT)
              })
          })
          .catch((e: ValidationError) =>
            checkValidationErrors(e, setAanstellingErrors)
          )
      })
      .catch((e: ValidationError) => checkValidationErrors(e, setDetailsErrors))
  }

  return (
    <Dialog
      innerDialogProps={{ maxWidth: "md" }}
      open={createDialog}
      onClose={() => setCreateDialog(false)}
      title="Maak docent aan"
      content={() => (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {getSteps().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <div className="dialog-column-2">
                <div className="card-content">
                  <div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Docentcode"
                      name="personnel_code"
                      value={teacherDetails.personnel_code}
                      onChange={(e) => handleChangeDetails(e)}
                      error={detailsErrors.some(
                        (e) => e.path === "personnel_code"
                      )}
                      helperText={
                        detailsErrors.find((e) => e.path === "personnel_code")
                          ?.message
                      }
                    />
                  </div>
                  <div className="column-2">
                    <TextField
                      variant="outlined"
                      label="Voornaam"
                      name="first_name"
                      value={teacherDetails.first_name}
                      onChange={(e) => handleChangeDetails(e)}
                      error={detailsErrors.some((e) => e.path === "first_name")}
                      helperText={
                        detailsErrors.find((e) => e.path === "first_name")
                          ?.message
                      }
                    />
                    <TextField
                      variant="outlined"
                      label="Achternaam"
                      name="last_name"
                      value={teacherDetails.last_name}
                      onChange={(e) => handleChangeDetails(e)}
                      error={detailsErrors.some((e) => e.path === "last_name")}
                      helperText={
                        detailsErrors.find((e) => e.path === "last_name")
                          ?.message
                      }
                    />
                  </div>
                  <div>
                    {!dataAvailablePositions ? (
                      <Loader />
                    ) : errorAvailablePositions ? (
                      <ErrorState />
                    ) : (
                      <Autocomplete
                        fullWidth
                        value={teacherDetails.position}
                        onChange={(e, v) =>
                          v !== null
                            ? setTeacherDetails({
                                ...teacherDetails,
                                position: v,
                              })
                            : setTeacherDetails({
                                ...teacherDetails,
                                position: { id: -1, name: "" },
                              })
                        }
                        options={dataAvailablePositions}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={"Geen resultaten"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Functie"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="card-content">
                  <div>
                    {!dataAvailableOrganisations ? (
                      <Loader />
                    ) : errorAvailableOrganisations ? (
                      <ErrorState />
                    ) : (
                      <Autocomplete
                        value={teacherDetails.organisation}
                        onChange={(e, v) =>
                          v !== null
                            ? setTeacherDetails({
                                ...teacherDetails,
                                organisation: v,
                              })
                            : setTeacherDetails({
                                ...teacherDetails,
                                organisation: { id: -1, name: "" },
                              })
                        }
                        options={dataAvailableOrganisations}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={"Geen resultaten"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Organisatie"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div>
                    {!dataAvailableTeams ? (
                      <Loader />
                    ) : errorAvailableTeams ? (
                      <ErrorState />
                    ) : (
                      <Autocomplete
                        value={teacherDetails.team}
                        onChange={(e, v) =>
                          v !== null
                            ? setTeacherDetails({ ...teacherDetails, team: v })
                            : setTeacherDetails({
                                ...teacherDetails,
                                team: { id: -1, name: "" },
                              })
                        }
                        options={dataAvailableTeams}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={"Geen resultaten"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Team"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div>
                    <FormControlLabel
                      id="teacher-placeholder"
                      control={
                        <Checkbox
                          checked={teacherDetails.isPlaceholder}
                          onChange={handleChangeCheckboxDetails}
                          name="isPlaceholder"
                          color="primary"
                        />
                      }
                      label="Docent is een placeholder:"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : activeStep === 1 ? (
            <>
              <div className="card-content">
                <div className="dialog-column-2">
                  <div>
                    <h6 className="subkop">
                      FTE per periode (Standaard per OP:{" "}
                      {typeof dataDefaultFTE !== "undefined" &&
                        dataDefaultFTE.fte}
                      )
                    </h6>
                    <div id="create-teacher-dialog-per-period-inputs">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP1"
                        name="fte_op1"
                        value={teacherAanstelling.fte_op1}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "fte_op1"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "fte_op1")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP2"
                        name="fte_op2"
                        value={teacherAanstelling.fte_op2}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "fte_op2"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "fte_op2")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP3"
                        name="fte_op3"
                        value={teacherAanstelling.fte_op3}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "fte_op3"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "fte_op3")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP4"
                        name="fte_op4"
                        value={teacherAanstelling.fte_op4}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "fte_op4"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "fte_op4")
                            ?.message
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      {/* <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        label="Maanden werkzaam"
                        name="months_active"
                        value={teacherAanstelling.months_active}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "months_active"
                        )}
                        helperText={
                          aanstellingErrors.find(
                            (e) => e.path === "months_active"
                          )?.message
                        }
                      /> */}
                    </div>
                    {/* <h6 className="subkop">
                      DI per periode (Standaard per OP:{" "}
                      {typeof dataDefaultDI !== "undefined" && dataDefaultDI.di}
                      )
                    </h6>
                    <div id="create-teacher-dialog-per-period-inputs">
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP1"
                        name="di_op1"
                        value={teacherAanstelling.di_op1}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "di_op1"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "di_op1")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP2"
                        name="di_op2"
                        value={teacherAanstelling.di_op2}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "di_op2"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "di_op2")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP3"
                        name="di_op3"
                        value={teacherAanstelling.di_op3}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "di_op3"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "di_op3")
                            ?.message
                        }
                      />
                      <TextField
                        variant="outlined"
                        type="number"
                        label="OP4"
                        name="di_op4"
                        value={teacherAanstelling.di_op4}
                        onChange={(e) => handleChangeNumberAanstelling(e)}
                        error={aanstellingErrors.some(
                          (e) => e.path === "di_op4"
                        )}
                        helperText={
                          aanstellingErrors.find((e) => e.path === "di_op4")
                            ?.message
                        }
                      />
                    </div> */}
                  </div>
                  <div>
                    <div>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="teacher-contract-label">
                          Type aanstelling
                        </InputLabel>
                        <Select
                          labelId="teacher-contract-label"
                          id="teacher-contract"
                          value={teacherAanstelling.contract}
                          onChange={(e) =>
                            setTeacherAanstelling({
                              ...teacherAanstelling,
                              contract: e.target
                                .value as TeacherAanstellingType["contract"],
                            })
                          }
                        >
                          <MenuItem value={"Vast"}>Vast</MenuItem>
                          <MenuItem value={"Tijdelijk"}>Tijdelijk</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="dialog-column-2">
                    <TextField
                      variant="outlined"
                      type="number"
                      label="Organisatorische taak ALG (%)"
                      name="organisational"
                      value={teacherAanstelling.organisational}
                      onChange={(e) => handleChangeNumberAanstelling(e)}
                      error={aanstellingErrors.some(
                        (e) => e.path === "organisational"
                      )}
                      helperText={
                        aanstellingErrors.find(
                          (e) => e.path === "organisational"
                        )?.message
                      }
                    />
                    <TextField
                      variant="outlined"
                      type="number"
                      label="Basisrecht professionalisering (%)"
                      name="professional"
                      value={teacherAanstelling.professional}
                      onChange={(e) => handleChangeNumberAanstelling(e)}
                      error={aanstellingErrors.some(
                        (e) => e.path === "professional"
                      )}
                      helperText={
                        aanstellingErrors.find((e) => e.path === "professional")
                          ?.message
                      }
                    />
                  </div>
                </div>
              </div>
              <Divider variant="middle" />
              <div className="card-content">
                <CreateTeacherDialogMOTTable
                  errors={errorsMOT}
                  mot_tasks={teacherAanstelling.mot_tasks}
                  setMOTTasks={(mot_tasks: MOTTask[]) =>
                    setTeacherAanstelling({ ...teacherAanstelling, mot_tasks })
                  }
                />
              </div>
            </>
          ) : (
            <>You're not supposed to be here 🤔</>
          )}
        </>
      )}
      actions={() => (
        <>
          <div className="dialog-actions-stepper">
            <Button
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Terug
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={nextButtonDisabled || createTeacherStatus.loading}
              onClick={() =>
                activeStep === getSteps().length - 1
                  ? createTeacher()
                  : setActiveStep(activeStep + 1)
              }
            >
              {activeStep === getSteps().length - 1 ? "Maak aan" : "Volgende"}
            </Button>
          </div>
        </>
      )}
    />
  )
}
