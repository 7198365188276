import "bootstrap/dist/css/bootstrap.css"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { SWRConfig } from "swr"
import { App } from "./App"
import registerServiceWorker from "./registerServiceWorker"
import { unregister } from "./registerServiceWorker"
import { RecoilRoot } from "recoil"
import DateFnsUtils from "@date-io/date-fns"
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

Sentry.init({
  dsn:
    "https://689b163bd9d04172a252baf89e53ad33@o485883.ingest.sentry.io/5541877",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0.0,
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CC0033",
    },
    secondary: {
      main: "#003399",
    },
  },
})

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")
const rootElement = document.getElementById("root")

ReactDOM.render(
  <BrowserRouter basename={baseUrl || ""}>
    <SWRConfig
      value={{
        fetcher: (...args: Parameters<typeof fetch>) =>
          fetch(...args).then((res) => res.json()),
        revalidateOnFocus: false,
      }}
    >
      <RecoilRoot>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </RecoilRoot>
    </SWRConfig>
  </BrowserRouter>,
  rootElement
)

// registerServiceWorker()
unregister()
