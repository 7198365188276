import React from "react"
import { Card, CardContent, CardProps, makeStyles } from "@material-ui/core"
import InfoRoundedIcon from "@material-ui/icons/InfoRounded"
import WarningRoundedIcon from "@material-ui/icons/WarningRounded"
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded"
import { hex2rgba } from "../../utils"

const useStylesCard = makeStyles({
  info: {
    borderColor: "#2196f3",
    backgroundColor: hex2rgba("#2196f3", 0.1),
  },
  warning: {
    borderColor: "#ff9800",
    backgroundColor: hex2rgba("#ff9800", 0.1),
  },
  error: {
    borderColor: "#f44336",
    backgroundColor: hex2rgba("#f44336", 0.1),
  },
})

type InformationCardProps = {
  type: "info" | "warning" | "error"
  disableIcon?: boolean
  content: string | (() => JSX.Element)
  style?: CardProps["style"]
}

const InformationCard = (props: InformationCardProps) => {
  const { type, disableIcon, content, style } = props
  const cardClass = useStylesCard()

  return (
    <Card className={cardClass[type]} variant="outlined" style={style}>
      <CardContent
        className={`information-card-content-${
          disableIcon ? "no-icon" : "with-icon"
        }`}
      >
        {!disableIcon && type === "info" ? (
          <InfoRoundedIcon htmlColor="#2196f3" />
        ) : type === "warning" ? (
          <WarningRoundedIcon htmlColor="#ff9800" />
        ) : type === "error" ? (
          <ErrorRoundedIcon htmlColor="#f44336" />
        ) : (
          <></>
        )}
        <div>{typeof content === "string" ? content : content()}</div>
      </CardContent>
    </Card>
  )
}

export default InformationCard
