import "./Curricula.css"
import React from "react"
import { Topbar } from "../../components/Layout"
import { CurriculaTable } from "../../components/CurriculaTable"
import { CurriculumDetails } from "../../components/CurriculumDetails"
import { CreateCurriculumDialog } from "../../components/CreateCurriculumDialog"
import { useHistory } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { searchbarState, createDialogState } from "../../state/app"

export const Curricula = () => {
  const setSearchbar = useSetRecoilState(searchbarState)
  const setCreateDialog = useSetRecoilState(createDialogState)

  let history = useHistory()
  history.listen(() => {
    setCreateDialog(false)
    setSearchbar("")
  })

  return (
    <div id="content-container" className="topbar">
      <Topbar searchbar createDialog="Maak curriculum aan" />
      <div id="content" className="content-divided-2">
        <div id="curricula-overview" className="card-container">
          <CurriculaTable />
        </div>
        <div id="curricula-details" className="card-container">
          <CurriculumDetails />
        </div>
      </div>
      <CreateCurriculumDialog />
    </div>
  )
}
