import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import "./App.css"

import { NavigationBar } from "./components/NavigationBar"
import { AuthManager } from "./components/AuthManager"
import { Planningsoverview } from "./pages/Planningsoverview"
import { Teachers } from "./pages/Teachers"
import { Courses } from "./pages/Courses"
import { Curricula } from "./pages/Curricula"
import { Settings } from "./pages/Settings"
import Snackbar from "@material-ui/core/Snackbar"
import { useRecoilState, useRecoilValue } from "recoil"
import { snackbarTextState, snackbarVisibleState } from "./state/app"
import { TeacherDetails } from "./pages/TeacherDetails"

export const App = () => {
  const snackbarText = useRecoilValue(snackbarTextState)
  const [snackbarVisibility, setSnackbarVisibility] = useRecoilState(
    snackbarVisibleState
  )

  return (
    <AuthManager>
      <Router>
        <div id="app-container">
          <NavigationBar />
          <div id="app-content">
            <Route path="/" component={Planningsoverview} exact />
            <Route path="/teachers" component={Teachers} exact />
            <Route path="/teachers/:id" component={TeacherDetails} />
            <Route path="/courses" component={Courses} />
            <Route path="/curricula" component={Curricula} />
            {/* <Route path="/settings" component={Settings} /> */}
          </div>
        </div>
      </Router>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarVisibility}
        autoHideDuration={6000}
        onClose={(
          event: React.SyntheticEvent | React.MouseEvent,
          reason?: string
        ) => {
          if (reason === "clickaway") {
            return
          }
          setSnackbarVisibility(false)
        }}
        message={snackbarText}
      />
    </AuthManager>
  )
}
