import useSWR from "swr"

export type useGetAllCurricula = { id: number; name: string; kinds: string[] }[]
type useGetAllCurriculaProps = {}

export const useGetAllCurricula = (props?: useGetAllCurriculaProps) => {
  const { data, error } = useSWR<useGetAllCurricula>("/api/app/all-curricula")

  return {
    data,
    error,
    isLoading: !error && !data,
  }
}
