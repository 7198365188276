import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ExportDefaultSection, pageDefaultStyles } from "."
import { ExportTeacherOnderwijstakenType } from "./ExportTeacher"

const ExportTeacherOnderwijstakenStyles = StyleSheet.create({
  table_header_container: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10pt",
    marginBottom: "5pt",
    fontWeight: "bold",
  },
  op: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "25pt",
  },
  course: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "75pt",
  },
  name: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "185pt",
  },
  vd: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "25pt",
    marginLeft: "5pt",
  },
  workmethod: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "65pt",
  },
  student_amount: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "55pt",
    marginRight: "5pt",
  },
  contact_student: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "60pt",
  },
  meetings: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "65pt",
  },
  meeting_duration: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "50pt",
  },
  raise_factor: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "50pt",
  },
  correction_time: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "65pt",
    marginRight: "5pt",
  },
  travel_time: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    width: "40pt",
  },
  body_row: {
    alignSelf: "flex-start",
  },
  table_row_container: {
    marginVertical: "2.5pt",
    display: "flex",
    flexDirection: "row",
    fontSize: "10pt",
  },
})

type ExportTeacherOnderwijstakenProps = {
  onderwijstaken: ExportTeacherOnderwijstakenType[]
}

export const ExportTeacherOnderwijstaken = (
  props: ExportTeacherOnderwijstakenProps
) => {
  const { onderwijstaken } = props
  const styles = ExportTeacherOnderwijstakenStyles

  return (
    <ExportDefaultSection name="Onderwijstaken">
      <View style={styles.table_header_container}>
        <Text style={styles.op}>OP</Text>
        <Text style={styles.course}>Cursus</Text>
        <Text style={styles.name}>Naam</Text>
        <Text style={styles.vd}>V/D</Text>
        <Text style={styles.workmethod}>Werkvorm</Text>
        <View style={styles.student_amount}>
          <Text>Aantal</Text>
          <Text>studenten</Text>
        </View>
        <Text style={styles.contact_student}>
          Contacttijd incl. opslag (uren)
        </Text>
        <Text style={styles.meetings}>Aantal bijeenkomsten</Text>
        <Text style={styles.meeting_duration}>Contact duur (min.)</Text>
        <Text style={styles.raise_factor}>Opslag factor</Text>
        <Text style={styles.correction_time}>Toets correctietijd (min.)</Text>
        <Text style={styles.travel_time}>Reistijd</Text>
      </View>
      {onderwijstaken.map((task, key) => (
        <View style={styles.table_row_container} key={key}>
          <Text style={{ ...styles.op, ...styles.body_row }}>
            OP{task.period}
          </Text>
          <Text style={{ ...styles.course, ...styles.body_row }}>
            {task.course_code}
          </Text>
          <Text style={{ ...styles.name, ...styles.body_row }}>
            {task.course_name}
          </Text>
          <Text style={{ ...styles.vd, ...styles.body_row }}>
            {task.curriculum_kind === "Voltijd" ? "VT" : "DT"}
          </Text>
          <Text style={{ ...styles.workmethod, ...styles.body_row }}>
            {task.workmethod}
          </Text>
          <Text style={{ ...styles.student_amount, ...styles.body_row }}>
            {task.student_amount}
          </Text>
          <Text style={{ ...styles.contact_student, ...styles.body_row }}>
            {task.contact_student}
          </Text>
          <Text style={{ ...styles.meetings, ...styles.body_row }}>
            {task.meetings}
          </Text>
          <Text style={{ ...styles.meeting_duration, ...styles.body_row }}>
            {task.meeting_duration}
          </Text>
          <Text style={{ ...styles.raise_factor, ...styles.body_row }}>
            {task.raise_factor}
          </Text>
          <Text style={{ ...styles.correction_time, ...styles.body_row }}>
            {task.correction_time}
          </Text>
          <Text style={{ ...styles.travel_time, ...styles.body_row }}>
            {task.travel_time}
          </Text>
        </View>
      ))}
    </ExportDefaultSection>
  )
}

export default ExportTeacherOnderwijstaken
