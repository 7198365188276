import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  createDialogState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import { Dialog } from "../Dialog"
import { Button } from "../Buttons"
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete"
import useSWR, { mutate } from "swr"
import { checkValidationErrors, Errors, useFetchState } from "../../utils"
import { ValidationError } from "yup"
import {
  CourseDetailsType,
  CourseOwner,
  CourseOwnerOptions,
  Team,
  TeamOptions,
  Workmethod,
} from "../CourseDetails"
import { courseDetailsValidationObject } from "../CourseDetails/ValidationObjects"
import { currentCurriculumAndKindObject } from "../../state/planning"

type DefaultValues = {
  meetings: number
  contact_students: number
  raise_factor: number
  correction_time: number
  travel_time: number
}

export const CreateCourseDialog = () => {
  const [createDialog, setCreateDialog] = useRecoilState(createDialogState)
  const currentCurriculum = useRecoilValue(currentCurriculumAndKindObject)
  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)

  const [createCourseStatus, setCreateCourseStatus] = useFetchState()
  const [errors, setErrors] = useState<Errors<CourseDetailsType>[]>([])
  const [courseDetails, setCourseDetails] = useState<CourseDetailsType>({
    code: "",
    name: "",
    ects: 0,
    raise_factor: 0,
    meetings: 0,
    meeting_duration: 0,
    teachers_simultaniously_teaching: 0,
    contact_students: 0,
    teached_on_day: 0,
    study_year: 1,
    op: 1,
    correction_time: 0,
    travel_time: 0,
    workmethod: { id: -1, name: "Standaard" },
    course_owner: { id: -1, personnel_code: "Geen cursusbeheerder" },
    team: { id: -1, name: "Geen team" },
    kind: currentCurriculum.kind,
  })
  const [availableCourseOwners, setAvailableCourseOwners] = useState<
    CourseOwnerOptions[]
  >([])
  const [availableTeams, setAvailableTeams] = useState<TeamOptions[]>([])
  // const {
  //   data: dataAvailableCourseOwners,
  //   error: errorAvailableCourseOwners,
  // } = useSWR<CourseOwner[]>(() =>
  //   currentCurriculum.id !== -1
  //     ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-course-owners`
  //     : null
  // )
  const { data: dataAvailableTeams, error: errorAvailableTeams } = useSWR<
    Team[]
  >(() =>
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-teams`
      : null
  )
  const {
    data: dataAvailableWorkmethods,
    error: errorAvailableWorkmethods,
  } = useSWR<Workmethod[]>(() =>
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-workmethods`
      : null
  )
  const { data: dataDefaultValues, error: errorDefaultValues } = useSWR<
    DefaultValues
  >(() =>
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/default-values`
      : null
  )

  // useEffect(() => {
  //   if (typeof dataAvailableCourseOwners !== "undefined" && createDialog) {
  //     let arr = dataAvailableCourseOwners as CourseOwnerOptions[]

  //     arr = arr.map((aco) => ({
  //       ...aco,
  //       type: "Beschikbare docenten",
  //     }))

  //     setAvailableCourseOwners(arr)
  //   }
  // }, [createDialog, dataAvailableCourseOwners])

  useEffect(() => {
    if (typeof dataAvailableTeams !== "undefined" && createDialog) {
      let arr = dataAvailableTeams as TeamOptions[]

      arr = arr.map((aco) => ({
        ...aco,
        type: "Beschikbare teams",
      }))

      setAvailableTeams(arr)
    }
  }, [createDialog, dataAvailableTeams])

  useEffect(() => {
    if (typeof dataAvailableWorkmethods !== "undefined" && createDialog) {
      const wm: Workmethod =
        dataAvailableWorkmethods[
          dataAvailableWorkmethods.findIndex((wm) => wm.name === "Standaard")
        ]
      setCourseDetails({ ...courseDetails, workmethod: wm })
    }
  }, [createDialog, dataAvailableWorkmethods])

  useEffect(() => {
    if (typeof dataDefaultValues !== "undefined" && createDialog) {
      setCourseDetails({
        ...courseDetails,
        ...dataDefaultValues,
      })
    }
  }, [createDialog, dataDefaultValues])

  useEffect(() => {
    if (createDialog) {
      mutate("/api/app/all-curricula")
    } else {
      mutate(
        `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-course-owners`
      )
      mutate(
        `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-teams`
      )
      mutate(
        `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-workmethods`
      )
      setCourseDetails({
        code: "",
        name: "",
        ects: 0,
        raise_factor: 0,
        meetings: 0,
        meeting_duration: 0,
        teachers_simultaniously_teaching: 0,
        contact_students: 0,
        teached_on_day: 0,
        study_year: 1,
        op: 1,
        correction_time: 0,
        travel_time: 0,
        workmethod: { id: -1, name: "Standaard" },
        course_owner: { id: -1, personnel_code: "Geen cursusbeheerder" },
        team: { id: -1, name: "Geen team" },
        kind: currentCurriculum.kind,
      })
      setCreateCourseStatus("initial")
    }
  }, [createDialog])

  useEffect(() => {
    setCourseDetails({
      ...courseDetails,
      kind: currentCurriculum.kind,
    })
  }, [currentCurriculum.kind])

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const createCourse = () => {
    courseDetailsValidationObject
      .validate(courseDetails, { abortEarly: false })
      .then(() => {
        setErrors([])
        setCreateCourseStatus("loading")
        fetch(
          `/api/course/create/${currentCurriculum.curriculum}/${currentCurriculum.kind}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(courseDetails),
          }
        ).then(async (r) => {
          if (r.ok) {
            setCreateCourseStatus("success")
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}`
            )
            setCreateDialog(false)
            snackbarText("De cursus is aangemaakt")
            snackbarVisible(true)
          } else {
            setCreateCourseStatus("error")
            const json = await r.json()
            console.log(json)
            snackbarText("Er was een probleem bij het aanmaken van de cursus")
            snackbarVisible(true)
          }
        })
      })
      .catch((e: ValidationError) => checkValidationErrors(e, setErrors))
  }

  return (
    <Dialog
      innerDialogProps={{
        fullWidth: true,
        maxWidth: "sm",
      }}
      open={createDialog}
      onClose={() => setCreateDialog(false)}
      title="Maak cursus aan"
      content={() => (
        <>
          <div className="card-content">
            <div className="column-2">
              <TextField
                label="Cursuscode"
                variant="outlined"
                type="text"
                name="code"
                value={courseDetails.code}
                onChange={(e) => handleChange(e)}
                error={errors.some((e) => e.path === "code")}
                helperText={errors.find((e) => e.path === "code")?.message}
              />
              <TextField
                label="Naam"
                variant="outlined"
                type="text"
                name="name"
                value={courseDetails.name}
                onChange={(e) => handleChange(e)}
                error={errors.some((e) => e.path === "name")}
                helperText={errors.find((e) => e.path === "name")?.message}
              />
              {/* <TextField
                label="Studiepunten"
                variant="outlined"
                type="number"
                name="ects"
                value={courseDetails.ects}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "ects")}
                helperText={errors.find((e) => e.path === "ects")?.message}
              /> */}
              {!dataAvailableWorkmethods ? (
                <Loader />
              ) : errorAvailableWorkmethods ? (
                <ErrorState />
              ) : (
                <Autocomplete
                  value={{ ...courseDetails.workmethod }}
                  onChange={(e, v) =>
                    setCourseDetails({ ...courseDetails, workmethod: v })
                  }
                  disableClearable
                  options={dataAvailableWorkmethods}
                  getOptionLabel={(option) => option.name}
                  noOptionsText={"Geen werkmethode beschikbaar"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Werkmethode"
                      variant="outlined"
                    />
                  )}
                />
              )}
              {/* {!dataAvailableCourseOwners ? (
                <Loader />
              ) : errorAvailableCourseOwners ? (
                <ErrorState />
              ) : (
                <Autocomplete
                  value={{
                    ...courseDetails.course_owner,
                    type: "Beschikbare docenten",
                  }}
                  onChange={(e, v) =>
                    v !== null
                      ? setCourseDetails({ ...courseDetails, course_owner: v })
                      : setCourseDetails({
                          ...courseDetails,
                          course_owner: { id: -1, personnel_code: "" },
                        })
                  }
                  onBlur={() =>
                    courseDetails.course_owner.id === -1 &&
                    setCourseDetails({
                      ...courseDetails,
                      course_owner: {
                        id: -1,
                        personnel_code: "Geen cursusbeheerder",
                      },
                    })
                  }
                  options={availableCourseOwners.sort((a, b) =>
                    a.type < b.type ? 1 : -1
                  )}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.personnel_code}
                  noOptionsText={"Geen docenten beschikbaar"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cursusbeheerder"
                      variant="outlined"
                    />
                  )}
                />
              )} */}
              {!dataAvailableTeams ? (
                <Loader />
              ) : errorAvailableTeams ? (
                <ErrorState />
              ) : (
                <Autocomplete
                  value={{ ...courseDetails.team, type: "Beschikbare teams" }}
                  onChange={(e, v) =>
                    v !== null
                      ? setCourseDetails({ ...courseDetails, team: v })
                      : setCourseDetails({
                          ...courseDetails,
                          team: { id: -1, name: "" },
                        })
                  }
                  onBlur={() =>
                    courseDetails.team.id === -1 &&
                    setCourseDetails({
                      ...courseDetails,
                      team: { id: -1, name: "Geen team" },
                    })
                  }
                  options={availableTeams.sort((a, b) =>
                    a.type < b.type ? 1 : -1
                  )}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.name}
                  noOptionsText={"Geen teams beschikbaar"}
                  renderInput={(params) => (
                    <TextField {...params} label="Team" variant="outlined" />
                  )}
                />
              )}
              {/* <FormControl variant="outlined">
                <InputLabel id="course-details-teached-on-day-label">
                  Wordt gegeven op dag
                </InputLabel>
                <Select
                  label="Wordt gegeven op dag"
                  labelId="course-details-teached-on-day-label"
                  id="course-details-teached-on-day-select"
                  value={courseDetails.teached_on_day}
                  onChange={(e) =>
                    setCourseDetails({
                      ...courseDetails,
                      teached_on_day: e.target.value as number,
                    })
                  }
                >
                  <MenuItem value={0}>Maandag</MenuItem>
                  <MenuItem value={1}>Dinsdag</MenuItem>
                  <MenuItem value={2}>Woensdag</MenuItem>
                  <MenuItem value={3}>Donderdag</MenuItem>
                  <MenuItem value={4}>Vrijdag</MenuItem>
                </Select>
              </FormControl> */}
              <FormControl variant="outlined">
                <InputLabel id="course-details-study-year-label">
                  Studiejaar
                </InputLabel>
                <Select
                  label="Studiejaar"
                  labelId="course-details-study-year-label"
                  id="course-details-study-year-select"
                  value={courseDetails.study_year}
                  onChange={(e) =>
                    setCourseDetails({
                      ...courseDetails,
                      study_year: e.target.value as number,
                    })
                  }
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="course-details-op-label">Periode</InputLabel>
                <Select
                  label="Periode"
                  labelId="course-details-op-label"
                  id="course-details-op-select"
                  value={courseDetails.op}
                  onChange={(e) =>
                    setCourseDetails({
                      ...courseDetails,
                      op: e.target.value as number,
                    })
                  }
                >
                  <MenuItem value={1}>OP1</MenuItem>
                  <MenuItem value={2}>OP2</MenuItem>
                  <MenuItem value={3}>OP3</MenuItem>
                  <MenuItem value={4}>OP4</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <Divider variant="middle" />
          <div className="card-content">
            <div className="column-2">
              <TextField
                label="w"
                variant="outlined"
                type="number"
                name="meetings"
                value={courseDetails.meetings}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "meetings")}
                helperText={
                  errors.find((e) => e.path === "meetings")
                    ? errors.find((e) => e.path === "meetings")?.message
                    : "Aantal lesmomenten"
                }
              />
              <TextField
                label="Ct"
                variant="outlined"
                type="number"
                name="contact_students"
                value={courseDetails.contact_students}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "contact_students")}
                helperText={
                  errors.find((e) => e.path === "contact_students")
                    ? errors.find((e) => e.path === "contact_students")?.message
                    : "Contacttijd lesmoment met studenten (min)"
                }
              />
              <TextField
                label="Opslag"
                variant="outlined"
                type="number"
                name="raise_factor"
                value={courseDetails.raise_factor}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "raise_factor")}
                helperText={
                  errors.find((e) => e.path === "raise_factor")
                    ? errors.find((e) => e.path === "raise_factor")?.message
                    : "Opslagfactor"
                }
              />
              <TextField
                label="Cor"
                variant="outlined"
                type="number"
                name="correction_time"
                value={courseDetails.correction_time}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "correction_time")}
                helperText={
                  errors.find((e) => e.path === "correction_time")
                    ? errors.find((e) => e.path === "correction_time")?.message
                    : "Correctietijd per student (min)"
                }
              />
              <TextField
                label="R"
                variant="outlined"
                type="number"
                name="travel_time"
                value={courseDetails.travel_time}
                onChange={(e) => handleChangeNumber(e)}
                error={errors.some((e) => e.path === "travel_time")}
                helperText={
                  errors.find((e) => e.path === "travel_time")
                    ? errors.find((e) => e.path === "travel_time")?.message
                    : "Reistijd"
                }
              />
            </div>
          </div>
        </>
      )}
      actions={() => (
        // <Button
        //   disableElevation
        //   variant="contained"
        //   color="primary"
        //   onClick={() => createCourse()}
        // >
        //   Maak aan
        // </Button>
        <Button
          fetchStatus={[createCourseStatus, setCreateCourseStatus]}
          onClick={() => createCourse()}
        >
          Maak aan
        </Button>
      )}
    />
  )
}
