import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core"
import {
  pdf,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer"
import html2canvas from "html2canvas"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import useSWR from "swr"
import {
  ExportDefaultFooter,
  ExportDefaultHeader,
  pageDefaultStyles,
  ExportPDFButton,
} from "."
import { exportPlanningDialogState } from "../../state/app"
import { currentCurriculumAndKindObject } from "../../state/planning"
import { FullScreenDialog } from "../Dialog"
import InformationCard from "../InformationCard"
import HRLogo from "../../images/hr-logo-plain@4x.png"
import { Button } from "../Buttons"

type ExportPlanningProps = {
  data: ExportPlanningResponse
  img: string
}

const ExportPlanning = (props: ExportPlanningProps) => {
  const { data, img } = props

  return (
    <>
      <div className="export-planning-image-header-container">
        <div className="export-planning-image-header-side left">
          <div>
            <span className="export-planning-image-header-title">
              {data.curriculum} - {data.institute} - {data.education} -
              Studiejaar {data.study_year} - OP{data.op}
            </span>
            <span className="export-planning-image-header-subtitle">
              {new Date().toLocaleDateString()} -{" "}
              {new Date().toLocaleTimeString()}
            </span>
          </div>
        </div>
        <div className="export-planning-image-header-side right">
          <div>
            <span className="export-planning-image-header-title">
              Hogeschool Rotterdam
            </span>
            <span className="export-planning-image-header-subtitle">
              Instituut voor Communicatie, Media en IT
            </span>
          </div>
          <img id="export-planning-image-header-logo" src={HRLogo} alt="HR" />
        </div>
      </div>
      <div className="export-planning-image-divider" />
      <img className="export-planning-image" src={img} />
    </>
  )
}

type ExportPlanningDialogProps = {
  year: number
  op: number
  studyYearRef: React.RefObject<HTMLDivElement>
  studyYearCellsRef: React.RefObject<HTMLTableElement>
  studyYearNotesRef: React.RefObject<HTMLDivElement>
  open: boolean
  onClose: () => void
}

type ExportPlanningResponse = {
  study_year: string
  op: number
  curriculum: string
  education: string
  institute: string
}

type ExportPlanningOptions = {
  notes: boolean
}

const ExportPlanningDialog = (props: ExportPlanningDialogProps) => {
  const {
    open,
    onClose,
    year,
    op,
    studyYearRef,
    studyYearCellsRef,
    studyYearNotesRef,
  } = props
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const [options, setOptions] = useState<ExportPlanningOptions>({
    notes: false,
  })
  const exportRef = useRef<HTMLDivElement>(null)
  const [img, setImg] = useState<string | undefined>(undefined)
  const [canvas, setCanvas] = useState<string | undefined>(undefined)

  const { data, error } = useSWR<ExportPlanningResponse>(
    open && currentCurriculumAndKind.curriculum && currentCurriculumAndKind.kind
      ? `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/export`
      : null,
    (url) =>
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(options),
      }).then((r) => r.json())
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions({ ...options, [event.target.name]: event.target.checked })
  }

  useEffect(() => {
    if (
      open &&
      studyYearRef.current &&
      studyYearCellsRef.current &&
      studyYearNotesRef.current
    ) {
      html2canvas(studyYearCellsRef.current).then((canvas) => {
        const png = canvas.toDataURL("image/png")
        setImg(png)
      })
    } else {
      setImg(undefined)
      setCanvas(undefined)
    }
  }, [open])

  useEffect(() => {
    if (data && img && exportRef.current) {
      if (exportRef.current) {
        html2canvas(exportRef.current).then((canvas) => {
          const png = canvas.toDataURL("image/png")
          setCanvas(png)
        })
      }
    }
  }, [data, img, exportRef])

  return (
    <>
      <FullScreenDialog
        title={"Exporteer planning"}
        open={open}
        onClose={onClose}
        content={() => (
          <div className="export-dialog-container">
            <div className="export-dialog-fullscreen-sidebar-container">
              <h6 className="subkop">Exporteer instellingen</h6>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox
                        checked={options.notes}
                        onChange={handleChange}
                        name="notes"
                      />
                    }
                    label="Notities"
                  />
                </FormGroup>
              </FormControl>
              <InformationCard
                type="info"
                content={() => (
                  <>Vink aan wat in de export meegenomen moet worden.</>
                )}
                style={{ marginTop: "6px" }}
              />
            </div>
            <div className="export-dialog-fullscreen-content-container">
              <div className="export-dialog-fullscreen-content-header">
                {data && canvas ? (
                  <a
                    href={canvas}
                    download={`${data.curriculum} - ${data.institute} - ${
                      data.education
                    } - S${data.study_year} - OP${
                      data.op
                    } - ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button>Exporteer PNG</Button>
                  </a>
                ) : (
                  <CircularProgress color="primary" size={30} />
                )}
              </div>
              {error ? (
                <div className="export-dialog-fullscreen-error-container">
                  Er is een probleem met het genereren van de PDF.
                </div>
              ) : data && img ? (
                <div className="export-planning-container" ref={exportRef}>
                  <ExportPlanning data={data} img={img} />
                </div>
              ) : (
                <div className="export-dialog-fullscreen-loading-container">
                  <CircularProgress color="primary" size={50} />
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default ExportPlanningDialog
