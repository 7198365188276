import "../../components/TeacherDetails/TeacherDetails.css"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { TeacherNotes } from "../../components/TeacherDetails/notes"
import { TeacherPersonalia } from "../../components/TeacherDetails/personalia"
import { TeacherTabs } from "../../components/TeacherDetails/tabs/tabs"
import { IconButton } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBackRounded"
import useSWR from "swr"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Loader } from "../../components/Loader"
import { ErrorState } from "../../components/ErrorState"
import { Button } from "../../components/Buttons"
import { ExportTeacherDialog } from "../../components/Export/ExportTeacher"

export const TeacherDetails = () => {
  let history = useHistory()
  let { id }: { id: string } = useParams()
  let [exportTeacherDialog, setExportTeacherDialog] = useState<boolean>(false)

  const { data, error } = useSWR<{ name: string; curriculum: string }>(
    `/api/teacher/${id}/personnel_code`
  )

  if (!data) return <Loader />

  if (error) return <ErrorState />

  return (
    <div id="content-container">
      <div id="content" className="content-divided-teacher-details">
        <div id="details-go-back" className="details-topbar">
          <IconButton
            aria-label="go-back"
            onClick={() => history.push("/teachers")}
          >
            <ArrowBackIcon />
          </IconButton>
          &nbsp; Terug naar docenten
        </div>
        <div id="details-specific" className="details-topbar">
          <div>
            <span className="details-topbar-red">{data.name}</span>
            &nbsp;-&nbsp;Curriculum&nbsp;
            <span className="details-topbar-red">{data.curriculum}</span>
          </div>
          <div>
            <Button onClick={() => setExportTeacherDialog(true)}>
              Exporteer
            </Button>
          </div>
        </div>
        <div id="teacher-personalia" className="card-container">
          <TeacherPersonalia />
        </div>
        <div id="teacher-tabs" className="card-container">
          <TeacherTabs />
        </div>
        <div id="teacher-notes" className="card-container">
          <TeacherNotes />
        </div>
      </div>
      <ExportTeacherDialog
        open={exportTeacherDialog}
        onClose={() => setExportTeacherDialog(false)}
        teacher_id={id}
      />
    </div>
  )
}
