import React from "react"
import { CourseTable } from "../../components/CourseTable"
import { CourseDetails } from "../../components/CourseDetails"
import { Topbar } from "../../components/Layout"
import { useHistory } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { searchbarState, createDialogState } from "../../state/app"
import { CreateCourseDialog } from "../../components/CreateCourseDialog"

export const Courses = () => {
  const setSearchbar = useSetRecoilState(searchbarState)
  const setCreateDialog = useSetRecoilState(createDialogState)

  let history = useHistory()
  history.listen(() => {
    setCreateDialog(false)
    setSearchbar("")
  })

  return (
    <div id="content-container" className="topbar">
      <Topbar
        currentCurriculumAndKind
        searchbar
        createDialog="Maak cursus aan"
      />
      <div id="content" className="content-divided-2">
        <div id="course-overview" className="card-container">
          <CourseTable />
        </div>
        <div id="course-details" className="card-container">
          <CourseDetails />
        </div>
      </div>
      <CreateCourseDialog />
    </div>
  )
}
