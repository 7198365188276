import "./EmptyState.css"
import React from "react"

type EmptyDetailsStateProps = {
  kind?: "curriculum" | "course"
}

export const EmptyDetailsState = ({ kind }: EmptyDetailsStateProps) => {
  const getKind = (kind: EmptyDetailsStateProps["kind"]) => {
    switch (kind) {
      case "curriculum":
        return "Selecteer een curriculum"
      case "course":
        return "Selecteer een cursus"
      default:
        return "Er is niks geselecteerd"
    }
  }

  return (
    <div id="empty-state">
      <h2>{getKind(kind)}</h2>
    </div>
  )
}
