import "./StudyYear.css"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  CurrentCellOverlayOpenState,
  currentCurriculumAndKindObject,
  planningViewState,
} from "../../state/planning"
import useSWR, { mutate } from "swr"
import classes from "*.module.css"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import { BodyCell, CreateCell, HeadCellClass, HeadCellCourse } from "./Cells"
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded"
import FullscreenRoundedIcon from "@material-ui/icons/FullscreenRounded"
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded"
import {
  exportPlanningDialogState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import html2canvas from "html2canvas"
import ExportPlanningDialog from "../Export/ExportPlanning"

export type StudyYearPlanning = {
  classes: StudyYearClassCell[]
  courses: StudyYearCoursCell[]
}

export type StudyYearClassCell = {
  id: number
  pos_x: number
  pos_y: number
  class_id: number
  name: string
}

export type StudyYearCoursCell = {
  id: number
  pos_x: number
  pos_y: number
  course_id: number
  name: string
  work_method: string
  cells: StudyYearCell[]
}

export type StudyYearCell = {
  id: number
  pos_x: number
  pos_y: number
  label: {
    name: string
    color: string
  }
  note: {
    note: string
  }
  teachers: {
    id: number
    personnel_code: string
    available_hours: number
    workload_override: number | string
  }[]
}

type StudyYearProps = {
  position: number
  selectedStudyYear: number[]
  selectedOP: number[]
  year_and_op: {
    year: {
      value: number[]
      setValue: React.Dispatch<React.SetStateAction<number[]>>
    }
    op: {
      value: number[]
      setValue: React.Dispatch<React.SetStateAction<number[]>>
    }
  }
}

export const StudyYear = (props: StudyYearProps) => {
  const { position, selectedStudyYear, selectedOP, year_and_op } = props
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const [exportPlanningDialog, setExportPlanningDialog] = useRecoilState(
    exportPlanningDialogState
  )
  const studyYearRef = useRef<HTMLDivElement>(null)
  const studyYearCellsRef = useRef<HTMLTableElement>(null)
  const studyYearNotesRef = useRef<HTMLDivElement>(null)

  const year = year_and_op.year.value[position]
  const op = year_and_op.op.value[position]

  const {
    data: dataCellData,
    error: errorCellData,
    isValidating: isValidatingCellData,
  } = useSWR<StudyYearPlanning>(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      year &&
      op
      ? `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      : null
  )

  return (
    <>
      {dataCellData && (
        <StudyYearActions position={position} year_and_op={year_and_op} />
      )}
      <div className="planning-study-year-container" ref={studyYearRef}>
        <div className="planning-study-year-cells-container">
          {dataCellData && (
            <>
              <table
                className="planning-study-year-table-container"
                ref={studyYearCellsRef}
              >
                <thead>
                  <tr>
                    <TableLead position={position} year_and_op={year_and_op} />
                    {dataCellData.classes.map((_class, key) => {
                      return (
                        <HeadCellClass
                          _class={_class}
                          year={year}
                          op={op}
                          key={`${key}-${_class.id}`}
                          first={false}
                          last={dataCellData.classes.length === key + 1}
                          length={dataCellData.classes.length}
                          selectedStudyYear={selectedStudyYear}
                          selectedOP={selectedOP}
                          classesPlannedInOP={dataCellData.classes}
                        />
                      )
                    })}
                    <CreateCell
                      type="class"
                      year={year}
                      op={op}
                      selectedStudyYear={selectedStudyYear}
                      selectedOP={selectedOP}
                      classesPlannedInOP={dataCellData.classes}
                    />
                  </tr>
                </thead>
                <tbody>
                  {dataCellData.courses.map((course, key) => {
                    return (
                      <tr key={`${key}-${course.id}`}>
                        <HeadCellCourse
                          course={course}
                          year={year}
                          op={op}
                          first={false}
                          last={dataCellData.courses.length === key + 1}
                          length={dataCellData.courses.length}
                        />
                        {dataCellData.classes.map((_, key) => {
                          const index = key + 1
                          const cell = course.cells.find(
                            (c) => c.pos_x === index
                          )

                          return (
                            <BodyCell
                              key={`${key}-${index}`}
                              cell={cell!}
                              course={course}
                              year={year}
                              op={op}
                            />
                          )
                        })}
                      </tr>
                    )
                  })}
                  <tr>
                    <CreateCell
                      type="course"
                      year={year}
                      op={op}
                      selectedStudyYear={selectedStudyYear}
                      selectedOP={selectedOP}
                      classesPlannedInOP={dataCellData.classes}
                    />
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <div
            className={`planning-study-year-loading-overlay ${
              isValidatingCellData ? "visible" : "invisible"
            }`}
          >
            <CircularProgress color="primary" size={100} />
          </div>
        </div>
        <div className="planning-study-year-divider" />
        <div className="planning-study-year-notes-container">
          <StudyYearNotes
            curriculum={currentCurriculumAndKind.curriculum}
            kind={currentCurriculumAndKind.kind}
            year={year}
            op={op}
            ref={studyYearNotesRef}
          />
        </div>
      </div>
      <ExportPlanningDialog
        year={year}
        op={op}
        studyYearRef={studyYearRef}
        studyYearCellsRef={studyYearCellsRef}
        studyYearNotesRef={studyYearNotesRef}
        open={
          exportPlanningDialog.expanded &&
          year === exportPlanningDialog.year &&
          op === exportPlanningDialog.op
        }
        onClose={() =>
          setExportPlanningDialog({ ...exportPlanningDialog, expanded: false })
        }
      />
    </>
  )
}

type StudyYearActionsProps = {
  position: StudyYearProps["position"]
  year_and_op: StudyYearProps["year_and_op"]
}

const StudyYearActions = (props: StudyYearActionsProps) => {
  const { position, year_and_op } = props
  const setExportPlanningDialog = useSetRecoilState(exportPlanningDialogState)
  const planningView = useRecoilValue(planningViewState)

  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#fff",
      },
    },
  })

  return (
    <div className="planning-study-year-actions">
      <ThemeProvider theme={theme}>
        <div>
          {planningView === "op" ? (
            <>
              <FormControl
                color={"primary"}
                variant="outlined"
                style={{ marginRight: "12px" }}
                size="small"
              >
                <Select
                  labelId="planning-study-year-outlined-label"
                  value={year_and_op.year.value[position]}
                  onChange={(e) => {
                    year_and_op.year.setValue([
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                    ])
                  }}
                >
                  <MenuItem value={1}>Studiejaar 1</MenuItem>
                  <MenuItem value={2}>Studiejaar 2</MenuItem>
                  <MenuItem value={3}>Studiejaar 3</MenuItem>
                  <MenuItem value={4}>Studiejaar 4</MenuItem>
                </Select>
              </FormControl>
              OP{year_and_op.op.value[position]}
            </>
          ) : (
            <>
              Studiejaar {year_and_op.year.value[position]}
              <FormControl
                color={"primary"}
                variant="outlined"
                style={{ marginLeft: "12px" }}
                size="small"
              >
                <Select
                  labelId="planning-study-year-outlined-label"
                  value={year_and_op.op.value[position]}
                  onChange={(e) => {
                    year_and_op.op.setValue([
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                    ])
                  }}
                >
                  <MenuItem value={1}>OP1</MenuItem>
                  <MenuItem value={2}>OP2</MenuItem>
                  <MenuItem value={3}>OP3</MenuItem>
                  <MenuItem value={4}>OP4</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div>
          <Tooltip title="Maak een screenshot">
            <IconButton
              aria-label="delete"
              onClick={() =>
                setExportPlanningDialog({
                  year: year_and_op.year.value[position],
                  op: year_and_op.op.value[position],
                  expanded: true,
                })
              }
            >
              <PhotoCameraRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </ThemeProvider>
    </div>
  )
}

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    status: {
      danger: string
    }
  }
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

const TableLead = (
  props: Omit<StudyYearProps, "selectedStudyYear" | "selectedOP">
) => {
  const { position, year_and_op } = props
  const planningView = useRecoilValue(planningViewState)

  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#fff",
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <th className="planning-study-year-lead">
        <div>
          Studiejaar {year_and_op.year.value[position]} - OP
          {year_and_op.op.value[position]}
          {/* {planningView === "op" ? (
            <>
              <FormControl
                color={"primary"}
                variant="outlined"
                style={{ marginRight: "24px" }}
                size="small"
              >
                <Select
                  labelId="planning-study-year-outlined-label"
                  value={year_and_op.year.value[position]}
                  onChange={(e) => {
                    year_and_op.year.setValue([
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                    ])
                  }}
                >
                  <MenuItem value={1}>Studiejaar 1</MenuItem>
                  <MenuItem value={2}>Studiejaar 2</MenuItem>
                  <MenuItem value={3}>Studiejaar 3</MenuItem>
                  <MenuItem value={4}>Studiejaar 4</MenuItem>
                </Select>
              </FormControl>
              OP{year_and_op.op.value[position]}
            </>
          ) : (
            <>
              Studiejaar {year_and_op.year.value[position]}
              <FormControl
                color={"primary"}
                variant="outlined"
                style={{ marginLeft: "24px" }}
                size="small"
              >
                <Select
                  labelId="planning-study-year-outlined-label"
                  value={year_and_op.op.value[position]}
                  onChange={(e) => {
                    year_and_op.op.setValue([
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                      e.target.value as number,
                    ])
                  }}
                >
                  <MenuItem value={1}>OP1</MenuItem>
                  <MenuItem value={2}>OP2</MenuItem>
                  <MenuItem value={3}>OP3</MenuItem>
                  <MenuItem value={4}>OP4</MenuItem>
                </Select>
              </FormControl>
            </>
          )} */}
        </div>
      </th>
    </ThemeProvider>
  )
}

type StudyYearNotesProps = {
  curriculum: string
  kind: string
  year: number
  op: number
  ref: React.RefObject<HTMLDivElement>
}

type Notes = {
  id: number
  cell_id: number
  date: Date
  note: string
  _class_name: string
  course_name: string
  teachers: string[]
}

const StudyYearNotes = React.forwardRef<HTMLDivElement, StudyYearNotesProps>(
  (props, ref) => {
    const { curriculum, kind, year, op } = props

    const currentCellOverlayOpen = useSetRecoilState(
      CurrentCellOverlayOpenState
    )
    const snackbarText = useSetRecoilState(snackbarTextState)
    const snackbarVisible = useSetRecoilState(snackbarVisibleState)

    const {
      data: dataNotes,
      error: errorNotes,
      isValidating: isValidatingNotes,
    } = useSWR<Notes[]>(
      curriculum && kind && year && op
        ? `/api/planning/${curriculum}/${kind}/${year}/${op}/notes`
        : null
    )

    const removeNote = (id: number) => {
      fetch(`/api/planning/cell/${id}/note`, { method: "DELETE" }).then(() => {
        mutate(`/api/planning/${curriculum}/${kind}/${year}/${op}`)
        mutate(`/api/planning/${curriculum}/${kind}/${year}/${op}/notes`)
        snackbarText("De notitie is verwijderd")
        snackbarVisible(true)
      })
    }

    return (
      <div ref={ref}>
        <h6 className="subkop">Notities</h6>
        {dataNotes ? (
          dataNotes.length > 0 ? (
            <>
              {dataNotes.map((note, key) => (
                <div key={`${key}-${note.id}`} className="planning-note">
                  <div className="planning-note-header">
                    <span className="planning-note-class-and-course">
                      {note._class_name} - {note.course_name}
                    </span>
                    <span className="planning-note-teachers">
                      {note.teachers.length > 0
                        ? note.teachers.join(", ")
                        : "Geen docent"}
                    </span>
                    <IconButton
                      size="small"
                      className="planning-note-remove"
                      onClick={() => removeNote(note.cell_id)}
                    >
                      <RemoveCircleOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <div
                    className="planning-note-text"
                    onClick={() => currentCellOverlayOpen(note.cell_id)}
                  >
                    {note.note}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span
              style={{
                display: "block",
                textAlign: "center",
                padding: "10px 0 20px 0",
              }}
            >
              Geen notities
            </span>
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 0 20px 0",
            }}
          >
            <CircularProgress size={32} />
          </div>
        )}
      </div>
    )
  }
)
