import "./CellOverlays.css"
import React, { ClassicElement, useEffect, useState } from "react"
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
} from "@material-ui/core"
import {
  checkValidationErrors,
  Errors,
  hex2rgba,
  useFetchState,
} from "../../utils"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { currentCurriculumAndKindObject } from "../../state/planning"
import useSWR, { mutate } from "swr"
import { Autocomplete } from "@material-ui/lab"
import { boolean, number, object, string, ValidationError } from "yup"
import {
  StudyYearCell,
  StudyYearClassCell,
  StudyYearCoursCell,
} from "./StudyYear"
import { HeadCellClassType, HeadCellCourseType } from "./Cells"
import { Team, TeamOptions, Workmethod } from "../CourseDetails"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import { snackbarTextState, snackbarVisibleState } from "../../state/app"
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded"
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded"
import { Dialog } from "../Dialog"
import InformationCard from "../InformationCard"
import { Button } from "../Buttons"

type ClassOverlayProps = {
  year: number
  op: number
  handleClose: () => void
  _class?: StudyYearClassCell
  cellType?: HeadCellClassType
  create?: boolean
  length: number
  selectedStudyYear: number[]
  selectedOP: number[]
  classesPlannedInOP: StudyYearClassCell[]
}

type ClassDetails = {
  id: number
  class_id: number
  code: string
  size_op1: number
  size_op2: number
  size_op3: number
  size_op4: number
  pos_x: number
  pos_y: number
  update: boolean
  side: "left" | "right"
  type:
    | "new_cell:end"
    | "new_cell:left"
    | "new_cell:right"
    | "existing_cell:class:middle"
    | "existing_cell:empty:middle"
}

type ExistingClassDetails = Omit<ClassDetails, "id"> & { group_by: string }

export const ClassOverlay = (props: ClassOverlayProps) => {
  const {
    year,
    op,
    handleClose,
    _class,
    cellType,
    create,
    length,
    selectedStudyYear,
    selectedOP,
    classesPlannedInOP,
  } = props

  const getType = (): ClassDetails["type"] => {
    if (_class && create && cellType?.left) return "new_cell:left"

    if (_class && create && cellType?.right) return "new_cell:right"

    if (_class && cellType?.update && !_class.name)
      return "existing_cell:empty:middle"

    if (_class && cellType?.update) return "existing_cell:class:middle"

    if (!_class && create) return "new_cell:end"

    return "new_cell:end"
  }

  const [_classDetails, setClassDetails] = useState<ClassDetails>({
    id: -1,
    class_id: -1,
    code: "",
    size_op1: 0,
    size_op2: 0,
    size_op3: 0,
    size_op4: 0,
    pos_x:
      getType() === "new_cell:left"
        ? _class!.pos_x
        : getType() === "new_cell:right"
        ? _class!.pos_x + 1
        : getType() === "existing_cell:empty:middle" ||
          getType() === "existing_cell:class:middle"
        ? _class!.pos_x
        : -1,
    pos_y:
      (_class && create) || (_class?.id && cellType?.update)
        ? _class.pos_y
        : -1,
    update: cellType?.update ? true : false,
    side: cellType?.left ? "left" : "right",
    type: getType(),
  })
  const [_classDetailsErrors, setClassDetailsErrors] = useState<
    Errors<ClassDetails>[]
  >([])
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )
  const [createClassStatus, setCreateClassStatus] = useFetchState()
  const [updateClassStatus, setUpdateClassStatus] = useFetchState()
  const [removeClassStatus, setRemoveClassStatus] = useFetchState()

  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)

  const { data, error } = useSWR<ExistingClassDetails[]>(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/existing-classes`
  )
  const [existingClasses, setExistingClasses] = useState<
    ExistingClassDetails[]
  >([])

  const { data: dataClassDetails, error: errorClassDetails } = useSWR<
    ClassDetails
  >(
    getType() === "existing_cell:class:middle"
      ? `/api/planning/_class/${_class?.id}`
      : null
  )

  const {
    data: dataDefaultStudentAmount,
    error: errorDefaultStudentAmount,
  } = useSWR<number>(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/default-student-amount`
  )

  useEffect(() => {
    if (typeof data !== "undefined") {
      setExistingClasses(data)
    }

    if (typeof dataClassDetails !== "undefined") {
      setClassDetails({
        ..._classDetails,
        ...dataClassDetails,
      })
    }

    if (
      typeof dataDefaultStudentAmount !== "undefined" &&
      getType() !== "existing_cell:class:middle"
    ) {
      setClassDetails({
        ..._classDetails,
        size_op1: dataDefaultStudentAmount,
        size_op2: dataDefaultStudentAmount,
        size_op3: dataDefaultStudentAmount,
        size_op4: dataDefaultStudentAmount,
      })
    }

    return () => {
      setClassDetails({
        id: -1,
        class_id: -1,
        code: "",
        size_op1: 0,
        size_op2: 0,
        size_op3: 0,
        size_op4: 0,
        pos_x:
          getType() === "new_cell:left"
            ? _class!.pos_x
            : getType() === "new_cell:right"
            ? _class!.pos_x + 1
            : getType() === "existing_cell:empty:middle" ||
              getType() === "existing_cell:class:middle"
            ? _class!.pos_x
            : -1,
        pos_y:
          (_class && create) || (_class?.id && cellType?.update)
            ? _class.pos_y
            : -1,
        update: cellType?.update ? true : false,
        side: cellType?.left ? "left" : "right",
        type: getType(),
      })
    }
  }, [
    data,
    dataClassDetails,
    dataDefaultStudentAmount,
    create,
    cellType,
    _class,
  ])

  const handleStringChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setClassDetails({
      ..._classDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setClassDetails({
      ..._classDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const createClass = () => {
    if (!classesPlannedInOP.some((c) => c.name === _classDetails.code)) {
      ClassOverlayValidiationObject.validate(_classDetails, {
        abortEarly: false,
      })
        .then(() => {
          setCreateClassStatus("loading")
          fetch(
            `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/class`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(_classDetails),
            }
          ).then(() => {
            setCreateClassStatus("success")
            // mutate(
            //   `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
            // )
            selectedStudyYear.map((st, i) => {
              mutate(
                `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${st}/${selectedOP[i]}`
              )
            })
            handleClose()
          })
        })
        .catch((e: ValidationError) =>
          checkValidationErrors<ClassDetails>(e, setClassDetailsErrors)
        )
    } else {
      setCreateClassStatus("error")
      snackbarText("De klas staat al in de planning.")
      snackbarVisible(true)
      console.log(
        `A class with the code ${_classDetails.code} already exists. Choose it from the list on the right`
      )
    }
  }

  const updateClass = () => {
    ClassOverlayValidiationObject.validate(_classDetails, { abortEarly: false })
      .then(() => {
        setUpdateClassStatus("loading")
        fetch(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/class`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(_classDetails),
          }
        ).then(() => {
          setUpdateClassStatus("success")
          // mutate(
          //   `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
          // )
          // mutate(
          //   `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
          // )

          selectedStudyYear.map((st, i) => {
            mutate(
              `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${st}/${selectedOP[i]}`
            )
            mutate(
              `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${st}/${selectedOP[i]}/notes`
            )
          })
          handleClose()
        })
      })
      .catch((e: ValidationError) =>
        checkValidationErrors<ClassDetails>(e, setClassDetailsErrors)
      )
  }

  const removeClassCheckLength = () =>
    length === 1 ? setShowDeleteConfirmation(true) : removeClass()

  const removeClass = () => {
    setRemoveClassStatus("loading")
    fetch(`/api/planning/_class/${_class!.class_id}/${op}`, {
      method: "DELETE",
    }).then(() => {
      setRemoveClassStatus("success")
      selectedStudyYear.map((st, i) => {
        mutate(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${st}/${selectedOP[i]}`
        )
        mutate(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${st}/${selectedOP[i]}/notes`
        )
      })
      handleClose()
    })
  }

  return (
    <div className="cell-overlay-container">
      <div className="cell-overlay-section class">
        <div className="cell-overlay-section-header">
          <span className="cell-overlay-section-instruction">
            {create ? "Maak een nieuwe klas aan" : "Wijzig de klas"}
          </span>
        </div>
        <div className="cell-overlay-section-content">
          <TextField
            fullWidth
            label="Klascode"
            variant="outlined"
            type="text"
            name="code"
            value={_classDetails.code}
            onChange={(e) => handleStringChange(e)}
            error={_classDetailsErrors.some((e) => e.path === "code")}
            helperText={
              _classDetailsErrors.find((e) => e.path === "code")?.message
            }
            size="small"
          />
          <div className="op">
            <TextField
              label="OP1"
              variant="outlined"
              type="number"
              name="size_op1"
              value={_classDetails.size_op1}
              onChange={(e) => handleNumberChange(e)}
              error={_classDetailsErrors.some((e) => e.path === "size_op1")}
              helperText={
                _classDetailsErrors.find((e) => e.path === "size_op1")?.message
              }
              size="small"
            />
            <TextField
              label="OP2"
              variant="outlined"
              type="number"
              name="size_op2"
              value={_classDetails.size_op2}
              onChange={(e) => handleNumberChange(e)}
              error={_classDetailsErrors.some((e) => e.path === "size_op2")}
              helperText={
                _classDetailsErrors.find((e) => e.path === "size_op2")?.message
              }
              size="small"
            />
            <TextField
              label="OP3"
              variant="outlined"
              type="number"
              name="size_op3"
              value={_classDetails.size_op3}
              onChange={(e) => handleNumberChange(e)}
              error={_classDetailsErrors.some((e) => e.path === "size_op3")}
              helperText={
                _classDetailsErrors.find((e) => e.path === "size_op3")?.message
              }
              size="small"
            />
            <TextField
              label="OP4"
              variant="outlined"
              type="number"
              name="size_op4"
              value={_classDetails.size_op4}
              onChange={(e) => handleNumberChange(e)}
              error={_classDetailsErrors.some((e) => e.path === "size_op4")}
              helperText={
                _classDetailsErrors.find((e) => e.path === "size_op4")?.message
              }
              size="small"
            />
          </div>
        </div>
        <div className="cell-overlay-section-footer">
          <Button
            disabled={createClassStatus.loading || updateClassStatus.loading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => (create ? createClass() : updateClass())}
          >
            {create ? "Maak aan en voeg toe" : "Sla wijzigingen op"}
          </Button>
          {cellType?.update && (
            <Button
              disabled={removeClassStatus.loading}
              variant="text"
              color="primary"
              fullWidth
              onClick={() => removeClassCheckLength()}
              innerProps={{ style: { marginTop: "12px" } }}
            >
              Verwijder kolom uit de planning
            </Button>
          )}
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="cell-overlay-section class">
        <div className="cell-overlay-section-header">
          <span className="cell-overlay-section-instruction">
            Selecteer een bestaande klas
          </span>
        </div>
        <div className="cell-overlay-section-content">
          <Autocomplete
            multiple={false}
            options={existingClasses.sort(
              (a, b) => -b.group_by.localeCompare(a.group_by)
            )}
            groupBy={(option) => option.group_by}
            getOptionLabel={(option) => option.code}
            onChange={(e, v) => {
              if (v) {
                setClassDetails({
                  ..._classDetails,
                  ...v,
                })
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Klascode" variant="outlined" />
            )}
          />
        </div>
      </div>
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        title={`Klas verwijderen`}
        content={() => (
          <>
            <InformationCard
              type="warning"
              content={() => (
                <>
                  <strong>Alle</strong> cursussen worden ook verwijderd als je
                  deze klas verwijderd.
                  <br />
                  <br />
                  Weet je het zeker?
                </>
              )}
            />
          </>
        )}
        actions={() => (
          <>
            <Button onClick={() => setShowDeleteConfirmation(false)}>
              Annuleer
            </Button>
            <Button
              disabled={removeClassStatus.loading}
              onClick={() => removeClass()}
              color="primary"
              variant="contained"
            >
              Verwijder klas uit de planning
            </Button>
          </>
        )}
      />
    </div>
  )
}

const ClassOverlayValidiationObject = object<ClassDetails>({
  id: number().required(),
  class_id: number().required(),
  code: string().required(),
  size_op1: number().required(),
  size_op2: number().required(),
  size_op3: number().required(),
  size_op4: number().required(),
  pos_x: number().required(),
  pos_y: number().required(),
  update: boolean().required(),
  side: string(),
  type: string(),
})

type CourseOverlayProps = {
  year: number
  op: number
  handleClose: () => void
  course?: StudyYearCoursCell
  cellType?: HeadCellCourseType
  create?: boolean
  length: number
}

type CourseDetails = {
  id: number
  course_id: number
  code: string
  name: string
  workmethod: {
    id: number
    name: string
  }
  team: {
    id: number
    name: string
  }
  meetings: number
  meeting_duration: number
  correction_time: number
  travel_time: number
  teachers_simultaniously_teaching: number
  contact_student: number
  raise_factor: number
  pos_x: number
  pos_y: number
  update: boolean
  side: "top" | "bottom"
  type:
    | "new_cell:end"
    | "new_cell:top"
    | "new_cell:bottom"
    | "existing_cell:course:middle"
    | "existing_cell:empty:middle"
}

type DefaultValues = {
  meetings: number
  contact_students: number
  raise_factor: number
  correction_time: number
  travel_time: number
}

type ExistingCourses = Omit<CourseDetails, "id"> & { group_by: string }

export const CourseOverlay = (props: CourseOverlayProps) => {
  const { year, op, handleClose, course, cellType, create, length } = props

  const getType = (): CourseDetails["type"] => {
    if (course && create && cellType?.top) return "new_cell:top"

    if (course && create && cellType?.bottom) return "new_cell:bottom"

    if (course && cellType?.update && !course.name)
      return "existing_cell:empty:middle"

    if (course && cellType?.update) return "existing_cell:course:middle"

    if (!course && create) return "new_cell:end"

    return "new_cell:end"
  }

  const [courseDetails, setCourseDetails] = useState<CourseDetails>({
    id: -1,
    course_id: -1,
    code: "",
    name: "",
    workmethod: {
      id: -1,
      name: "Standaard",
    },
    team: {
      id: -1,
      name: "Geen team",
    },
    meetings: 0,
    meeting_duration: 0,
    correction_time: 0,
    travel_time: 0,
    teachers_simultaniously_teaching: 0,
    contact_student: 0,
    raise_factor: 0,
    pos_x:
      (course && create) || (course?.id && cellType?.update)
        ? course.pos_x
        : -1,
    pos_y:
      getType() === "new_cell:top"
        ? course!.pos_y
        : getType() === "new_cell:bottom"
        ? course!.pos_y + 1
        : getType() === "existing_cell:empty:middle" ||
          getType() === "existing_cell:course:middle"
        ? course!.pos_y
        : -1,
    update: cellType?.update ? true : false,
    side: cellType?.top ? "top" : "bottom",
    type: getType(),
  })
  const [courseDetailsErrors, setCourseDetailsErrors] = useState<
    Errors<CourseDetails>[]
  >([])
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  )
  const [createCourseStatus, setCreateCourseStatus] = useFetchState()
  const [updateCourseStatus, setUpdateCourseStatus] = useFetchState()
  const [removeCourseStatus, setRemoveCourseStatus] = useFetchState()

  const { data, error } = useSWR<ExistingCourses[]>(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/existing-courses`
  )
  const [existingCourses, setExistingCourses] = useState<ExistingCourses[]>([])

  const {
    data: dataAvailableWorkmethods,
    error: errorAvailableWorkmethods,
  } = useSWR<Workmethod[]>(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/available-work-methods`
  )

  const { data: dataAvailableTeams, error: errorAvailableTeams } = useSWR<
    Team[]
  >(
    currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/available-teams`
  )
  const [availableTeams, setAvailableTeams] = useState<TeamOptions[]>([])

  const { data: dataCourseDetails, error: errorCourseDetails } = useSWR<
    CourseDetails
  >(
    getType() === "existing_cell:course:middle"
      ? `/api/planning/course/${course?.id}`
      : null
  )
  const { data: dataDefaultValues, error: errorDefaultValues } = useSWR<
    DefaultValues
  >(
    () =>
      currentCurriculumAndKind.curriculum &&
      currentCurriculumAndKind.kind &&
      `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/default-values`
  )

  useEffect(() => {
    if (typeof data !== "undefined") {
      setExistingCourses(data)
    }

    if (typeof dataCourseDetails !== "undefined") {
      setCourseDetails({
        ...courseDetails,
        ...dataCourseDetails,
      })
    }

    if (
      typeof dataAvailableWorkmethods !== "undefined" &&
      getType() !== "existing_cell:course:middle"
    ) {
      console.log("set workmethod")

      setCourseDetails({
        ...courseDetails,
        workmethod:
          dataAvailableWorkmethods.filter((wm) => wm.name === "Standaard")
            .length === 1
            ? dataAvailableWorkmethods.filter(
                (wm) => wm.name === "Standaard"
              )[0]
            : dataAvailableWorkmethods[0],
      })
    }

    if (typeof dataAvailableTeams !== "undefined") {
      let arr = dataAvailableTeams as TeamOptions[]

      arr = arr.map((aco) => ({
        ...aco,
        type: "Beschikbare teams",
      }))

      setAvailableTeams(arr)
    }

    if (typeof dataDefaultValues !== "undefined") {
      const type = getType()
      if (
        type === "new_cell:bottom" ||
        type === "new_cell:end" ||
        type === "new_cell:top" ||
        type === "existing_cell:empty:middle"
      ) {
        setCourseDetails({
          ...courseDetails,
          meetings: dataDefaultValues.meetings,
          contact_student: dataDefaultValues.contact_students,
          correction_time: dataDefaultValues.correction_time,
          travel_time: dataDefaultValues.travel_time,
          raise_factor: dataDefaultValues.raise_factor,
        })
      }
    }

    return () => {
      setCourseDetails({
        id: -1,
        course_id: -1,
        code: "",
        name: "",
        workmethod: {
          id: -1,
          name: "Standaard",
        },
        team: {
          id: -1,
          name: "Geen team",
        },
        meetings: 0,
        meeting_duration: 0,
        correction_time: 0,
        travel_time: 0,
        teachers_simultaniously_teaching: 0,
        contact_student: 0,
        raise_factor: 0,
        pos_x:
          (course && create) || (course?.id && cellType?.update)
            ? course.pos_x
            : -1,
        pos_y:
          getType() === "new_cell:top"
            ? course!.pos_y
            : getType() === "new_cell:bottom"
            ? course!.pos_y + 1
            : getType() === "existing_cell:empty:middle" ||
              getType() === "existing_cell:course:middle"
            ? course!.pos_y
            : -1,
        update: cellType?.update ? true : false,
        side: cellType?.top ? "top" : "bottom",
        type: getType(),
      })
      setShowDeleteConfirmation(false)
    }
  }, [
    data,
    dataCourseDetails,
    dataAvailableWorkmethods,
    dataAvailableTeams,
    dataDefaultValues,
    create,
    cellType,
    course,
  ])

  const handleStringChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const createCourse = () => {
    CourseOverlayValidiationObject.validate(courseDetails, {
      abortEarly: false,
    })
      .then(() => {
        setCreateCourseStatus("loading")
        fetch(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/course`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(courseDetails),
          }
        ).then(async (r) => {
          setCreateCourseStatus("success")
          mutate(
            `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
          )
          handleClose()
        })
      })
      .catch((e: ValidationError) =>
        checkValidationErrors<CourseDetails>(e, setCourseDetailsErrors)
      )
  }

  const updateCourse = () => {
    CourseOverlayValidiationObject.validate(courseDetails, {
      abortEarly: false,
    })
      .then(() => {
        setUpdateCourseStatus("loading")
        fetch(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/course`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(courseDetails),
          }
        ).then(async (r) => {
          setUpdateCourseStatus("success")
          mutate(
            `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
          )
          mutate(
            `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
          )
          handleClose()
        })
      })
      .catch((e: ValidationError) =>
        checkValidationErrors<CourseDetails>(e, setCourseDetailsErrors)
      )
  }

  const removeCourseCheckLength = () =>
    length === 1 ? setShowDeleteConfirmation(true) : removeCourse()

  const removeCourse = () => {
    setRemoveCourseStatus("loading")
    fetch(`/api/planning/course/${course!.course_id}`, {
      method: "DELETE",
    }).then(() => {
      setRemoveCourseStatus("success")
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
      )
      setShowDeleteConfirmation(false)
      handleClose()
    })
  }

  return (
    <div className="cell-overlay-container">
      <div className="cell-overlay-section course-details">
        <div className="cell-overlay-section-header">
          <span className="cell-overlay-section-instruction">
            {create ? "Maak een nieuwe cursus aan" : "Wijzig de cursus"}
          </span>
        </div>
        <div className="cell-overlay-section-content">
          <div className="column-2">
            <TextField
              fullWidth
              label="Cursuscode"
              variant="outlined"
              type="text"
              name="code"
              value={courseDetails.code}
              onChange={(e) => handleStringChange(e)}
              error={courseDetailsErrors.some((e) => e.path === "code")}
              helperText={
                courseDetailsErrors.find((e) => e.path === "code")?.message
              }
              size="small"
            />
            <TextField
              fullWidth
              label="Naam"
              variant="outlined"
              type="text"
              name="name"
              value={courseDetails.name}
              onChange={(e) => handleStringChange(e)}
              error={courseDetailsErrors.some((e) => e.path === "name")}
              helperText={
                courseDetailsErrors.find((e) => e.path === "name")?.message
              }
              size="small"
            />
          </div>
          <div className="column-2">
            {!dataAvailableWorkmethods ? (
              <Loader />
            ) : errorAvailableWorkmethods ? (
              <ErrorState />
            ) : (
              <Autocomplete
                value={courseDetails.workmethod}
                onChange={(e, v) =>
                  setCourseDetails({ ...courseDetails, workmethod: v })
                }
                disableClearable
                options={dataAvailableWorkmethods}
                getOptionLabel={(option) => option.name}
                noOptionsText={"Geen werkmethode beschikbaar"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Werkmethode"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}

            {!dataAvailableTeams ? (
              <Loader />
            ) : errorAvailableTeams ? (
              <ErrorState />
            ) : (
              <Autocomplete
                value={{ ...courseDetails.team, type: "Beschikbare teams" }}
                onChange={(e, v) =>
                  v !== null
                    ? setCourseDetails({ ...courseDetails, team: v })
                    : setCourseDetails({
                        ...courseDetails,
                        team: { id: -1, name: "" },
                      })
                }
                onBlur={() =>
                  courseDetails.team.id === -1 &&
                  setCourseDetails({
                    ...courseDetails,
                    team: { id: -1, name: "Geen team" },
                  })
                }
                options={availableTeams.sort((a, b) =>
                  a.type < b.type ? 1 : -1
                )}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.name}
                noOptionsText={"Geen teams beschikbaar"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          </div>
          <Divider />
          <div className="column-2">
            <TextField
              fullWidth
              label="w"
              variant="outlined"
              type="number"
              name="meetings"
              value={courseDetails.meetings}
              onChange={(e) => handleNumberChange(e)}
              error={courseDetailsErrors.some((e) => e.path === "meetings")}
              helperText={
                courseDetailsErrors.find((e) => e.path === "meetings")
                  ? courseDetailsErrors.find((e) => e.path === "meetings")
                      ?.message
                  : "Aantal lesmomenten"
              }
              size="small"
            />
            <TextField
              fullWidth
              label="Ct"
              variant="outlined"
              type="number"
              name="contact_student"
              value={courseDetails.contact_student}
              onChange={(e) => handleNumberChange(e)}
              error={courseDetailsErrors.some(
                (e) => e.path === "contact_student"
              )}
              helperText={
                courseDetailsErrors.find((e) => e.path === "contact_student")
                  ? courseDetailsErrors.find(
                      (e) => e.path === "contact_student"
                    )?.message
                  : "Contacttijd lesmoment met studenten (min)"
              }
              size="small"
            />
          </div>
          <div className="column-2">
            <TextField
              fullWidth
              label="Opslag"
              variant="outlined"
              type="number"
              name="raise_factor"
              value={courseDetails.raise_factor}
              onChange={(e) => handleNumberChange(e)}
              error={courseDetailsErrors.some((e) => e.path === "raise_factor")}
              helperText={
                courseDetailsErrors.find((e) => e.path === "raise_factor")
                  ? courseDetailsErrors.find((e) => e.path === "raise_factor")
                      ?.message
                  : "Opslagfactor"
              }
              size="small"
            />
            <TextField
              fullWidth
              label="Cor"
              variant="outlined"
              type="number"
              name="correction_time"
              value={courseDetails.correction_time}
              onChange={(e) => handleNumberChange(e)}
              error={courseDetailsErrors.some(
                (e) => e.path === "correction_time"
              )}
              helperText={
                courseDetailsErrors.find((e) => e.path === "correction_time")
                  ? courseDetailsErrors.find(
                      (e) => e.path === "correction_time"
                    )?.message
                  : "Correctietijd per student (min)"
              }
              size="small"
            />
          </div>
          <div className="column-2">
            <TextField
              fullWidth
              label="R"
              variant="outlined"
              type="number"
              name="travel_time"
              value={courseDetails.travel_time}
              onChange={(e) => handleNumberChange(e)}
              error={courseDetailsErrors.some((e) => e.path === "travel_time")}
              helperText={
                courseDetailsErrors.find((e) => e.path === "travel_time")
                  ? courseDetailsErrors.find((e) => e.path === "travel_time")
                      ?.message
                  : "Reistijd"
              }
              size="small"
            />
          </div>
          <div className="column-2"></div>
        </div>
        <div className="cell-overlay-section-footer">
          <Button
            disabled={createCourseStatus.loading || updateCourseStatus.loading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => (create ? createCourse() : updateCourse())}
          >
            {create ? "Maak aan en voeg toe" : "Sla wijzigingen op"}
          </Button>
          {cellType?.update && (
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={() => removeCourseCheckLength()}
              innerProps={{ style: { marginTop: "12px" } }}
            >
              Verwijder rij uit de planning
            </Button>
          )}
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className="cell-overlay-section course-autocomplete">
        <div className="cell-overlay-section-header">
          <span className="cell-overlay-section-instruction">
            Selecteer een bestaande cursus
          </span>
        </div>
        <div className="cell-overlay-section-content">
          <Autocomplete
            multiple={false}
            options={existingCourses.sort(
              (a, b) => -b.group_by.localeCompare(a.group_by)
            )}
            groupBy={(option) => option.group_by}
            getOptionLabel={(option) =>
              `${option.code}${
                option.workmethod.name !== "Standaard"
                  ? ` - ${option.workmethod.name}`
                  : ""
              }`
            }
            onChange={(e, v) => {
              if (v) {
                setCourseDetails({
                  ...courseDetails,
                  ...v,
                  team:
                    v.team.id === 0 ? { id: -1, name: "Geen team" } : v.team,
                })
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Cursuscode" variant="outlined" />
            )}
          />
        </div>
      </div>
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        title={`Cursus verwijderen`}
        content={() => (
          <>
            <InformationCard
              type="warning"
              content={() => (
                <>
                  <strong>Alle</strong> klassen worden ook verwijderd als je
                  deze cursus verwijderd.
                  <br />
                  <br />
                  Weet je het zeker?
                </>
              )}
            />
          </>
        )}
        actions={() => (
          <>
            <Button onClick={() => setShowDeleteConfirmation(false)}>
              Annuleer
            </Button>
            <Button
              disabled={removeCourseStatus.loading}
              onClick={() => removeCourse()}
              color="primary"
              variant="contained"
            >
              Verwijder cursus uit de planning
            </Button>
          </>
        )}
      />
    </div>
  )
}

const CourseOverlayValidiationObject = object<CourseDetails>({
  id: number().required(),
  course_id: number().required(),
  code: string().required(),
  name: string().required(),
  workmethod: object<CourseDetails["workmethod"]>({
    id: number().required(),
    name: string().required(),
  }).required(),
  team: object<CourseDetails["team"]>({
    id: number().required(),
    name: string().required(),
  }).required(),
  meetings: number().required(),
  meeting_duration: number().required(),
  correction_time: number().required(),
  travel_time: number().required(),
  teachers_simultaniously_teaching: number().required(),
  contact_student: number().required(),
  raise_factor: number().required(),
  pos_x: number().required(),
  pos_y: number().required(),
  update: boolean().required(),
  side: string(),
  type: string(),
})

type CellWorkload = {
  workload: number
}

type AvailableTeachers = {
  name: string
  teachers: {
    id: number
    personnel_code: string
    available_hours: number
  }[]
}

type Label = {
  id: number
  name: string
  color: string
}

type Note = {
  id: number
  note: string
}

type CellOverlayProps = {
  cell: StudyYearCell
  course: StudyYearCoursCell
  year: number
  op: number
}

export const CellOverlay = (props: CellOverlayProps) => {
  const { cell, course, year, op } = props
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [teams, setTeams] = useState<AvailableTeachers[]>([])
  const [note, setNote] = useState<Note>({
    id: -1,
    note: "",
  })
  const [teacherWorkloadOverrides, setTeacherWorkloadOverrides] = useState<
    (string | number)[]
  >(cell.teachers.map((t) => t.workload_override as number))
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [saveCellStatus, setSaveCellStatus] = useFetchState()

  const { data: dataWorkload, error: errorWorkload } = useSWR<CellWorkload>(
    `/api/planning/cell/${cell.id}/teacher-workload`
  )
  const { data: dataAvailableTeachers, error: errorAvailableTeachers } = useSWR<
    AvailableTeachers[]
  >(`/api/planning/cell/${cell.id}/available-teachers`)
  const { data: dataLabels, error: errorLabels } = useSWR<Label[]>(
    `/api/planning/cell/${cell.id}/labels`
  )
  const { data: dataNote, error: errorNote } = useSWR<Note>(
    `/api/planning/cell/${cell.id}/note`
  )

  useEffect(() => {
    if (typeof dataAvailableTeachers !== "undefined") {
      setTeams(dataAvailableTeachers)
    }
  }, [dataAvailableTeachers])

  useEffect(() => {
    if (typeof dataNote !== "undefined") {
      setNote(dataNote)
    }
  }, [dataNote])

  useEffect(() => {
    setTeacherWorkloadOverrides(
      cell.teachers.map((t) => t.workload_override as number)
    )
  }, [cell])

  useEffect(() => {
    if (searchTerm.length > 0) {
      let filteredTeams: AvailableTeachers[] = []

      teams.forEach((t) => {
        let filteredList = t.teachers.filter((teacher) =>
          teacher.personnel_code
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
        if (filteredList.length > 0) {
          filteredTeams.push({
            name: t.name,
            teachers: filteredList,
          })
        }
      })

      setTeams(filteredTeams)
    } else {
      if (typeof dataAvailableTeachers !== "undefined") {
        setTeams(dataAvailableTeachers)
      }
    }
  }, [searchTerm])

  const handleWorkloadOverrideChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    let list = [...teacherWorkloadOverrides]
    list[index] =
      e.target.value.length > 0 ? Number(e.target.value) : e.target.value

    setTeacherWorkloadOverrides([...list])
  }

  const saveCell = async () => {
    setSaveCellStatus("loading")
    if (dataNote?.note !== note.note) {
      await saveNote()
    }
    fetch(`/api/planning/cell/${cell.id}/teacher-workload-override`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        cell.teachers.map((v, i) => {
          return {
            ...v,
            cell_id: cell.id,
            workload_override: teacherWorkloadOverrides[i],
          }
        })
      ),
    }).then(() => {
      setSaveCellStatus("success")
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
      )
      snackbarText("De wijzigingen zijn opgeslagen")
      snackbarVisible(true)
    })
  }

  const setLabel = (id: number) => {
    setSaveCellStatus("loading")
    fetch(`/api/planning/cell/${cell.id}/label`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    }).then(() => {
      setSaveCellStatus("success")
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
      snackbarText("Het label is toegewijzen")
      snackbarVisible(true)
    })
  }

  const removeLabel = () => {
    setSaveCellStatus("loading")
    fetch(`/api/planning/cell/${cell.id}/label`, { method: "DELETE" }).then(
      () => {
        setSaveCellStatus("success")
        mutate(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
        )
        snackbarText("Het label is verwijderd")
        snackbarVisible(true)
      }
    )
  }

  const saveNote = () => {
    return fetch(`/api/planning/cell/${cell.id}/note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(note),
    }).then(() => {
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
    })
  }

  const removeNote = () => {
    setSaveCellStatus("loading")
    fetch(`/api/planning/cell/${cell.id}/note`, { method: "DELETE" }).then(
      () => {
        setSaveCellStatus("success")
        mutate(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
        )
        mutate(
          `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
        )
        snackbarText("De notitie is verwijderd")
        snackbarVisible(true)
      }
    )
  }

  const assignTeacherToCell = (id: number) => {
    setSaveCellStatus("loading")
    fetch(`/api/planning/cell/${cell.id}/teacher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    }).then(() => {
      setSaveCellStatus("success")
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
      )
      mutate(`/api/planning/cell/${cell.id}/available-teachers`)
      snackbarText("De docent is toegewezen")
      snackbarVisible(true)
    })
  }

  const removeTeacherFromCell = (id: number) => {
    setSaveCellStatus("loading")
    fetch(`/api/planning/cell/${cell.id}/teacher`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    }).then(() => {
      setSaveCellStatus("success")
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}`
      )
      mutate(
        `/api/planning/${currentCurriculumAndKind.curriculum}/${currentCurriculumAndKind.kind}/${year}/${op}/notes`
      )
      mutate(`/api/planning/cell/${cell.id}/available-teachers`)
      snackbarText("De docent is uit de cell verwijderd")
      snackbarVisible(true)
    })
  }

  return (
    <div className="cell-overlay-teacher-container">
      <div className="cell-overlay-teacher-header-container">
        <span className="cell-overlay-teacher-header-course">
          {course.name}
        </span>
        <Divider orientation="vertical" flexItem />
        <span className="cell-overlay-teacher-header-teacher-workload">
          Belasting:
          {dataWorkload ? (
            ` ${dataWorkload.workload} ${
              dataWorkload.workload > 1 ? "uren" : "uur"
            }`
          ) : (
            <CircularProgress style={{ marginLeft: "12px" }} size={16} />
          )}
        </span>
        <span>
          <Button
            fetchStatus={[saveCellStatus, setSaveCellStatus]}
            className=""
            variant="contained"
            color="primary"
            onClick={() => saveCell()}
          >
            Sla wijzigingen op
          </Button>
        </span>
      </div>
      <Divider />
      <div className="cell-overlay-teacher-details-container">
        <div className="cell-overlay-teacher-assing-container">
          <List>
            {cell.teachers.length > 0 && dataWorkload ? (
              <>
                {cell.teachers.map((teacher, key) => {
                  return (
                    <ListItem
                      key={`${key}-${teacher.id}`}
                      className="cell-overlay-teacher-assigned-item"
                      style={{
                        backgroundColor:
                          teacher.available_hours < 0
                            ? hex2rgba("#cc0033", 0.2)
                            : "white",
                      }}
                    >
                      <ListItemText
                        primary={`${teacher.personnel_code}`}
                        className="cell-overlay-teacher-assigned-personnel-code"
                      />
                      <ListItemSecondaryAction className="cell-overlay-teacher-assigned-actions">
                        <span style={{ marginRight: "6px" }}>
                          {dataWorkload ? (
                            `${
                              teacher.available_hours +
                              ((teacher.workload_override as unknown) as number) +
                              dataWorkload.workload
                            } ⮕ ${
                              teacher.available_hours +
                              ((teacher.workload_override as unknown) as number) -
                              (typeof teacherWorkloadOverrides[key] === "number"
                                ? ((teacherWorkloadOverrides[
                                    key
                                  ] as unknown) as number)
                                : 0)
                            }`
                          ) : (
                            <CircularProgress
                              style={{ marginLeft: "12px" }}
                              size={16}
                            />
                          )}
                        </span>
                        <TextField
                          className="cell-overlay-teacher-assigned-workload-override-input"
                          value={teacherWorkloadOverrides[key]}
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(e) => handleWorkloadOverrideChange(e, key)}
                        />
                        <IconButton
                          size="small"
                          color="secondary"
                          className="cell-overlay-teacher-assigned-remove-button"
                          onClick={() => removeTeacherFromCell(teacher.id)}
                        >
                          <RemoveCircleRoundedIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </>
            ) : (
              <ListItem className="available-teachers-list-empty-team">
                <ListItemText primary={`Er zijn geen docenten toegewezen`} />
              </ListItem>
            )}
          </List>
          <Divider style={{ marginBottom: "24px" }} />
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            variant="outlined"
            label="Docentcode"
            size="small"
            style={{ marginBottom: "12px" }}
          />
          <div className="cell-overlay-teacher-assing-available-teachers">
            {dataAvailableTeachers ? (
              <List subheader={<li />}>
                {teams.length > 0 ? (
                  <>
                    {teams.map((team) => (
                      <li
                        key={`section-${team.name
                          .toLowerCase()
                          .replace(" ", "")}`}
                      >
                        <ul>
                          <ListSubheader className="available-teachers-list-header">{`${team.name}`}</ListSubheader>
                          {team.teachers.length > 0 ? (
                            <>
                              {team.teachers.map((teacher) => (
                                <ListItem
                                  onClick={() =>
                                    assignTeacherToCell(teacher.id)
                                  }
                                  button
                                  key={`item-${team.name}-${teacher.id}`}
                                  style={{
                                    backgroundColor:
                                      teacher.available_hours < 0
                                        ? hex2rgba("#cc0033", 0.2)
                                        : "white",
                                  }}
                                  className="available-teachers-list-item"
                                >
                                  <span style={{ marginRight: "auto" }}>
                                    {teacher.personnel_code}
                                  </span>
                                  <span>
                                    {dataWorkload &&
                                      ` ${teacher.available_hours} ⮕ ${
                                        teacher.available_hours -
                                        dataWorkload.workload
                                      }`}
                                  </span>
                                </ListItem>
                              ))}
                            </>
                          ) : (
                            <>
                              <ListItem className="available-teachers-list-empty-team">
                                <ListItemText primary={`Geen docenten`} />
                              </ListItem>
                            </>
                          )}
                        </ul>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    <ListItem className="available-teachers-list-empty-team">
                      <ListItemText primary={`Geen zoekresultaten`} />
                    </ListItem>
                  </>
                )}
              </List>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="cell-overlay-teacher-side-container">
          <div className="cell-overlay-teacher-label-container">
            <div style={{ overflow: "auto" }}>
              {dataLabels &&
                dataLabels.map((label, key) => (
                  <div
                    key={`${key}=${label.id}`}
                    className="cell-overlay-teacher-label"
                    onClick={() => setLabel(label.id)}
                    style={{
                      background: hex2rgba(label.color, 0.2),
                      color: label.color,
                      border: `1px solid ${label.color}`,
                    }}
                  >
                    {label.name}
                  </div>
                ))}
            </div>
            <div className="cell-overlay-teacher-label-remove">
              <Button
                variant="text"
                onClick={() => removeLabel()}
                disabled={saveCellStatus.loading}
                fullWidth
              >
                Verwijder label
              </Button>
            </div>
          </div>
          <Divider />
          <div className="cell-overlay-teacher-notes-container">
            <h6 className="subkop cell-overlay-teacher-notes-title">Notitie</h6>
            {dataNote ? (
              <>
                <TextField
                  className="cell-overlay-teacher-notes-input"
                  variant="outlined"
                  value={note.note}
                  onChange={(e) => setNote({ ...note, note: e.target.value })}
                  rows={4}
                  multiline={true}
                />
                {/* <Button
                  className="cell-overlay-teacher-notes-save"
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => saveNote()}
                >
                  Sla op
                </Button> */}
                <Button
                  className="cell-overlay-teacher-notes-delete"
                  disabled={saveCellStatus.loading}
                  fullWidth
                  variant="text"
                  onClick={() => {
                    removeNote()
                    setNote({
                      id: -1,
                      note: "",
                    })
                  }}
                >
                  Verwijder notitie
                </Button>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
