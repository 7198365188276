import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import {
  currentCurriculumState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import { Errors, ExceptionResponse, useFetchState } from "../../utils"
import { Dialog } from "../Dialog"
import { ErrorState } from "../ErrorState"
import { Loader } from "../Loader"
import { Button } from "../Buttons"

type Personalia = {
  id: number
  curriculum_id: number
  personnel_code: string
  first_name: string
  last_name: string
  placeholder: boolean
  team: Team
  is_teamleader_of_team: boolean
  position: Position
  courseowner: Courseowner[]
  organisation: string
}

type Position = {
  id: number
  name: string
}

type Courseowner = {
  id: number
  code: string
}

type Team = {
  id: number
  name: string
}

export const TeacherPersonalia = () => {
  let history = useHistory()
  let { id }: { id: string } = useParams()

  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [updateTeacherStatus, setUpdateTeacherStatus] = useFetchState()
  const [deleteTeacherStatus, setDeleteTeacherStatus] = useFetchState()
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<
    boolean
  >(false)
  const [errors, setErrors] = useState<Errors<Personalia>[]>([])
  const [personalia, setPersonalia] = useState<Personalia>({
    id: -1,
    curriculum_id: -1,
    personnel_code: "",
    first_name: "",
    last_name: "",
    placeholder: false,
    team: { id: -1, name: "" },
    is_teamleader_of_team: false,
    position: { id: -1, name: "" },
    courseowner: [],
    organisation: "",
  })

  const { data, error } = useSWR<Personalia>(`/api/teacher/${id}/personalia`)
  const {
    data: dataAvailablePositions,
    error: errorAvailablePositions,
  } = useSWR<Position[]>(
    typeof data !== "undefined"
      ? `/api/teacher/${data.curriculum_id}/available-positions`
      : null
  )
  // const { data: dataAvailableCourseowner, error: errorAvailableCourseowner } = useSWR<Courseowner[]>(
  //   typeof data !== 'undefined' ? `/api/teacher/${data.curriculum_id}/available-courseowner` : null)
  const { data: dataAvailableTeams, error: errorAvailableTeams } = useSWR<
    Position[]
  >(
    typeof data !== "undefined"
      ? `/api/teacher/${data.curriculum_id}/available-teams`
      : null
  )

  useEffect(() => {
    if (typeof data !== "undefined") {
      setPersonalia(data)
    }
  }, [data])

  if (!data) return <Loader />

  if (error) return <ErrorState />

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setPersonalia({
      ...personalia,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setPersonalia({
      ...personalia,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalia({ ...personalia, [event.target.name]: event.target.checked })
  }

  const updatePersonalia = () => {
    setUpdateTeacherStatus("loading")
    fetch(`/api/teacher/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(personalia),
    }).then(async (r) => {
      if (r.ok) {
        setUpdateTeacherStatus("success")
        setSnackbarText(`De wijzigingen zijn opgeslagen`)
        setSnackbarVisible(true)
        mutate(`/api/teacher/${id}/personalia`)
      } else {
        setUpdateTeacherStatus("error")
        const json: ExceptionResponse = await r.json()
        console.log(json)
        setSnackbarText(
          `Er is iets fout gegaan bij het opslaan van de wijzigingen`
        )
        setSnackbarVisible(true)
      }
    })
  }

  const deleteTeacher = () => {
    setDeleteTeacherStatus("loading")
    fetch(`/api/teacher/delete/${data.id}`, {
      method: "DELETE",
    }).then(async (r) => {
      if (r.ok) {
        setDeleteTeacherStatus("success")
        setSnackbarText(`De docent is verwijderd`)
        setSnackbarVisible(true)
        history.replace("/teachers")
        mutate(`/api/teacher/all/${data.curriculum_id}`)
      } else {
        setDeleteTeacherStatus("error")
        const json: ExceptionResponse = await r.json()
        console.log(json)
        setSnackbarText(
          `Er is iets fout gegaan bij het verwijderen van de docent`
        )
        setSnackbarVisible(true)
      }
    })
  }

  return (
    <>
      <div className="card-content">
        <h2>Personalia</h2>
        <div>
          <TextField
            fullWidth
            label="Docentcode"
            variant="outlined"
            type="text"
            name="personnel_code"
            value={personalia.personnel_code}
            onChange={(e) => handleChange(e)}
            error={errors.some((e) => e.path === "personnel_code")}
            helperText={
              errors.find((e) => e.path === "personnel_code")?.message
            }
          />
        </div>
        <div className="column-2">
          <TextField
            fullWidth
            label="Voornaam"
            variant="outlined"
            type="text"
            name="first_name"
            value={personalia.first_name}
            onChange={(e) => handleChange(e)}
            error={errors.some((e) => e.path === "first_name")}
            helperText={errors.find((e) => e.path === "first_name")?.message}
          />
          <TextField
            fullWidth
            label="Achternaam"
            variant="outlined"
            type="text"
            name="last_name"
            value={personalia.last_name}
            onChange={(e) => handleChange(e)}
            error={errors.some((e) => e.path === "last_name")}
            helperText={errors.find((e) => e.path === "last_name")?.message}
          />
        </div>
        <div>
          <FormControlLabel
            id="teacher-placeholder"
            control={
              <Checkbox
                checked={personalia.placeholder}
                onChange={handleChangeCheckbox}
                name="placeholder"
                color="primary"
              />
            }
            label="Docent is een placeholder:"
            labelPlacement="start"
          />
        </div>
        <Divider />
        <div>
          {!dataAvailablePositions ? (
            <Loader />
          ) : errorAvailablePositions ? (
            <ErrorState />
          ) : (
            <Autocomplete
              value={personalia.position}
              onChange={(e, v) =>
                v !== null
                  ? setPersonalia({ ...personalia, position: v })
                  : setPersonalia({
                      ...personalia,
                      position: data.position,
                    })
              }
              options={dataAvailablePositions}
              getOptionLabel={(option) => option.name}
              noOptionsText={"Geen resultaten"}
              renderInput={(params) => (
                <TextField {...params} label="Functie" variant="outlined" />
              )}
            />
          )}
        </div>
        {/* <div>
          {!dataAvailableCourseowner
            ? <Loader />
          : errorAvailableCourseowner
            ? <ErrorState />
          :  <Autocomplete
              multiple={true}
              limitTags={1}
              onChange={(e, v, r) => setPersonalia({ ...personalia, courseowner: v })}
              options={dataAvailableCourseowner}
              getOptionLabel={(option) => option.code}
              value={personalia.courseowner}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Cursusbeheerder van"
                />
              )}
            />}
        </div> */}
        <div>
          {!dataAvailableTeams ? (
            <Loader />
          ) : errorAvailableTeams ? (
            <ErrorState />
          ) : (
            <Autocomplete
              value={personalia.team}
              onChange={(e, v) =>
                v !== null
                  ? setPersonalia({ ...personalia, team: v })
                  : setPersonalia({
                      ...personalia,
                      team: data.team,
                    })
              }
              options={dataAvailableTeams}
              getOptionLabel={(option) => option.name}
              noOptionsText={"Geen resultaten"}
              renderInput={(params) => (
                <TextField {...params} label="Team" variant="outlined" />
              )}
            />
          )}
        </div>
        <div id="teacher-personalia-actions">
          <Button
            fetchStatus={[updateTeacherStatus, setUpdateTeacherStatus]}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => updatePersonalia()}
          >
            Sla personalia op
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            Verwijder docent
          </Button>
        </div>
      </div>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`Docent verwijderen`}
        content={() => (
          <p>
            Weet je zeker dat je de docent{" "}
            <strong>{personalia.personnel_code}</strong> (
            {personalia.first_name} {personalia.last_name}) wilt verwijderen?
          </p>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteTeacherStatus.loading}
              onClick={() => deleteTeacher()}
              color="primary"
              variant="contained"
            >
              Verwijder docent
            </Button>
          </>
        )}
      />
    </>
  )
}
