import "./TeacherNote.css"
import React, { useEffect, useState } from "react"
import { TeacherNoteType } from "../TeacherDetails/notes"
import EditIcon from "@material-ui/icons/EditRounded"
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineRounded"
import { IconButton, TextField } from "@material-ui/core"
import { Dialog } from "../Dialog"
import { mutate } from "swr"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { snackbarTextState, snackbarVisibleState } from "../../state/app"
import {
  checkValidationErrors,
  Errors,
  ExceptionResponse,
  useFetchState,
} from "../../utils"
import { object, string, ValidationError } from "yup"
import { Button } from "../Buttons"

type TeacherNoteProps = {
  editable?: boolean
  note: TeacherNoteType
}

export const TeacherNote = (props: TeacherNoteProps) => {
  let { id }: { id: string } = useParams()
  const { editable, note } = props
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)

  const [updateNoteStatus, setUpdateNoteStatus] = useFetchState()
  const [deleteNoteStatus, setDeleteNoteStatus] = useFetchState()
  const date = new Date(note.date)
  const dateFormatted = `${date.toLocaleDateString()} @ ${date.getHours()}:${date.getMinutes()}`
  const [editedNote, setEditedNote] = useState<string>(note.note)
  const [errors, setErrors] = useState<Errors<{ note: string }>[]>([])
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<
    boolean
  >(false)
  const [editDialog, setEditDialog] = useState<boolean>(false)

  const newNoteValidationObject = object({
    note: string()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
  })

  const saveNote = () => {
    newNoteValidationObject
      .validate({ note: editedNote }, { abortEarly: false })
      .then(() => {
        setUpdateNoteStatus("loading")
        fetch(`/api/teacher/${id}/note/update/${note.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ note: editedNote }),
        }).then(async (r) => {
          if (r.ok) {
            setUpdateNoteStatus("success")
            setSnackbarText("De notitie is aangepast")
            setSnackbarVisible(true)
            mutate(`/api/teacher/${id}/notes`)
            setEditDialog(false)
          } else {
            setUpdateNoteStatus("error")
            const e: ExceptionResponse = await r.json()
            console.log(e)
            setSnackbarText(
              "Er was een probleem bij het aanpassen van de notitie"
            )
            setSnackbarVisible(true)
          }
        })
      })
      .catch((e: ValidationError) => checkValidationErrors(e, setErrors))
  }
  const deleteNote = () => {
    setDeleteNoteStatus("loading")
    fetch(`/api/teacher/${id}/note/delete/${note.id}`, {
      method: "DELETE",
    }).then(async (r) => {
      if (r.ok) {
        setDeleteNoteStatus("success")
        setSnackbarText("De notitie is verwijderd")
        setSnackbarVisible(true)
        mutate(`/api/teacher/${id}/notes`)
        mutate(`/api/teacher/${id}/notes-from-previous-curricula`)
        setDeleteConfirmationDialog(false)
      } else {
        setDeleteNoteStatus("error")
        const e: ExceptionResponse = await r.json()
        console.log(e)
        setSnackbarText(
          "Er was een probleem bij het verwijderen van de notitie"
        )
        setSnackbarVisible(true)
      }
    })
  }

  return (
    <div className="teacher-note-container">
      <div className="teacher-note-header">
        <div>
          <span className="teacher-note-header-curriculum">
            Curriculum {note.curriculum_name}
          </span>
          <span className="teacher-note-header-date">{dateFormatted}</span>
        </div>
        <div>
          {editable && (
            <IconButton
              aria-label="edit"
              size="small"
              style={{ marginRight: "5px" }}
              onClick={() => setEditDialog(true)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="teacher-note-content">{note.note}</div>
      <Dialog
        open={editDialog}
        onClose={() => setEditDialog(false)}
        title="Notitie aanpassen"
        content={() => (
          <TextField
            style={{ width: "400px" }}
            fullWidth
            multiline
            variant="outlined"
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
            error={errors.some((e) => e.path === "note")}
            helperText={errors.find((e) => e.path === "note")?.message}
          />
        )}
        actions={() => (
          <>
            <Button onClick={() => setEditDialog(false)}>Annuleer</Button>
            <Button
              disabled={updateNoteStatus.loading}
              onClick={() => saveNote()}
              color="primary"
              variant="contained"
            >
              Opslaan
            </Button>
          </>
        )}
      />
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title="Notitie verwijderen"
        content={() => <p>Weet je zeker dat je de notitie wilt verwijderen?</p>}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteNoteStatus.loading}
              onClick={() => deleteNote()}
              color="primary"
              variant="contained"
            >
              Verwijder notitie
            </Button>
          </>
        )}
      />
    </div>
  )
}
