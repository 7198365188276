import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import useSWR from "swr"
import {
  currentCurriculumAndKindObject,
  planningViewState,
} from "../../state/planning"
import { StudyYear } from "./StudyYear"

export const Planning = () => {
  const currentCurriculumAndKind = useRecoilValue(
    currentCurriculumAndKindObject
  )
  const planningView = useRecoilValue(planningViewState)
  const [selectedStudyYear, setSelectedStudyYear] = useState<number[]>([])
  const [selectedOP, setSelectedOP] = useState<number[]>([])

  useEffect(() => {
    if (planningView === "study-year") {
      setSelectedStudyYear([1, 2, 3, 4])
      setSelectedOP([1, 1, 1, 1])
    } else {
      setSelectedStudyYear([1, 1, 1, 1])
      setSelectedOP([1, 2, 3, 4])
    }
  }, [planningView])

  return (
    <div id="content">
      <StudyYear
        position={0}
        selectedStudyYear={selectedStudyYear}
        selectedOP={selectedOP}
        year_and_op={{
          year: { value: selectedStudyYear, setValue: setSelectedStudyYear },
          op: { value: selectedOP, setValue: setSelectedOP },
        }}
      />
      <StudyYear
        position={1}
        selectedStudyYear={selectedStudyYear}
        selectedOP={selectedOP}
        year_and_op={{
          year: { value: selectedStudyYear, setValue: setSelectedStudyYear },
          op: { value: selectedOP, setValue: setSelectedOP },
        }}
      />
      <StudyYear
        position={2}
        selectedStudyYear={selectedStudyYear}
        selectedOP={selectedOP}
        year_and_op={{
          year: { value: selectedStudyYear, setValue: setSelectedStudyYear },
          op: { value: selectedOP, setValue: setSelectedOP },
        }}
      />
      <StudyYear
        position={3}
        selectedStudyYear={selectedStudyYear}
        selectedOP={selectedOP}
        year_and_op={{
          year: { value: selectedStudyYear, setValue: setSelectedStudyYear },
          op: { value: selectedOP, setValue: setSelectedOP },
        }}
      />
    </div>
  )
}
