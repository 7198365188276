import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core"
import React, { useState } from "react"
import useSWR from "swr"
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer"
import {
  ExportDefaultFooter,
  ExportDefaultHeader,
  ExportPDFButton,
  pageDefaultStyles,
} from "."
import { Button } from "../Buttons"
import { FullScreenDialog } from "../Dialog"
import { TaakToedelingType } from "../TeacherDetails/tabs/aanstelling"
import ExportTeacherPersonalia from "./ExportTeacherPersonalia"
import ExportTeacherOnderwijstaken from "./ExportTeacherOnderwijstaken"
import ExportTeacherTaaktoedeling from "./ExportTeacherTaaktoedeling"
import InformationCard from "../InformationCard"
import ExportTeacherMOT from "./ExportTeacherMOT"
import ExportTeacherSecondments from "./ExportTeacherSecondments"

const ExportTeacherStyles = StyleSheet.create({
  page: {
    ...pageDefaultStyles,
  },
})

type ExportTeacherProps = {
  teacher: ExportTeacherResponse
  options: ExportTeacherOptions
}

const ExportTeacher = (props: ExportTeacherProps) => {
  const { teacher, options } = props
  const styles = ExportTeacherStyles

  return (
    <Document
      title={`${teacher.curriculum} - ${teacher.institute} - ${
        teacher.education
      } - ${
        teacher.personnel_code
      } - ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`}
      author={`PTD`}
      creator={`Rotterdam University of Applied Sciences`}
      producer={`Rotterdam University of Applied Sciences`}
    >
      <Page orientation="landscape" size="A4" style={styles.page}>
        <ExportDefaultHeader
          title="Export docent"
          subtitle={`${teacher.curriculum} - ${teacher.institute} - ${teacher.education} - ${teacher.personnel_code} (${teacher.surname}, ${teacher.name})`}
        />
        {options.personalia && (
          <ExportTeacherPersonalia personalia={teacher.personalia} />
        )}
        {options.taaktoedeling && (
          <ExportTeacherTaaktoedeling data={teacher.taaktoedeling} />
        )}
        {options.mot && <ExportTeacherMOT data={teacher.mot} />}
        {options.secondments && (
          <ExportTeacherSecondments data={teacher.secondments} />
        )}
        {options.onderwijstaken && (
          <ExportTeacherOnderwijstaken
            onderwijstaken={teacher.onderwijstaken}
          />
        )}
        <ExportDefaultFooter />
      </Page>
    </Document>
  )
}

type ExportTeacherDialogProps = {
  open: boolean
  onClose: () => void
  teacher_id: string
}

type ExportTeacherResponse = {
  name: string
  surname: string
  personnel_code: string
  curriculum: string
  education: string
  institute: string
  personalia: ExportTeacherPersonaliaType
  onderwijstaken: ExportTeacherOnderwijstakenType[]
  taaktoedeling: ExportTeacherTaaktoedelingType
  mot: ExportTeacherMotType[]
  secondments: ExportTeacherSecondmentType[]
}

export type ExportTeacherPersonaliaType = {
  personnel_code: string
  name: string
  surname: string
  position: string
  team: string
  contract: string
}

export type ExportTeacherOnderwijstakenType = {
  period: number
  course_code: string
  course_name: string
  curriculum_kind: string
  workmethod: string
  student_amount: number
  contact_student: number
  meetings: number
  meeting_duration: number
  raise_factor: number
  correction_time: number
  travel_time: number
}

export type ExportTeacherTaaktoedelingType = {
  fte_op1: number
  fte_op2: number
  fte_op3: number
  fte_op4: number
  di_op1: number
  di_op2: number
  di_op3: number
  di_op4: number
  organisational: number
  professional: number
  taaktoedeling: TaakToedelingType
}

export type ExportTeacherMotType = {
  name: string
  type: string
  hours: number
  op1: boolean
  op2: boolean
  op3: boolean
  op4: boolean
}

export type ExportTeacherSecondmentType = {
  hours: number
  reason: string
  op1: boolean
  op2: boolean
  op3: boolean
  op4: boolean
}

type ExportTeacherOptions = {
  personalia: boolean
  taaktoedeling: boolean
  mot: boolean
  secondments: boolean
  onderwijstaken: boolean
}

export const ExportTeacherDialog = (props: ExportTeacherDialogProps) => {
  const { open, onClose, teacher_id } = props
  const [options, setOptions] = useState<ExportTeacherOptions>({
    personalia: true,
    taaktoedeling: true,
    mot: true,
    secondments: true,
    onderwijstaken: true,
  })

  const { data, error } = useSWR<ExportTeacherResponse>(
    open ? `/api/teacher/${teacher_id}/export` : null,
    (url) =>
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(options),
      }).then((r) => r.json())
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions({ ...options, [event.target.name]: event.target.checked })
  }

  return (
    <>
      <FullScreenDialog
        title={"Exporteer docent"}
        open={open}
        onClose={onClose}
        content={() => (
          <div className="export-dialog-container">
            <div className="export-dialog-fullscreen-sidebar-container">
              <h6 className="subkop">Exporteer instellingen</h6>
              <FormControl disabled component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.personalia}
                        onChange={handleChange}
                        name="personalia"
                      />
                    }
                    label="Personalia"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.taaktoedeling}
                        onChange={handleChange}
                        name="taaktoedeling"
                      />
                    }
                    label="Taaktoedeling"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.mot}
                        onChange={handleChange}
                        name="mot"
                      />
                    }
                    label="MOT taken"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.secondments}
                        onChange={handleChange}
                        name="secondments"
                      />
                    }
                    label="Detachering"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={options.onderwijstaken}
                        onChange={handleChange}
                        name="onderwijstaken"
                      />
                    }
                    label="Onderwijstaken"
                  />
                </FormGroup>
              </FormControl>
              <InformationCard
                type="info"
                content={() => (
                  <>Vink aan wat in de export meegenomen moet worden.</>
                )}
                style={{ marginTop: "6px" }}
              />
            </div>
            <div className="export-dialog-fullscreen-content-container">
              <div className="export-dialog-fullscreen-content-header">
                {data ? (
                  <ExportPDFButton
                    pdf={pdf(
                      <ExportTeacher teacher={data} options={options} />
                    )}
                    filename={`${data.curriculum} - ${data.institute} - ${
                      data.education
                    } - ${
                      data.personnel_code
                    } - ${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`}
                  />
                ) : (
                  <CircularProgress color="primary" size={30} />
                )}
              </div>
              {error ? (
                <div className="export-dialog-fullscreen-error-container">
                  Er is een probleem met het genereren van de PDF.
                </div>
              ) : data ? (
                <PDFViewer className="export-dialog-fullscreen-content">
                  <ExportTeacher teacher={data} options={options} />
                </PDFViewer>
              ) : (
                <div className="export-dialog-fullscreen-loading-container">
                  <CircularProgress color="primary" size={50} />
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}
