import React from "react"
import { TopbarPlanning, Sidebar } from "../../components/Layout"
import { useHistory } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import {
  createDialogState,
  currentCurriculumState,
  searchbarState,
} from "../../state/app"
import { Planning } from "../../components/Planning"

import "./Planningsoverview.css"

export const Planningsoverview = () => {
  const setCurrentCurriculum = useSetRecoilState(currentCurriculumState)
  const setSearchbar = useSetRecoilState(searchbarState)
  const setCreateDialog = useSetRecoilState(createDialogState)

  let history = useHistory()
  history.listen(() => {
    // setCurrentCurriculum({ id: -1, name: "" })
    setCreateDialog(false)
    setSearchbar("")
  })

  return (
    <div id="content-container" className="topbar">
      {/* <Sidebar /> */}
      <TopbarPlanning />
      <Planning />
    </div>
  )
}
