import { Divider, Tab, Tabs } from "@material-ui/core"
import React, { useState } from "react"
import { TabAanstelling } from "./aanstelling"
import { TabOnderwijstaken } from "./onderwijstaken"
import { TabMOT } from "./mot"

export const TeacherTabs = () => {
  const [tabIndex, setTabIndex] = useState<number>(0)

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={tabIndex}
        scrollButtons="auto"
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(e, v) => setTabIndex(v)}
      >
        <Tab label="Aanstelling" />
        <Tab label="Onderwijstaken" />
        <Tab label="MOT" />
        {/* <Tab label="DI / Verlof" />
        <Tab label="Beschikbaarheid / Voorkeuren" /> */}
      </Tabs>
      <Divider />
      <TabPanel value={tabIndex} index={0}>
        <TabAanstelling />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TabOnderwijstaken />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <TabMOT />
      </TabPanel>
      {/* <TabPanel value={tabIndex} index={3}>
        DI / Verlof
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        Beschikbaarheid / Voorkeuren
      </TabPanel> */}
    </>
  )
}

type TabPanelProps = {
  value: number
  index: number
}

const TabPanel = (props: React.PropsWithChildren<TabPanelProps>) => {
  const { value, index, children } = props

  return (
    <>{value === index && <div className="card-content">{children}</div>}</>
  )
}
