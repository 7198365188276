import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSetRecoilState } from "recoil"
import HRLogo from "../../images/hr-logo-plain@4x.png"
import { userState } from "../../state/app"
import "./AuthManager.css"

export const AuthManager = ({ children }: React.PropsWithChildren<{}>) => {
  const setUser = useSetRecoilState(userState)
  const [authStatus, setAuthStatus] = useState<{
    isAuthenticating: boolean
    isLoggedIn: boolean
    authFailed: boolean
    typeFailed: string
    reasonFailed: string
  }>({
    isAuthenticating: true,
    isLoggedIn: false,
    authFailed: false,
    typeFailed: "",
    reasonFailed: "",
  })

  useEffect(() => {
    fetch("/api/login")
      .then(() => {
        fetch("/api/login/get-user-credentials").then(async (r) => {
          if (r.status === 401 || r.status === 404) {
            const json: {
              type: "user-is-student" | "user-not-found"
              message: string
            } = await r.json()
            return setAuthStatus({
              isAuthenticating: false,
              isLoggedIn: true,
              authFailed: true,
              typeFailed: json.type,
              reasonFailed: json.message,
            })
          }
          const json: {
            institute: string
            education: string
            email: string
            personnel_code: string
            role: string
          } = await r.json()
          setUser(json)
          setAuthStatus({
            isAuthenticating: false,
            isLoggedIn: true,
            authFailed: false,
            typeFailed: "",
            reasonFailed: "",
          })
        })
      })
      .catch((e) =>
        setAuthStatus({
          isAuthenticating: false,
          isLoggedIn: false,
          authFailed: true,
          typeFailed: "user-not-logged-in",
          reasonFailed: "User not logged in.",
        })
      )
  }, [setUser])

  if (authStatus.isAuthenticating) {
    return <AuthManagerStatus loading />
  }

  if (!authStatus.isAuthenticating && !authStatus.isLoggedIn) {
    window.location.replace(`/api/login`)
    return <AuthManagerStatus notLoggedIn />
  }

  if (
    !authStatus.isAuthenticating &&
    authStatus.isLoggedIn &&
    !authStatus.authFailed
  ) {
    return (
      <>
        <AuthManagerStatus loggedIn />
        <div id="auth-manager">{children}</div>
      </>
    )
  }

  if (authStatus.authFailed) {
    return (
      <AuthManagerStatus
        error
        errorType={authStatus.typeFailed}
        errorMessage={authStatus.reasonFailed}
      />
    )
  }

  return <AuthManagerStatus unknownError />
}

type AuthManagerStatusProps = {
  loading?: boolean
  notLoggedIn?: boolean
  loggedIn?: boolean
  error?: boolean
  errorType?: string
  errorMessage?: string
  unknownError?: boolean
}

const AuthManagerStatus = (props: AuthManagerStatusProps) => {
  const {
    loading,
    notLoggedIn,
    loggedIn,
    error,
    errorType,
    errorMessage,
    unknownError,
  } = props

  return (
    <div id="auth-manager-status" className={`${loggedIn && "logged-in"}`}>
      <img id="auth-manager-logo" src={HRLogo} alt="HR" />
      {loading && (
        <>
          <h1>Laden</h1>
          <CircularProgress />
        </>
      )}
      {notLoggedIn && (
        <>
          <h1>Je bent niet ingelogd. Je wordt automatische omgeleid.</h1>
          <h2 className="sub">
            Klik op de onderstaande knop als je niet automatische wordt omgeleid
          </h2>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => window.location.replace(`/api/login`)}
          >
            Inloggen
          </Button>
        </>
      )}
      {loggedIn && <h1>Succesvol ingelogd!</h1>}
      {error && (
        <>
          <h1>Er is iets fout gegaan bij het inloggen. Probeer het opnieuw</h1>
          <h6>Type: {errorType}</h6>
          <h6>Message: {errorMessage}</h6>
        </>
      )}
      {unknownError && (
        <>
          <h1>Er is een onbekende fout opgetreden. Probeer het opnieuw</h1>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => window.location.replace(`/api/login`)}
          >
            Inloggen
          </Button>
        </>
      )}
    </div>
  )
}
