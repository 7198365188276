import React, { useEffect, useState } from "react"
import Stepper from "@material-ui/core/Stepper"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  createDialogState,
  currentCurriculumState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import { Dialog } from "../Dialog"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"
import {
  Card,
  CardContent,
  Divider,
  TextField,
  Tooltip,
} from "@material-ui/core"
import { useGetAllCurricula } from "../../api/app"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import Paper from "@material-ui/core/Paper"
import useSWR, { mutate } from "swr"
import { CurriculumDetailsType } from "../CurriculumDetails"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { checkValidationErrors, Errors } from "../../utils"
import { DatePicker } from "@material-ui/pickers/DatePicker"
import { curriculumDetailsValidationObject } from "../CurriculumDetails/ValidationObjects"
import { ValidationError } from "yup"
import { newCurriculumValidationObject } from "./ValidationObjects"
import InformationCard from "../InformationCard"
import { currentCurriculumAndKindState } from "../../state/planning"
import HelpRoundedIcon from "@material-ui/icons/HelpRounded"

const getSteps = () => {
  return ["Keuze kopiëren", "Details wijzigen"]
}

type curriculumToCopyFrom = { id: number; name: string }

type AvailabilityKinds = {
  id: number
  block_duration: number
  block_amount: number
}

export const totalHours: number = 1659

export const CreateCurriculumDialog = () => {
  const [createDialog, setCreateDialog] = useRecoilState(createDialogState)
  const setCurrentCurriculum = useSetRecoilState(currentCurriculumState)
  const setCurrentCurriculumAndKinds = useSetRecoilState(
    currentCurriculumAndKindState
  )
  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [activeStep, setActiveStep] = useState<number>(0)
  const {
    data: dataAllCurricula,
    error: errorAllCurricula,
  } = useGetAllCurricula()
  const [curriculumName, setCurriculumName] = useState<string>("")
  const [
    curriculumToCopyFrom,
    setCurriculumToCopyFrom,
  ] = useState<curriculumToCopyFrom>({ id: -1, name: "" })
  const [availabilityKind, setAvailabilityKind] = useState<AvailabilityKinds>({
    id: -1,
    block_duration: -1,
    block_amount: -1,
  })
  const [left, setLeft] = React.useState<string[]>([
    "Docenten",
    "Cursussen",
    "Klassen",
    "MOT taken",
    "Teams",
  ])
  const [right, setRight] = React.useState<string[]>([])
  const { data: dataCurriculumDetails, error: errorCurriculumDetails } = useSWR(
    curriculumToCopyFrom.id !== -1
      ? `/api/curricula/${curriculumToCopyFrom.id}`
      : null
  )
  const { data: dataAvailabilityKinds, error: errorAvailabilityKinds } = useSWR<
    AvailabilityKinds[]
  >("/api/curricula/availability-kinds")
  const [errors, setErrors] = useState<Errors<CurriculumDetailsType>[]>([])
  const [
    curriculumDetails,
    setCurriculumDetails,
  ] = useState<CurriculumDetailsType>({
    id: -1,
    name: "",
    default_fte: 0,
    default_fte_hours: 0,
    default_di: 0,
    default_organisational: 0,
    default_professional: 0,
    course_formula_w: 0,
    course_formula_ct: 0,
    course_formula_opslag: 0,
    course_formula_n: 0,
    course_formula_cor: 0,
    course_formula_r: 0,
    start_date: new Date(),
    end_date: new Date(),
    mot_tasks: [],
    availability_type: "",
  })

  useEffect(() => {
    if (typeof dataCurriculumDetails !== "undefined") {
      setCurriculumDetails({ ...dataCurriculumDetails, name: curriculumName })
    } else {
      setCurriculumDetails({
        id: -1,
        name: curriculumName,
        default_fte: 0,
        default_fte_hours: 0,
        default_di: 0,
        default_organisational: 0,
        default_professional: 0,
        course_formula_w: 0,
        course_formula_ct: 0,
        course_formula_opslag: 0,
        course_formula_n: 0,
        course_formula_cor: 0,
        course_formula_r: 0,
        start_date: new Date(),
        end_date: new Date(),
        mot_tasks: [],
        availability_type: "",
      })
    }
  }, [dataCurriculumDetails, curriculumName])

  useEffect(() => {
    setCurriculumDetails({ ...curriculumDetails, name: curriculumName })
  }, [curriculumName])

  useEffect(() => {
    if (typeof dataAvailabilityKinds !== "undefined") {
      setAvailabilityKind(dataAvailabilityKinds[0])
    }
  }, [dataAvailabilityKinds])

  useEffect(() => {
    if (createDialog) {
      mutate("/api/app/all-curricula")
    } else {
      setActiveStep(0)
      setCurriculumName("")
      setCurriculumToCopyFrom({ id: -1, name: "" })
      setLeft(["Docenten", "Cursussen", "Klassen", "MOT taken", "Teams"])
      setRight([])
      setCurriculumDetails({
        id: -1,
        name: curriculumName,
        default_fte: 0,
        default_fte_hours: 0,
        default_di: 0,
        default_organisational: 0,
        default_professional: 0,
        course_formula_w: 0,
        course_formula_ct: 0,
        course_formula_opslag: 0,
        course_formula_n: 0,
        course_formula_cor: 0,
        course_formula_r: 0,
        start_date: new Date(),
        end_date: new Date(),
        mot_tasks: [],
        availability_type: "",
      })
    }
  }, [createDialog])

  if (!dataAllCurricula) return <></>

  if (errorAllCurricula) return <ErrorState />

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)
    setCurriculumDetails({
      ...curriculumDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const handleDateChange = (
    d: MaterialUiPickersDate,
    type: "start_date" | "end_date"
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${type}: ${d?.toLocaleDateString()}`)
    setCurriculumDetails({ ...curriculumDetails, [type]: d })
  }

  const createCurriculum = () => {
    const payload = {
      name: curriculumName,
      default_fte: curriculumDetails.default_fte,
      default_fte_hours: curriculumDetails.default_fte_hours,
      default_di: curriculumDetails.default_di,
      default_organisational: curriculumDetails.default_organisational,
      default_professional: curriculumDetails.default_professional,
      course_formula_w: curriculumDetails.course_formula_w,
      course_formula_ct: curriculumDetails.course_formula_ct,
      course_formula_opslag: curriculumDetails.course_formula_opslag,
      course_formula_n: curriculumDetails.course_formula_n,
      course_formula_cor: curriculumDetails.course_formula_cor,
      course_formula_r: curriculumDetails.course_formula_r,
      start_date: curriculumDetails.start_date,
      end_date: curriculumDetails.end_date,
      availability_kind: availabilityKind.id,
      curriculum_to_copy_from_id: curriculumToCopyFrom.id,
      copy_entities: right,
    }

    newCurriculumValidationObject
      .validate(curriculumDetails, { abortEarly: false })
      .then(() => {
        fetch("/api/curricula", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }).then(async (r) => {
          if (r.ok) {
            mutate("/api/curricula")
            setCreateDialog(false)
            snackbarText("Het curriculum is aangemaakt")
            snackbarVisible(true)
            mutate("/api/app/all-curricula").then(
              (data: useGetAllCurricula) => {
                setCurrentCurriculum({
                  id: data[0].id,
                  name: data[0].name,
                  kind: data[0].kinds[0],
                })
                setCurrentCurriculumAndKinds(
                  `${data[0].name}-${data[0].kinds[0]}`
                )
              }
            )
          } else {
            const json = await r.json()
            console.log(json)
            snackbarText(
              "Er was een probleem bij het aanmaken van het curriculum"
            )
            snackbarVisible(true)
          }
        })
      })
      .catch((err: ValidationError) => {
        checkValidationErrors(err, setErrors)
      })
  }

  return (
    <Dialog
      innerDialogProps={{
        fullWidth: true,
        maxWidth: "sm",
      }}
      open={createDialog}
      onClose={() => setCreateDialog(false)}
      title="Maak curriculum aan"
      content={() => (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {getSteps().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <div className="card-content">
                <div className="column-2">
                  <TextField
                    variant="outlined"
                    label="Naam curriculum"
                    value={curriculumName}
                    onChange={(e) => {
                      if (e.target.value.length < 6) {
                        setCurriculumName(e.target.value)
                      }
                    }}
                    helperText={`Bijvoorbeeld: 21/22`}
                  />
                  <Autocomplete
                    noOptionsText={"Geen resultaten"}
                    value={curriculumToCopyFrom}
                    onChange={(e, v) =>
                      v !== null
                        ? setCurriculumToCopyFrom(v)
                        : setCurriculumToCopyFrom({ id: -1, name: "" })
                    }
                    options={dataAllCurricula}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kopiëren uit curriculum"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="card-content">
                <TransferList
                  chosenCurriculum={curriculumToCopyFrom}
                  leftState={{ state: left, setState: setLeft }}
                  rightState={{ state: right, setState: setRight }}
                />
              </div>
            </>
          ) : activeStep === 1 ? (
            <>
              {curriculumToCopyFrom.id === -1 || !!dataCurriculumDetails ? (
                <>
                  <div className="card-content">
                    <h6 className="subkop">Standaard waardes</h6>
                    <InformationCard
                      type="info"
                      content={() => (
                        <>
                          <div id="curricula-formula">
                            <strong className="formula-variable">
                              Totaal aantal uren per schooljaar
                            </strong>{" "}
                            x <strong className="formula-variable">FTE</strong>{" "}
                            x{" "}
                            <strong className="formula-variable">
                              (DI | %)
                            </strong>{" "}
                            = X uren
                          </div>
                        </>
                      )}
                    />
                    <div className="column-2">
                      <InformationCard
                        type="info"
                        disableIcon
                        content={() => (
                          <>
                            <TextField
                              label="FTE"
                              variant="outlined"
                              type="number"
                              name="default_fte"
                              value={curriculumDetails.default_fte}
                              onChange={(e) => handleChangeNumber(e)}
                              error={errors.some(
                                (e) => e.path === "default_fte"
                              )}
                              helperText={
                                errors.find((e) => e.path === "default_fte")
                                  ?.message
                                  ? errors.find((e) => e.path === "default_fte")
                                      ?.message
                                  : "Voor elk OP"
                              }
                            />
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              1 FTE = 1659 uren per schooljaar
                            </span>
                          </>
                        )}
                      />
                      {/* <InformationCard
                        type="info"
                        disableIcon
                        content={() => (
                          <>
                            <TextField
                              label="Duurzaam Inzetbaarheid (DI)"
                              fullWidth
                              variant="outlined"
                              type="number"
                              name="default_di"
                              value={curriculumDetails.default_di}
                              onChange={(e) => handleChangeNumber(e)}
                              error={errors.some(
                                (e) => e.path === "default_di"
                              )}
                              helperText={
                                errors.find((e) => e.path === "default_di")
                                  ?.message
                                  ? errors.find((e) => e.path === "default_di")
                                      ?.message
                                  : "Voor elk OP"
                              }
                            />
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {totalHours} x {curriculumDetails.default_fte} x{" "}
                              {curriculumDetails.default_di} ={" "}
                              {Math.ceil(
                                curriculumDetails.default_di *
                                  totalHours *
                                  curriculumDetails.default_fte
                              )}{" "}
                              uur per OP
                            </span>
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {Math.ceil(
                                curriculumDetails.default_di *
                                  totalHours *
                                  curriculumDetails.default_fte
                              )}{" "}
                              x 4 ={" "}
                              {Math.ceil(
                                curriculumDetails.default_di *
                                  totalHours *
                                  curriculumDetails.default_fte
                              ) * 4}{" "}
                              uur per schooljaar
                            </span>
                          </>
                        )}
                      /> */}
                    </div>
                    <div className="column-2">
                      <InformationCard
                        type="info"
                        style={{ marginTop: "12px" }}
                        disableIcon
                        content={() => (
                          <>
                            <TextField
                              label="Organisatorische taak ALG (%)"
                              fullWidth
                              variant="outlined"
                              type="number"
                              name="default_organisational"
                              value={curriculumDetails.default_organisational}
                              onChange={(e) => handleChangeNumber(e)}
                              error={errors.some(
                                (e) => e.path === "default_organisational"
                              )}
                              helperText={
                                errors.find(
                                  (e) => e.path === "default_organisational"
                                )?.message
                              }
                            />
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {totalHours} x {curriculumDetails.default_fte} x{" "}
                              {curriculumDetails.default_organisational}% ={" "}
                              {Math.round(
                                totalHours *
                                  (curriculumDetails.default_organisational /
                                    100) *
                                  curriculumDetails.default_fte
                              )}{" "}
                              uren per schooljaar
                            </span>
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {Math.round(
                                totalHours *
                                  (curriculumDetails.default_organisational /
                                    100) *
                                  curriculumDetails.default_fte
                              )}{" "}
                              / 4 ={" "}
                              {Math.round(
                                (totalHours *
                                  (curriculumDetails.default_organisational /
                                    100) *
                                  curriculumDetails.default_fte) /
                                  4
                              )}{" "}
                              uren per OP
                            </span>
                          </>
                        )}
                      />
                      <InformationCard
                        type="info"
                        style={{ marginTop: "12px" }}
                        disableIcon
                        content={() => (
                          <>
                            <TextField
                              label="Basisrecht professionalisering (%)"
                              fullWidth
                              variant="outlined"
                              type="number"
                              name="default_professional"
                              value={curriculumDetails.default_professional}
                              onChange={(e) => handleChangeNumber(e)}
                              error={errors.some(
                                (e) => e.path === "default_professional"
                              )}
                              helperText={
                                errors.find(
                                  (e) => e.path === "default_professional"
                                )?.message
                              }
                            />
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {totalHours} x {curriculumDetails.default_fte} x{" "}
                              {curriculumDetails.default_professional}% ={" "}
                              {Math.round(
                                totalHours *
                                  (curriculumDetails.default_professional /
                                    100) *
                                  curriculumDetails.default_fte
                              )}{" "}
                              uren per schooljaar
                            </span>
                            <span
                              style={{ marginTop: "6px", display: "block" }}
                            >
                              {Math.round(
                                totalHours *
                                  (curriculumDetails.default_professional /
                                    100) *
                                  curriculumDetails.default_fte
                              )}{" "}
                              / 4 ={" "}
                              {Math.round(
                                (totalHours *
                                  (curriculumDetails.default_professional /
                                    100) *
                                  curriculumDetails.default_fte) /
                                  4
                              )}{" "}
                              per OP
                            </span>
                          </>
                        )}
                      />
                    </div>
                    <div>
                      <InformationCard
                        type="info"
                        style={{ marginTop: "12px" }}
                        content={() => (
                          <>
                            <span>
                              Uren worden op hele getallen afgerond en waarnodig
                              naar beneden.
                            </span>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="card-content">
                    <div id="curricula-formula">
                      Formule cursus ={" "}
                      <strong className="formula-variable">w</strong> x{" "}
                      <strong className="formula-variable">Ct</strong> x{" "}
                      <strong className="formula-variable">Opslag</strong> +{" "}
                      <strong className="formula-variable">n</strong> x{" "}
                      <strong className="formula-variable">Cor</strong> +{" "}
                      <strong className="formula-variable">R</strong> = y min /
                      60 = z uur
                    </div>
                    <div id="curricula-inputs">
                      <TextField
                        label="w"
                        variant="outlined"
                        type="number"
                        name="course_formula_w"
                        value={curriculumDetails.course_formula_w}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_w"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_w")
                            ? errors.find((e) => e.path === "course_formula_w")
                                ?.message
                            : "Aantal lesmomenten. Bijv.: 4"
                        }
                      />
                      <TextField
                        label="Ct"
                        variant="outlined"
                        type="number"
                        name="course_formula_ct"
                        value={curriculumDetails.course_formula_ct}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_ct"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_ct")
                            ? errors.find((e) => e.path === "course_formula_ct")
                                ?.message
                            : "Contacttijd lesmoment met studenten (min). Bijv.: 150"
                        }
                      />
                      <TextField
                        label="Opslag"
                        variant="outlined"
                        type="number"
                        name="course_formula_opslag"
                        value={curriculumDetails.course_formula_opslag}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_opslag"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_opslag")
                            ? errors.find(
                                (e) => e.path === "course_formula_opslag"
                              )?.message
                            : "Opslagfactor. Bijv.: 1,4 of 1,6"
                        }
                      />
                      <TextField
                        label="n"
                        variant="outlined"
                        type="number"
                        name="course_formula_n"
                        value={curriculumDetails.course_formula_n}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_n"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_n")
                            ? errors.find((e) => e.path === "course_formula_n")
                                ?.message
                            : "Studenten in een klas. Bijv.: 30"
                        }
                      />
                      <TextField
                        label="Cor"
                        variant="outlined"
                        type="number"
                        name="course_formula_cor"
                        value={curriculumDetails.course_formula_cor}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_cor"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_cor")
                            ? errors.find(
                                (e) => e.path === "course_formula_cor"
                              )?.message
                            : "Correctietijd per student (min). Bijv.: 10"
                        }
                      />
                      <TextField
                        label="R"
                        variant="outlined"
                        type="number"
                        name="course_formula_r"
                        value={curriculumDetails.course_formula_r}
                        onChange={(e) => handleChangeNumber(e)}
                        error={errors.some(
                          (e) => e.path === "course_formula_r"
                        )}
                        helperText={
                          errors.find((e) => e.path === "course_formula_r")
                            ? errors.find((e) => e.path === "course_formula_r")
                                ?.message
                            : "Reistijd. Bijv.: 0"
                        }
                      />
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="card-content">
                    <div className="column-2">
                      <DatePicker
                        label="Startdatum"
                        variant="dialog"
                        inputVariant="outlined"
                        name="start_date"
                        value={curriculumDetails.start_date}
                        onChange={(d) => handleDateChange(d, "start_date")}
                        format="dd-MM-yyyy"
                        cancelLabel="Annuleer"
                        okLabel="Selecteer datum"
                      />
                      <DatePicker
                        label="Einddatum"
                        variant="dialog"
                        inputVariant="outlined"
                        name="end_date"
                        value={curriculumDetails.end_date}
                        onChange={(d) => handleDateChange(d, "end_date")}
                        format="dd-MM-yyyy"
                        cancelLabel="Annuleer"
                        okLabel="Selecteer datum"
                      />
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="card-content">
                    {!dataAvailabilityKinds ? (
                      <Loader />
                    ) : errorAvailabilityKinds ? (
                      <ErrorState />
                    ) : (
                      <div className="column-1">
                        <InformationCard
                          type="info"
                          content={() => (
                            <>
                              Met de beschikbaarheid type kan je aangeven
                              hoeveel en hoelang de blokken in een dag zijn.
                            </>
                          )}
                          style={{ marginBottom: "24px" }}
                        />
                        <InformationCard
                          type="warning"
                          content={() => (
                            <>
                              Na het aanmaken van het curriculum kan de
                              beschikbaarheid type niet meer worden gewijzigd.
                            </>
                          )}
                          style={{ marginBottom: "24px" }}
                        />
                        <Autocomplete
                          disableClearable
                          noOptionsText={"Geen resultaten"}
                          value={availabilityKind}
                          onChange={(e, v) =>
                            v !== null
                              ? setAvailabilityKind(v)
                              : setAvailabilityKind({
                                  id: -1,
                                  block_duration: -1,
                                  block_amount: -1,
                                })
                          }
                          options={dataAvailabilityKinds}
                          getOptionLabel={(option) =>
                            `${option.block_duration}min x ${option.block_amount} blokken`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Beschikbaarheid type"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : !dataCurriculumDetails ? (
                <Loader />
              ) : errorCurriculumDetails ? (
                <ErrorState />
              ) : (
                <>err</>
              )}
            </>
          ) : (
            <>You're not supposed to be here 🤔</>
          )}
        </>
      )}
      actions={() => (
        <div className="dialog-actions-stepper">
          <Button
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0}
          >
            Terug
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={
              curriculumName.match(/([\d]{2}\/[\d]{2})/g) ? false : true
            }
            onClick={() =>
              activeStep === getSteps().length - 1
                ? createCurriculum()
                : setActiveStep(activeStep + 1)
            }
          >
            {activeStep === getSteps().length - 1 ? "Maak aan" : "Volgende"}
          </Button>
        </div>
      )}
    />
  )
}

const not = (a: string[], b: string[]) => {
  return a.filter((value) => b.indexOf(value) === -1)
}

const intersection = (a: string[], b: string[]) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

type TransferListProps = {
  chosenCurriculum: curriculumToCopyFrom
  leftState: {
    state: string[]
    setState: React.Dispatch<React.SetStateAction<string[]>>
  }
  rightState: {
    state: string[]
    setState: React.Dispatch<React.SetStateAction<string[]>>
  }
}

const TransferList = (props: TransferListProps) => {
  const { chosenCurriculum, leftState, rightState } = props
  const [checked, setChecked] = React.useState<string[]>([])
  const { state: left, setState: setLeft } = leftState
  const { state: right, setState: setRight } = rightState

  useEffect(() => {
    if (chosenCurriculum.id === -1) {
      setChecked([])
      setLeft(["Docenten", "Cursussen", "Klassen", "MOT taken", "Teams"])
      setRight([])
    }
  }, [chosenCurriculum])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  const customList = (title: string, items: string[]) => (
    <div className="transfer-list-custom-list-container">
      <span className="transfer-list-custom-list-title">{title}</span>
      <Paper
        className="transfer-list-custom-list"
        elevation={0}
        variant="outlined"
      >
        <List dense component="div" role="list">
          {items.map((value: string) => {
            const labelId = `transfer-list-item-${value}-label`

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
                disabled={chosenCurriculum.id === -1}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    </div>
  )

  return (
    <div className="transfer-list-container">
      {customList("Niet kopiëren", left)}
      <div className="transfer-list-buttons">
        <Button
          variant="outlined"
          size="small"
          onClick={handleAllRight}
          disabled={left.length === 0 || chosenCurriculum.id === -1}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0 || chosenCurriculum.id === -1}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0 || chosenCurriculum.id === -1}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleAllLeft}
          disabled={right.length === 0 || chosenCurriculum.id === -1}
          aria-label="move all left"
        >
          ≪
        </Button>
      </div>
      {customList("Wel kopiëren", right)}
    </div>
  )
}
