import { number, object, array, string, date, bool } from "yup"
import { CurriculumDetailsMOT, CurriculumDetailsType } from "."
import { newMOTTaskType } from "./MOT"

export const curriculumDetailsValidationObject = object<
  Omit<CurriculumDetailsType, "mot_tasks">
>({
  id: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  name: string()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_fte: number()
    .min(0, "Het FTE kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  default_fte_hours: number()
    .min(0, "De FTE uren kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  default_di: number()
    .min(0, "Het DI kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  default_organisational: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_professional: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_w: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  course_formula_ct: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  course_formula_opslag: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  course_formula_n: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  course_formula_cor: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  course_formula_r: number()
    .min(0, `>= 0`)
    .typeError("Dit veld is verplicht")
    .required(),
  start_date: date().required(),
  end_date: date().required(),
  availability_type: string().required(),
})

export const curriculumDetailsMOTValidationObject = object<
  CurriculumDetailsMOT
>({
  id: number().required(),
  type: string().required(),
  name: string().required("Dit veld is verplicht"),
  hours: number()
    .typeError("Dit veld is verplicht")
    .min(0, ">= 0")
    .required("Dit veld is verplicht"),
  active_op1: bool(),
  active_op2: bool(),
  active_op3: bool(),
  active_op4: bool(),
})

export const curriculumDetailsMOTArrayValidationObject = array<
  CurriculumDetailsMOT
>().of(curriculumDetailsMOTValidationObject)

export const newMOTTaskValidationObject = object<newMOTTaskType>({
  type: string(),
  name: string().required("Dit veld is verplicht"),
  hours: number()
    .min(0, "Uren kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  active_op1: bool(),
  active_op2: bool(),
  active_op3: bool(),
  active_op4: bool(),
})
