import React, { useEffect, useState } from "react"
import useSWR, { mutate } from "swr"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentCurriculumState,
  selectedCurriculumForDetailsState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import {
  Checkbox,
  Divider,
  IconButton,
  Paper,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core"
import { Dialog } from "../Dialog"
import Button from "@material-ui/core/Button/Button"
import AddIcon from "@material-ui/icons/AddRounded"
import {
  checkValidationErrors,
  DialogError,
  Errors,
  ExceptionResponse,
  ExceptionResponseState,
} from "../../utils"
import { newMOTTaskValidationObject } from "./ValidationObjects"
import { ValidationError } from "yup"
import { MOTTask } from "."
import { Autocomplete } from "@material-ui/lab"

interface HeadCell {
  disablePadding: boolean
  id: keyof MOTTask
  label: string
  numeric: boolean
  minWidth: number
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Taak",
    minWidth: 200,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    minWidth: 150,
  },
  {
    id: "hours",
    numeric: true,
    disablePadding: false,
    label: "Uren",
    minWidth: 75,
  },
  {
    id: "active_op1",
    numeric: false,
    disablePadding: false,
    label: "OP1",
    minWidth: 40,
  },
  {
    id: "active_op2",
    numeric: false,
    disablePadding: false,
    label: "OP2",
    minWidth: 40,
  },
  {
    id: "active_op3",
    numeric: false,
    disablePadding: false,
    label: "OP3",
    minWidth: 40,
  },
  {
    id: "active_op4",
    numeric: false,
    disablePadding: false,
    label: "OP4",
    minWidth: 40,
  },
]

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ minWidth: `${headCell.minWidth}px` }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  setDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
)

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected, setDeleteConfirmationDialog } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          MOT taken
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Verwijder">
          <IconButton
            aria-label="Verwijder"
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

type CurriculumMOTTableProps = {
  errors: { message: string; index: number; path: string }[]
  mot_tasks: MOTTask[]
  setMOTTasks: (mot_tasks: MOTTask[]) => void
}

export type newMOTTaskType = MOTTask

export const CreateTeacherDialogMOTTable = (props: CurriculumMOTTableProps) => {
  const { errors, mot_tasks, setMOTTasks } = props
  const currentCurriculum = useRecoilValue(currentCurriculumState)
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [selected, setSelected] = useState<MOTTask[]>([])
  const [createMOTTaskDialog, setCreateMOTTaskDialog] = useState<boolean>(false)
  const [newMOTTask, setNewMOTTask] = useState<newMOTTaskType>({
    id: -1,
    name: "",
    type: "Organisatorische",
    hours: 0,
    active_op1: false,
    active_op2: false,
    active_op3: false,
    active_op4: false,
  })
  const [newMOTTaskErrors, setnewMOTTaskErrors] = useState<
    Errors<newMOTTaskType>[]
  >([])
  const [
    newMOTTaskCreateErrorMessage,
    setNewMOTTaskCreateErrorMessage,
  ] = useState<ExceptionResponseState>({
    status: -1,
    statusText: "",
    type: "",
    message: "",
  })
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<
    boolean
  >(false)
  const [MOTOptions, setMOTOptions] = useState<MOTTask[]>([])

  const { data: dataMOTOptions, error: errorMOTOptions } = useSWR<MOTTask[]>(
    currentCurriculum.id !== -1
      ? `api/teacher/${currentCurriculum.id}/mot/new-teacher-options`
      : null
  )

  useEffect(() => {
    if (!createMOTTaskDialog) {
      setNewMOTTask({
        id: -1,
        name: "",
        type: "Organisatorische",
        hours: 0,
        active_op1: false,
        active_op2: false,
        active_op3: false,
        active_op4: false,
      })
      setnewMOTTaskErrors([])
    } else {
      if (typeof dataMOTOptions !== "undefined") {
        setMOTOptions(dataMOTOptions)
      }
    }
  }, [createMOTTaskDialog])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = mot_tasks.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, task: MOTTask) => {
    const selectedIndex = selected.indexOf(task)
    let newSelected: MOTTask[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, task)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChange = (task: MOTTask) => {
    if (process.env.NODE_ENV === "development")
      console.log(`[${task.id}] ${task.name}: ${task.hours}`)

    let new_mot_tasks = mot_tasks
    const index = new_mot_tasks.findIndex((t) => t.id === task.id)
    new_mot_tasks[index] = task

    setMOTTasks(new_mot_tasks)
  }

  const resetNewState = () => {
    setNewMOTTask({
      id: -1,
      name: "",
      hours: 0,
      type: "Organisatorische",
      active_op1: false,
      active_op2: false,
      active_op3: false,
      active_op4: false,
    })
  }

  const createMOTTask = () => {
    newMOTTaskValidationObject
      .validate(newMOTTask, { abortEarly: false })
      .then(() => {
        let arr = mot_tasks

        arr.push({
          id: Math.floor(Math.random() * 10000),
          name: newMOTTask.name,
          type: newMOTTask.type,
          hours: newMOTTask.hours,
          active_op1: newMOTTask.active_op1,
          active_op2: newMOTTask.active_op2,
          active_op3: newMOTTask.active_op3,
          active_op4: newMOTTask.active_op4,
        })
        setCreateMOTTaskDialog(false)
        setNewMOTTask({
          id: -1,
          name: "",
          type: "Organisatorische",
          hours: 0,
          active_op1: false,
          active_op2: false,
          active_op3: false,
          active_op4: false,
        })

        setMOTTasks(arr)
      })
      .catch((e: ValidationError) =>
        checkValidationErrors(e, setnewMOTTaskErrors)
      )
  }

  const deleteSelected = () => {
    let arr = mot_tasks.filter((a) => !selected.includes(a))
    setDeleteConfirmationDialog(false)
    setSelected([])

    setMOTTasks(arr)
  }

  const isSelected = (task: MOTTask) => selected.indexOf(task) !== -1

  return (
    <Paper elevation={0} variant="outlined" className="curriculum-mot-table">
      <EnhancedTableToolbar
        numSelected={selected.length}
        setDeleteConfirmationDialog={setDeleteConfirmationDialog}
      />
      <Divider />
      <TableContainer>
        <Table>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={mot_tasks.length}
          />
          <TableBody>
            {mot_tasks.length > 0 ? (
              <>
                <TableRow>
                  <TableCell padding="checkbox">
                    <IconButton onClick={() => setCreateMOTTaskDialog(true)}>
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    colSpan={headCells.length + 1}
                    component="th"
                    scope="row"
                  >
                    Maak een nieuwe MOT taak aan
                  </TableCell>
                </TableRow>
                {mot_tasks.map((task, key) => {
                  const isItemSelected = isSelected(task)

                  return (
                    <TableRow key={key}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, task)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          variant="outlined"
                          size="small"
                          value={task.name}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              name: e.target.value,
                            })
                          }
                          type="text"
                          error={errors.some(
                            (e) => e.index === key && e.path === "name"
                          )}
                          helperText={
                            errors.find(
                              (e) => e.index === key && e.path === "name"
                            )?.message
                          }
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <FormControl variant="outlined">
                          <Select
                            value={task.type}
                            onChange={(e) => {
                              handleChange({
                                ...task,
                                type: e.target.value as string,
                              })
                            }}
                          >
                            <MenuItem value={"Organisatorische"}>
                              Organisatorische
                            </MenuItem>
                            <MenuItem value={"Professionalisering"}>
                              Professionalisering
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell scope="row">
                        <TextField
                          variant="outlined"
                          size="small"
                          value={task.hours}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              hours:
                                e.target.value.length > 0
                                  ? Number(e.target.value)
                                  : ((e.target.value as unknown) as number),
                            })
                          }
                          type="number"
                          error={errors.some(
                            (e) => e.index === key && e.path === "hours"
                          )}
                          helperText={
                            errors.find(
                              (e) => e.index === key && e.path === "hours"
                            )?.message
                          }
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={task.active_op1}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              active_op1: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={task.active_op2}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              active_op2: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={task.active_op3}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              active_op3: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={task.active_op4}
                          onChange={(e) =>
                            handleChange({
                              ...task,
                              active_op4: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell padding="checkbox">
                    <IconButton onClick={() => setCreateMOTTaskDialog(true)}>
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    colSpan={headCells.length + 1}
                    component="th"
                    scope="row"
                  >
                    Maak een nieuwe MOT taak aan
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    Er zijn geen MOT taken. Maak een MOT taak aan.
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`MOT ${selected.length > 1 ? "taken" : "taak"} verwijderen`}
        content={() => (
          <p>
            Weet je zeker dat je de geselecteerde MOT{" "}
            {selected.length > 1 ? "taken" : "taak"} wilt verwijderen?
          </p>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disableElevation
              autoFocus
              onClick={() => deleteSelected()}
              color="primary"
              variant="contained"
            >
              Verwijder MOT {selected.length > 1 ? "taken" : "taak"}
            </Button>
          </>
        )}
      />
      <Dialog
        open={createMOTTaskDialog}
        onClose={() => setCreateMOTTaskDialog(false)}
        title={`Nieuwe MOT taak`}
        content={() => (
          <div className="card-content">
            <div className="column">
              <Autocomplete
                multiple={false}
                options={MOTOptions}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.name}
                onChange={(e, v, r) => {
                  if (v) {
                    setNewMOTTask(v)
                  } else {
                    resetNewState()
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="MOT taken uit het curriculum"
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                )}
                fullWidth
              />
            </div>
            <div className="column">
              <p>Kies een uit het curriculum of maak een nieuwe aan</p>
            </div>
            <div className="column">
              <TextField
                label="Naam"
                variant="outlined"
                type="text"
                name="name"
                value={newMOTTask.name}
                onChange={(e) =>
                  setNewMOTTask({ ...newMOTTask, name: e.target.value })
                }
                error={newMOTTaskErrors.some((e) => e.path === "name")}
                helperText={
                  newMOTTaskErrors.find((e) => e.path === "name")?.message
                }
                fullWidth
              />
            </div>
            <div className="column">
              <FormControl fullWidth variant="outlined">
                <Select
                  value={newMOTTask.type}
                  onChange={(e) => {
                    handleChange({
                      ...newMOTTask,
                      type: e.target.value as string,
                    })
                  }}
                >
                  <MenuItem value={"Organisatorische"}>
                    Organisatorische
                  </MenuItem>
                  <MenuItem value={"Professionalisering"}>
                    Professionalisering
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="column">
              <TextField
                label="Uren"
                variant="outlined"
                type="number"
                name="hours"
                value={newMOTTask.hours}
                onChange={(e) =>
                  setNewMOTTask({
                    ...newMOTTask,
                    hours:
                      e.target.value.length > 0
                        ? Number(e.target.value)
                        : ((e.target.value as unknown) as number),
                  })
                }
                error={newMOTTaskErrors.some((e) => e.path === "hours")}
                helperText={
                  newMOTTaskErrors.find((e) => e.path === "hours")
                    ? newMOTTaskErrors.find((e) => e.path === "hours")?.message
                    : "Uren worden per OP toegewezen"
                }
                fullWidth
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newMOTTask.active_op1}
                      onChange={(e) =>
                        setNewMOTTask({
                          ...newMOTTask,
                          active_op1: e.target.checked,
                        })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP1"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newMOTTask.active_op2}
                      onChange={(e) =>
                        setNewMOTTask({
                          ...newMOTTask,
                          active_op2: e.target.checked,
                        })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP2"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newMOTTask.active_op3}
                      onChange={(e) =>
                        setNewMOTTask({
                          ...newMOTTask,
                          active_op3: e.target.checked,
                        })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP3"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newMOTTask.active_op4}
                      onChange={(e) =>
                        setNewMOTTask({
                          ...newMOTTask,
                          active_op4: e.target.checked,
                        })
                      }
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP4"
                  labelPlacement="top"
                />
              </FormGroup>
            </div>
            {newMOTTaskCreateErrorMessage.message.length > 0 && (
              <DialogError error={newMOTTaskCreateErrorMessage} />
            )}
          </div>
        )}
        actions={() => (
          <>
            <Button onClick={() => setCreateMOTTaskDialog(false)}>
              Annuleer
            </Button>
            <Button
              disableElevation
              autoFocus
              onClick={() => createMOTTask()}
              color="primary"
              variant="contained"
            >
              Maak taak aan
            </Button>
          </>
        )}
      />
    </Paper>
  )
}
