import "./Dialog.css"
import React from "react"
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles"
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export type DialogProps = {
  open: boolean
  onClose: () => void
  title: string
  content: () => JSX.Element
  actions: () => JSX.Element
  innerDialogProps?: Omit<MuiDialogProps, "open">
}

export const Dialog = (props: DialogProps) => {
  const { open, onClose, title, content, actions, innerDialogProps } = props

  return (
    <MuiDialog
      disableBackdropClick
      disableEscapeKeyDown
      {...innerDialogProps}
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{content()}</DialogContent>
      <DialogActions>{actions()}</DialogActions>
    </MuiDialog>
  )
}

export type FullScreenDialogProps = {
  open: boolean
  onClose: () => void
  title: string
  content: () => JSX.Element
  contentPadding?: string
  innerFullScreenDialogProps?: Omit<MuiDialogProps, "open">
}

export const FullScreenDialog = (props: FullScreenDialogProps) => {
  const {
    open,
    onClose,
    title,
    content,
    contentPadding,
    innerFullScreenDialogProps,
  } = props

  return (
    <MuiDialog
      fullScreen
      disableBackdropClick
      disableEscapeKeyDown
      {...innerFullScreenDialogProps}
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          padding: contentPadding ? contentPadding : "0px",
        }}
      >
        {content()}
      </DialogContent>
    </MuiDialog>
  )
}
