import useSWR from "swr"

export type useGetAllCurriculumsAndKinds = {
  id: number
  curriculum: string
  kinds: string[]
}
type useGetAllCurriculumsAndKindsProps = {}

export const useGetAllCurriculumsAndKinds = (
  props?: useGetAllCurriculumsAndKindsProps
) => {
  const { data, error } = useSWR<useGetAllCurriculumsAndKinds[]>(
    "/api/planning/all-curricula"
  )

  return {
    data,
    error,
    isLoading: !error && !data,
  }
}
