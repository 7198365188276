import {
  Divider,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Table as MdTable,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import { array, number, object, ValidationError } from "yup"
import { snackbarTextState, snackbarVisibleState } from "../../../state/app"
import {
  checkArrayValidationErrors,
  checkValidationErrors,
  Errors,
  ErrorsArray,
  useFetchState,
} from "../../../utils"
import { Dialog } from "../../Dialog"
import Table, { HeadCell } from "../../Table"
import { Button } from "../../Buttons"

type Supervision = {
  interns_summary: SupervisionSummary
  graduates_summary: SupervisionSummary
  interns: SupervisionObject[]
  graduates: SupervisionObject[]
}

type SupervisionSummary = {
  total: number
  op1: number
  op2: number
  op3: number
  op4: number
}

type SupervisionObject = {
  id: number
  start_op: number
  end_op: number
}

type Onderwijstaken = {
  period: number
  course_code: string
  course_name: string
  curriculum_kind: string
  student_amount: number
  contact_student: number
  meetings: number
  meeting_duration: number
  raise_factor: number
  correction_time: number
}

export const TabOnderwijstaken = () => {
  let { id }: { id: string } = useParams()
  const [tabSupervision, setTabSupervision] = useState<Supervision>({
    interns_summary: {
      total: -1,
      op1: -1,
      op2: -1,
      op3: -1,
      op4: -1,
    },
    graduates_summary: {
      total: -1,
      op1: -1,
      op2: -1,
      op3: -1,
      op4: -1,
    },
    interns: [],
    graduates: [],
  })
  const [onderwijstaken, setOnderwijstaken] = useState<Onderwijstaken[]>([])
  const [InternSupervisionErrors, setInternSupervisionErrors] = useState<
    ErrorsArray<SupervisionObject>[]
  >([])

  const [
    openExportOnderwijstakenDialog,
    setOpenExportOnderwijstakenDialog,
  ] = useState<boolean>(false)

  const { data: supervisionData, error: supervisionError } = useSWR<
    Supervision
  >(id ? `/api/teacher/${id}/supervision` : null)
  const { data: onderwijstakenData, error: onderwijstakenError } = useSWR<
    Onderwijstaken[]
  >(id ? `/api/teacher/${id}/onderwijstaken` : null)

  useEffect(() => {
    if (typeof supervisionData !== "undefined") {
      setTabSupervision(supervisionData)
    }
    if (typeof onderwijstakenData !== "undefined") {
      setOnderwijstaken(onderwijstakenData)
    }
  }, [supervisionData, onderwijstakenData])

  return (
    <>
      <h6 className="subkop">Begeleiding</h6>
      <SupervisionSummary
        interns={tabSupervision.interns_summary}
        graduates={tabSupervision.graduates_summary}
      />
      <Divider />
      <InternsSupervisionTable
        key={`interns-supervision-table`}
        supervision={tabSupervision.interns}
        setSupervision={(data) =>
          setTabSupervision({ ...tabSupervision, interns: data })
        }
        supervisionErrors={InternSupervisionErrors}
        setSupervisionErrors={setInternSupervisionErrors}
        supervisionKind="Stagiairs"
      />
      <Divider />
      <InternsSupervisionTable
        key={`graduates-supervision-table`}
        supervision={tabSupervision.graduates}
        setSupervision={(data) =>
          setTabSupervision({ ...tabSupervision, graduates: data })
        }
        supervisionErrors={InternSupervisionErrors}
        setSupervisionErrors={setInternSupervisionErrors}
        supervisionKind="Afstudeerders"
      />
      <Divider />
      <div className="onderwijstaken-title-container">
        <h6 className="subkop">Onderwijstaken</h6>
        {/* <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenExportOnderwijstakenDialog(true)}
        >
          Exporteer
        </Button> */}
      </div>
      <Onderwijstaken onderwijstaken={onderwijstaken} />
    </>
  )
}

type SupervisionTableProps = {
  supervision: SupervisionObject[]
  setSupervision: (data: SupervisionObject[]) => void
  supervisionErrors: ErrorsArray<SupervisionObject>[]
  setSupervisionErrors: React.Dispatch<
    React.SetStateAction<ErrorsArray<SupervisionObject>[]>
  >
  supervisionKind: "Afstudeerders" | "Stagiairs"
}

const InternsSupervisionTable = (props: SupervisionTableProps) => {
  let { id }: { id: string } = useParams()
  const {
    supervision,
    setSupervision,
    supervisionErrors,
    setSupervisionErrors,
    supervisionKind,
  } = props
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [
    createNewSupervisionStatus,
    setCreateNewSupervisionStatus,
  ] = useFetchState()
  const [updateSupervisionStatus, setUpdateSupervisionStatus] = useFetchState()
  const [deleteSupervisionStatus, setDeleteSupervisionStatus] = useFetchState()
  const [newSupervision, setNewSupervision] = useState<
    Omit<SupervisionObject, "id">
  >({
    start_op: 1,
    end_op: 2,
  })
  const [newSupervisionErrors, setNewSupervisionErrors] = useState<
    Errors<Omit<SupervisionObject, "id">>[]
  >([])

  const headcells: HeadCell<SupervisionObject>[] = [
    {
      id: "start_op",
      numeric: false,
      disablePadding: false,
      label: "Start OP",
      minWidth: 50,
    },
    {
      id: "end_op",
      numeric: false,
      disablePadding: false,
      label: "End OP",
      minWidth: 50,
    },
  ]

  const resetNewSupervisionState = () => {
    setNewSupervision({
      start_op: 1,
      end_op: 2,
    })
  }

  const createNewSupervision = (onClose: () => void) => {
    newSupervisionValidationObject
      .validate(newSupervision, { abortEarly: false })
      .then(() => {
        setCreateNewSupervisionStatus("loading")
        setNewSupervisionErrors([])
        fetch(`/api/teacher/${id}/supervision`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: supervisionKind,
            ...newSupervision,
          }),
        })
          .then(async (r) => {
            if (r.ok) {
              setCreateNewSupervisionStatus("success")
              mutate(`/api/teacher/${id}/supervision`)
              setSnackbarText("De begeleiding is toegevoegd")
              setSnackbarVisible(true)
              onClose()
            }
          })
          .catch((e) => {
            setCreateNewSupervisionStatus("error")
            console.log(e)
          })
      })
      .catch((err: ValidationError) =>
        checkValidationErrors<Omit<SupervisionObject, "id">>(
          err,
          setNewSupervisionErrors
        )
      )
  }

  const saveChanges = () => {
    supervisionArrayValidationObject
      .validate(supervision, { abortEarly: false })
      .then(() => {
        setUpdateSupervisionStatus("loading")
        setSupervisionErrors([])
        fetch(`/api/teacher/${id}/supervision/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supervision),
        })
          .then(async (r) => {
            setUpdateSupervisionStatus("success")
            mutate(`/api/teacher/${id}/supervision`)
            setSnackbarText("De begeleiding is toegevoegd")
            setSnackbarVisible(true)
          })
          .catch((e) => {
            setUpdateSupervisionStatus("error")
            console.log(e)
            setSnackbarText(`Is is iets fout gegaan.`)
            setSnackbarVisible(true)
          })
      })
      .catch((e: ValidationError) =>
        checkArrayValidationErrors<SupervisionObject>(e, setSupervisionErrors)
      )
  }

  const deleteSelected = (
    selected: SupervisionObject[],
    onClose: () => void,
    setSelected: React.Dispatch<React.SetStateAction<SupervisionObject[]>>
  ) => {
    setDeleteSupervisionStatus("loading")
    fetch(`/api/teacher/${id}/supervision/delete-multiple`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selected),
    })
      .then(async (r) => {
        if (r) {
          setDeleteSupervisionStatus("success")
          mutate(`/api/teacher/${id}/supervision`)
          setSnackbarText("De begeleiding is toegevoegd")
          setSnackbarVisible(true)
          setSelected([])
          onClose()
        } else {
          setDeleteSupervisionStatus("error")
        }
      })
      .catch((e) => {
        setDeleteSupervisionStatus("error")
        console.log(e)
      })
  }

  const getType = (): { plural: string; singular: string } => {
    switch (supervisionKind) {
      case "Afstudeerders":
        return { plural: "afstudeerders", singular: "afstudeerder" }
      case "Stagiairs":
        return { plural: "stagiairs", singular: "stagiaire" }
      default:
        return { plural: "", singular: "" }
    }
  }

  return (
    <Table<SupervisionObject>
      title={supervisionKind}
      headcells={headcells}
      data={supervision}
      resetNewState={() => resetNewSupervisionState()}
      CreateDialog={({ open, onClose }) => (
        <Dialog
          open={open}
          onClose={() => onClose()}
          title={`Nieuwe begeleiding ${getType().singular}`}
          content={() => (
            <div className="card-content">
              <div className="column">
                <TextField
                  variant="outlined"
                  label="Start OP"
                  value={newSupervision.start_op}
                  onChange={(e) =>
                    setNewSupervision({
                      ...newSupervision,
                      start_op:
                        e.target.value.length > 0
                          ? Number(e.target.value)
                          : ((e.target.value as unknown) as number),
                    })
                  }
                  type="number"
                  error={newSupervisionErrors.some(
                    (e) => e.path === "start_op"
                  )}
                  helperText={
                    newSupervisionErrors.find((e) => e.path === "start_op")
                      ?.message
                  }
                  style={{ marginRight: "12px" }}
                />
                <TextField
                  variant="outlined"
                  label="Eind OP"
                  value={newSupervision.end_op}
                  onChange={(e) =>
                    setNewSupervision({
                      ...newSupervision,
                      end_op:
                        e.target.value.length > 0
                          ? Number(e.target.value)
                          : ((e.target.value as unknown) as number),
                    })
                  }
                  type="number"
                  error={newSupervisionErrors.some((e) => e.path === "end_op")}
                  helperText={
                    newSupervisionErrors.find((e) => e.path === "end_op")
                      ?.message
                  }
                />
              </div>
            </div>
          )}
          actions={() => (
            <>
              <Button onClick={() => onClose()}>Annuleer</Button>
              <Button
                fetchStatus={[
                  createNewSupervisionStatus,
                  setCreateNewSupervisionStatus,
                ]}
                onClick={() => createNewSupervision(onClose)}
                color="primary"
                variant="contained"
              >
                Voeg begeleiding toe
              </Button>
            </>
          )}
        />
      )}
      DeleteDialog={({ open, onClose, selected, setSelected }) => (
        <Dialog
          open={open}
          onClose={() => onClose()}
          title={`${
            selected.length > 1
              ? getType().plural.charAt(0).toUpperCase() +
                getType().plural.slice(1)
              : getType().singular.charAt(0).toUpperCase() +
                getType().singular.slice(1)
          } verwijderen`}
          content={() => (
            <p>
              Weet je zeker dat je de geselecteerde{" "}
              {selected.length > 1 ? getType().plural : getType().singular} wilt
              verwijderen?
            </p>
          )}
          actions={() => (
            <>
              <Button onClick={() => onClose()}>Annuleer</Button>
              <Button
                disabled={deleteSupervisionStatus.loading}
                onClick={() => deleteSelected(selected, onClose, setSelected)}
                color="primary"
                variant="contained"
              >
                Verwijder{" "}
                {selected.length > 1 ? getType().plural : getType().singular}
              </Button>
            </>
          )}
        />
      )}
      text={{
        new: `Voeg een ${getType().singular} toe`,
        empty: `De docent begeleid geen ${getType().plural}. Voeg er een toe.`,
      }}
      BottomActions={() => (
        <div>
          <Button
            fetchStatus={[updateSupervisionStatus, setUpdateSupervisionStatus]}
            variant="contained"
            color="primary"
            onClick={() => saveChanges()}
          >
            Sla wijzigingen op
          </Button>
        </div>
      )}
    >
      {({ data, handleChange, key }) => (
        <>
          <TableCell scope="row">
            <TextField
              variant="outlined"
              size="small"
              value={data.start_op}
              onChange={(e) =>
                handleChange({
                  ...data,
                  start_op:
                    e.target.value.length > 0
                      ? Number(e.target.value)
                      : ((e.target.value as unknown) as number),
                }).then((data) => setSupervision(data))
              }
              type="number"
              error={supervisionErrors.some(
                (e) => e.index === key && e.path === "start_op"
              )}
              helperText={
                supervisionErrors.find(
                  (e) => e.index === key && e.path === "start_op"
                )?.message
              }
            />
          </TableCell>
          <TableCell scope="row">
            <TextField
              variant="outlined"
              size="small"
              value={data.end_op}
              onChange={(e) =>
                handleChange({
                  ...data,
                  end_op:
                    e.target.value.length > 0
                      ? Number(e.target.value)
                      : ((e.target.value as unknown) as number),
                }).then((data) => setSupervision(data))
              }
              type="number"
              error={supervisionErrors.some(
                (e) => e.index === key && e.path === "end_op"
              )}
              helperText={
                supervisionErrors.find(
                  (e) => e.index === key && e.path === "end_op"
                )?.message
              }
            />
          </TableCell>
        </>
      )}
    </Table>
  )
}

export const newSupervisionValidationObject = object<
  Omit<SupervisionObject, "id">
>({
  start_op: number()
    .min(0, "> 0")
    .max(4, "< 5")
    .typeError("Dit veld is verplicht")
    .required(),
  end_op: number()
    .min(0, "> 0")
    .max(4, "< 5")
    .typeError("Dit veld is verplicht")
    .required(),
})

export const supervisionValidationObject = object<SupervisionObject>({
  id: number().required(),
  start_op: number()
    .min(0, "> 0")
    .max(4, "< 5")
    .typeError("Dit veld is verplicht")
    .required(),
  end_op: number()
    .min(0, "> 0")
    .max(4, "< 5")
    .typeError("Dit veld is verplicht")
    .required(),
})

export const supervisionArrayValidationObject = array<SupervisionObject>().of(
  supervisionValidationObject
)

type SupervisionSummaryProps = {
  interns: SupervisionSummary
  graduates: SupervisionSummary
}

const SupervisionSummary = (props: SupervisionSummaryProps) => {
  const { interns, graduates } = props

  return (
    <div id="tab-onderwijstaken-supervision-summary-container">
      <MdTable size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">OP1</TableCell>
            <TableCell align="right">OP2</TableCell>
            <TableCell align="right">OP3</TableCell>
            <TableCell align="right">OP4</TableCell>
            <TableCell align="right">Totaal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Stagiairs</TableCell>
            <TableCell align="right">{interns.op1}</TableCell>
            <TableCell align="right">{interns.op2}</TableCell>
            <TableCell align="right">{interns.op3}</TableCell>
            <TableCell align="right">{interns.op4}</TableCell>
            <TableCell align="right">{interns.total}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Afstudeerders</TableCell>
            <TableCell align="right">{graduates.op1}</TableCell>
            <TableCell align="right">{graduates.op2}</TableCell>
            <TableCell align="right">{graduates.op3}</TableCell>
            <TableCell align="right">{graduates.op4}</TableCell>
            <TableCell align="right">{graduates.total}</TableCell>
          </TableRow>
        </TableBody>
      </MdTable>
    </div>
  )
}

type OnderwijsTakenProps = {
  onderwijstaken: Onderwijstaken[]
}

const Onderwijstaken = (props: OnderwijsTakenProps) => {
  const { onderwijstaken } = props

  return (
    <div id="tab-onderwijstaken-taaktoedeling-container">
      <MdTable size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell width={70}>Periode</TableCell>
            <TableCell width={100}>Cursus</TableCell>
            <TableCell width={250}>Naam</TableCell>
            <TableCell width={30}>V/D</TableCell>
            <TableCell width={100}>Aantal studenten</TableCell>
            <TableCell width={100}>Contacttijd incl. opslag (uren)</TableCell>
            <TableCell width={100}>Aantal bijeenkomsten</TableCell>
            <TableCell width={100}>Contact duur (min.)</TableCell>
            <TableCell width={100}>Opslag factor</TableCell>
            <TableCell width={100}>Toets correctietijd (min.)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {onderwijstaken.length > 0 ? (
            <>
              {onderwijstaken.map((o, key) => (
                <TableRow key={key}>
                  <TableCell className="sticky-column">OP{o.period}</TableCell>
                  <TableCell className="sticky-column">
                    {o.course_code}
                  </TableCell>
                  <TableCell>{o.course_name}</TableCell>
                  <TableCell>
                    {o.curriculum_kind === "Voltijd" ? "VT" : "DT"}
                  </TableCell>
                  <TableCell>{o.student_amount}</TableCell>
                  <TableCell>{o.contact_student}</TableCell>
                  <TableCell>{o.meetings}</TableCell>
                  <TableCell>{o.meeting_duration}</TableCell>
                  <TableCell>{o.raise_factor}</TableCell>
                  <TableCell>{o.correction_time}</TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={10}>
                De docent is nergens ingeplanned
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MdTable>
    </div>
  )
}
