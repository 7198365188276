import { atom } from "recoil"

export const userState = atom<{
  institute: string
  education: string
  email: string
  personnel_code: string
  role: string
}>({
  key: "userState",
  default: {
    institute: "",
    education: "",
    email: "",
    personnel_code: "",
    role: "",
  },
})

type currentCurriculumStateType = { id: number; name: string; kind: string }

export const currentCurriculumState = atom<currentCurriculumStateType>({
  key: "currentCurriculumState",
  default: { id: -1, name: "", kind: "" },
})

export const searchbarState = atom<string>({
  key: "searchbarState",
  default: "",
})

export const createDialogState = atom<boolean>({
  key: "createDialogState",
  default: false,
})

export const snackbarTextState = atom({
  key: "snackbarTextState",
  default: "",
})

export const snackbarVisibleState = atom({
  key: "snackbarVisibleState",
  default: false,
})

export const selectedCurriculumForDetailsState = atom<number>({
  key: "selectedCurriculumForDetailsState",
  default: -1,
})

export const selectedCourseForDetailsState = atom<number>({
  key: "selectedCourseForDetailsState",
  default: -1,
})

export const exportPlanningDialogState = atom<{
  year: number
  op: number
  expanded: boolean
}>({
  key: "exportPlanningDialogState",
  default: {
    year: 1,
    op: 1,
    expanded: false,
  },
})
