import React, { useEffect, useState } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import {
  createStyles,
  Divider,
  lighten,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  selectedCourseForDetailsState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import useSWR, { mutate } from "swr"
import { EmptyDetailsState } from "../EmptyDetailsState"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import { ValidationError } from "yup"
import {
  checkValidationErrors,
  Errors,
  ExceptionResponse,
  useFetchState,
} from "../../utils"
import { Dialog } from "../Dialog"
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormControl from "@material-ui/core/FormControl/FormControl"
import InputLabel from "@material-ui/core/InputLabel/InputLabel"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { courseDetailsValidationObject } from "./ValidationObjects"
import { currentCurriculumAndKindObject } from "../../state/planning"
import InformationCard from "../InformationCard"
import { Button } from "../Buttons"

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      borderBottom: "1px solid rgba(0, 0, 0, .12)",
      zIndex: 2,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flexGrow: 1,
    },
    deleteButton: {
      marginRight: "16px",
    },
  })
)

export type CourseDetailsType = {
  code: string
  name: string
  ects: number
  raise_factor: number
  meetings: number
  meeting_duration: number
  teachers_simultaniously_teaching: number
  contact_students: number
  teached_on_day: number
  study_year: number
  op: number
  correction_time: number
  travel_time: number
  workmethod: Workmethod
  course_owner: CourseOwner
  team: Team
  kind: string
}

export type Workmethod = {
  id: number
  name: string
}

export type CourseOwner = {
  id: number
  personnel_code: string
}

export type CourseOwnerOptions = {
  type: string
  id: number
  personnel_code: string
}

export type Team = {
  id: number
  name: string
}

export type TeamOptions = {
  type: string
  id: number
  name: string
}

type CourseDeleteErrors = {
  year: number
  op: number
}

export const CourseDetails = () => {
  const classes = useToolbarStyles()
  const currentCurriculum = useRecoilValue(currentCurriculumAndKindObject)
  const [selectedCourse, setSelectedCourse] = useRecoilState(
    selectedCourseForDetailsState
  )
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [updateCourseStatus, setUpdateCourseStatus] = useFetchState()
  const [deleteCourseStatus, setDeleteCourseStatus] = useFetchState()
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<
    boolean
  >(false)
  const [courseUsedInPlanningError, setCourseUsedInPlanningError] = useState<
    boolean
  >(false)
  const [
    courseUsedInPlanningStudyYearsAndOPs,
    setCourseUsedInPlanningStudyYearAndOPs,
  ] = useState<CourseDeleteErrors[]>([])
  const [errors, setErrors] = useState<Errors<CourseDetailsType>[]>([])
  const [courseDetails, setCourseDetails] = useState<CourseDetailsType>({
    code: "",
    name: "",
    ects: -1,
    raise_factor: -1,
    meetings: -1,
    meeting_duration: -1,
    teachers_simultaniously_teaching: -1,
    contact_students: -1,
    teached_on_day: -1,
    study_year: 1,
    op: -1,
    correction_time: -1,
    travel_time: -1,
    workmethod: { id: -1, name: "" },
    course_owner: { id: -1, personnel_code: "" },
    team: { id: -1, name: "" },
    kind: currentCurriculum.kind,
  })
  const [availableCourseOwners, setAvailableCourseOwners] = useState<
    CourseOwnerOptions[]
  >([])
  const [availableTeams, setAvailableTeams] = useState<TeamOptions[]>([])
  const { data: dataCourseDetails, error: errorCourseDetails } = useSWR<
    CourseDetailsType
  >(() =>
    currentCurriculum && selectedCourse !== -1
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/${selectedCourse}`
      : null
  )
  // const {
  //   data: dataAvailableCourseOwners,
  //   error: errorAvailableCourseOwners,
  // } = useSWR<CourseOwner[]>(() =>
  //   currentCurriculum.id !== -1
  //     ? `/api/course/${currentCurriculum.id}/available-course-owners`
  //     : null
  // )
  const { data: dataAvailableTeams, error: errorAvailableTeams } = useSWR<
    Team[]
  >(() =>
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-teams`
      : null
  )
  const {
    data: dataAvailableWorkmethods,
    error: errorAvailableWorkmethods,
  } = useSWR<Workmethod[]>(() =>
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-workmethods`
      : null
  )

  useEffect(() => {
    if (!deleteConfirmationDialog) {
      setCourseUsedInPlanningError(false)
      setCourseUsedInPlanningStudyYearAndOPs([])
    }
  }, [deleteConfirmationDialog])

  useEffect(() => {
    if (typeof dataCourseDetails !== "undefined") {
      setCourseDetails(dataCourseDetails)
    }
  }, [dataCourseDetails])

  // useEffect(() => {
  //   if (
  //     typeof dataAvailableCourseOwners !== "undefined" &&
  //     typeof dataCourseDetails !== "undefined"
  //   ) {
  //     let arr = dataAvailableCourseOwners as CourseOwnerOptions[]

  //     arr = arr.map((aco) => ({
  //       ...aco,
  //       type: "Beschikbare docenten",
  //     }))

  //     arr.push({
  //       ...dataCourseDetails.course_owner,
  //       type: "Huidige cursusbeheerder",
  //     })

  //     setAvailableCourseOwners(arr)
  //   }
  // }, [dataAvailableCourseOwners, dataCourseDetails])

  useEffect(() => {
    if (
      typeof dataAvailableTeams !== "undefined" &&
      typeof dataCourseDetails !== "undefined"
    ) {
      let arr = dataAvailableTeams as TeamOptions[]

      arr = arr.map((aco) => ({
        ...aco,
        type: "Beschikbare teams",
      }))

      arr.push({ ...dataCourseDetails.team, type: "Huidige team" })

      setAvailableTeams(arr)
    }
  }, [dataAvailableTeams, dataCourseDetails])

  useEffect(() => {
    if (selectedCourse) {
      setUpdateCourseStatus("initial")
      setDeleteCourseStatus("initial")
    }
  }, [selectedCourse])

  if (selectedCourse === -1) return <EmptyDetailsState kind="course" />

  if (!dataCourseDetails) return <Loader />

  if (errorCourseDetails) return <ErrorState />

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setCourseDetails({
      ...courseDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const updateCourse = () => {
    courseDetailsValidationObject
      .validate(courseDetails, { abortEarly: false })
      .then(() => {
        setUpdateCourseStatus("loading")
        setErrors([])
        fetch(`/api/course/update/${selectedCourse}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(courseDetails),
        }).then(async (r) => {
          if (r.ok) {
            setUpdateCourseStatus("success")
            setSnackbarText(`De wijzigingen zijn opgeslagen`)
            setSnackbarVisible(true)
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/${selectedCourse}`
            )
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}`
            )
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-course-owners`
            )
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-teams`
            )
            mutate(
              `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}/available-workmethods`
            )
          } else {
            setUpdateCourseStatus("error")
            const json: ExceptionResponse = await r.json()
            console.log(json)
            setSnackbarText(
              `Er is iets fout gegaan bij het opslaan van de wijzigingen`
            )
            setSnackbarVisible(true)
          }
        })
      })
      .catch((e: ValidationError) => checkValidationErrors(e, setErrors))
  }

  const deleteCourse = () => {
    setDeleteCourseStatus("error")
    setCourseUsedInPlanningError(false)
    fetch(`/api/course/delete/${selectedCourse}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (r) => {
      if (r.ok) {
        setDeleteCourseStatus("success")
        setDeleteConfirmationDialog(false)
        setSnackbarText(`De cursus is verwijderd`)
        setSnackbarVisible(true)
        setSelectedCourse(-1)
        mutate(
          `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}`
        )
      } else {
        setDeleteCourseStatus("error")
        if (r.status === 400) {
          const json: CourseDeleteErrors[] = await r.json()
          console.log(json)

          setCourseUsedInPlanningStudyYearAndOPs(json)
          setCourseUsedInPlanningError(true)
        } else {
          const json: ExceptionResponse = await r.json()
          console.log(json)
          setSnackbarText(
            `Er is iets fout gegaan bij het verwijderen van de cursus`
          )
          setSnackbarVisible(true)
        }
      }
    })
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {courseDetails.code}
        </Typography>
        <Button
          disabled={deleteCourseStatus.loading}
          variant="outlined"
          color="primary"
          onClick={() => setDeleteConfirmationDialog(true)}
          className={classes.deleteButton}
        >
          Verwijder cursus
        </Button>
        <Button
          fetchStatus={[updateCourseStatus, setUpdateCourseStatus]}
          variant="contained"
          color="primary"
          onClick={() => updateCourse()}
        >
          Sla wijzigingen op
        </Button>
      </Toolbar>
      <div className="card-content">
        <div className="column-2">
          <TextField
            label="Cursuscode"
            variant="outlined"
            type="text"
            name="code"
            value={courseDetails.code}
            onChange={(e) => handleChange(e)}
            error={errors.some((e) => e.path === "code")}
            helperText={errors.find((e) => e.path === "code")?.message}
          />
          <TextField
            label="Naam"
            variant="outlined"
            type="text"
            name="name"
            value={courseDetails.name}
            onChange={(e) => handleChange(e)}
            error={errors.some((e) => e.path === "name")}
            helperText={errors.find((e) => e.path === "name")?.message}
          />
          {/* <TextField
            label="Studiepunten"
            variant="outlined"
            type="number"
            name="ects"
            value={courseDetails.ects}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "ects")}
            helperText={errors.find((e) => e.path === "ects")?.message}
          /> */}
          {!dataAvailableWorkmethods ? (
            <Loader />
          ) : errorAvailableWorkmethods ? (
            <ErrorState />
          ) : (
            <Autocomplete
              value={{ ...courseDetails.workmethod }}
              onChange={(e, v) =>
                setCourseDetails({ ...courseDetails, workmethod: v })
              }
              disableClearable
              options={dataAvailableWorkmethods}
              getOptionLabel={(option) => option.name}
              noOptionsText={"Geen werkmethode beschikbaar"}
              renderInput={(params) => (
                <TextField {...params} label="Werkmethode" variant="outlined" />
              )}
            />
          )}
          {/* {!dataAvailableCourseOwners ? (
            <Loader />
          ) : errorAvailableCourseOwners ? (
            <ErrorState />
          ) : (
            <Autocomplete
              value={{
                ...courseDetails.course_owner,
                type: "Beschikbare docenten",
              }}
              onChange={(e, v) =>
                v !== null
                  ? setCourseDetails({ ...courseDetails, course_owner: v })
                  : setCourseDetails({
                      ...courseDetails,
                      course_owner: { id: -1, personnel_code: "" },
                    })
              }
              onBlur={() =>
                courseDetails.course_owner.id === -1 &&
                setCourseDetails({
                  ...courseDetails,
                  course_owner: {
                    id: -1,
                    personnel_code: "Geen cursusbeheerder",
                  },
                })
              }
              options={availableCourseOwners.sort((a, b) =>
                a.type < b.type ? 1 : -1
              )}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.personnel_code}
              noOptionsText={"Geen docenten beschikbaar"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cursusbeheerder"
                  variant="outlined"
                />
              )}
            />
          )} */}
          {!dataAvailableTeams ? (
            <Loader />
          ) : errorAvailableTeams ? (
            <ErrorState />
          ) : (
            <Autocomplete
              value={{ ...courseDetails.team, type: "Beschikbare teams" }}
              onChange={(e, v) =>
                v !== null
                  ? setCourseDetails({ ...courseDetails, team: v })
                  : setCourseDetails({
                      ...courseDetails,
                      team: { id: -1, name: "" },
                    })
              }
              onBlur={() =>
                courseDetails.team.id === -1 &&
                setCourseDetails({
                  ...courseDetails,
                  team: { id: -1, name: "Geen team" },
                })
              }
              options={availableTeams.sort((a, b) =>
                a.type < b.type ? 1 : -1
              )}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.name}
              noOptionsText={"Geen teams beschikbaar"}
              renderInput={(params) => (
                <TextField {...params} label="Team" variant="outlined" />
              )}
            />
          )}
          {/* <FormControl variant="outlined">
            <InputLabel id="course-details-teached-on-day-label">
              Wordt gegeven op dag
            </InputLabel>
            <Select
              label="Wordt gegeven op dag"
              labelId="course-details-teached-on-day-label"
              id="course-details-teached-on-day-select"
              value={courseDetails.teached_on_day}
              onChange={(e) =>
                setCourseDetails({
                  ...courseDetails,
                  teached_on_day: e.target.value as number,
                })
              }
            >
              <MenuItem value={0}>Maandag</MenuItem>
              <MenuItem value={1}>Dinsdag</MenuItem>
              <MenuItem value={2}>Woensdag</MenuItem>
              <MenuItem value={3}>Donderdag</MenuItem>
              <MenuItem value={4}>Vrijdag</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl variant="outlined">
            <InputLabel id="course-details-study-year-label">
              Studiejaar
            </InputLabel>
            <Select
              variant="outlined"
              label="Studiejaar"
              labelId="course-details-study-year-label"
              id="course-details-study-year-select"
              value={courseDetails.study_year}
              onChange={(e) =>
                setCourseDetails({
                  ...courseDetails,
                  study_year: e.target.value as number,
                })
              }
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="course-details-op-label">Periode</InputLabel>
            <Select
              label="Periode"
              labelId="course-details-op-label"
              id="course-details-op-select"
              value={courseDetails.op}
              onChange={(e) =>
                setCourseDetails({
                  ...courseDetails,
                  op: e.target.value as number,
                })
              }
            >
              <MenuItem value={1}>OP1</MenuItem>
              <MenuItem value={2}>OP2</MenuItem>
              <MenuItem value={3}>OP3</MenuItem>
              <MenuItem value={4}>OP4</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider variant="middle" />
      <div className="card-content">
        <div className="column-2">
          <TextField
            label="w"
            variant="outlined"
            type="number"
            name="meetings"
            value={courseDetails.meetings}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "meetings")}
            helperText={
              errors.find((e) => e.path === "meetings")
                ? errors.find((e) => e.path === "meetings")?.message
                : "Aantal lesmomenten"
            }
          />
          <TextField
            label="Ct"
            variant="outlined"
            type="number"
            name="contact_students"
            value={courseDetails.contact_students}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "contact_students")}
            helperText={
              errors.find((e) => e.path === "contact_students")
                ? errors.find((e) => e.path === "contact_students")?.message
                : "Contacttijd lesmoment met studenten (min)"
            }
          />
          {/* <TextField
            label="Aantal docenten dat samen les geeft"
            variant="outlined"
            type="number"
            name="teachers_simultaniously_teaching"
            value={courseDetails.teachers_simultaniously_teaching}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some(
              (e) => e.path === "teachers_simultaniously_teaching"
            )}
            helperText={
              errors.find((e) => e.path === "teachers_simultaniously_teaching")
                ?.message
            }
          /> */}
          <TextField
            label="Opslag"
            variant="outlined"
            type="number"
            name="raise_factor"
            value={courseDetails.raise_factor}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "raise_factor")}
            helperText={
              errors.find((e) => e.path === "raise_factor")
                ? errors.find((e) => e.path === "raise_factor")?.message
                : "Opslagfactor"
            }
          />
          <TextField
            label="Cor"
            variant="outlined"
            type="number"
            name="correction_time"
            value={courseDetails.correction_time}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "correction_time")}
            helperText={
              errors.find((e) => e.path === "correction_time")
                ? errors.find((e) => e.path === "correction_time")?.message
                : "Correctietijd per student (min)"
            }
          />
          <TextField
            label="R"
            variant="outlined"
            type="number"
            name="travel_time"
            value={courseDetails.travel_time}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "travel_time")}
            helperText={
              errors.find((e) => e.path === "travel_time")
                ? errors.find((e) => e.path === "travel_time")?.message
                : "Reistijd"
            }
          />
        </div>
      </div>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`Cursus verwijderen`}
        content={() => (
          <>
            <p>Weet je zeker dat je de cursus wilt verwijderen?</p>
            {courseUsedInPlanningError && (
              <InformationCard
                type="error"
                content={() => (
                  <>
                    De cursus is ingeplanned. Verwijder de cursus uit de
                    planning om het te verwijderen.
                    <br />
                    <br />
                    Komt voor in:
                    <br />
                    {courseUsedInPlanningStudyYearsAndOPs.map((e, key) => (
                      <span key={key}>
                        Studiejaar {e.year} - OP{e.op}
                      </span>
                    ))}
                  </>
                )}
              />
            )}
          </>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteCourseStatus.loading}
              onClick={() => deleteCourse()}
              color="primary"
              variant="contained"
            >
              Verwijder cursus
            </Button>
          </>
        )}
      />
    </>
  )
}
