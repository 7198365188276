import React, { useEffect, useState } from "react"
import {
  Table as MdTable,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  createStyles,
  lighten,
  makeStyles,
  Theme,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
  Divider,
  TableBody,
  TableContainer,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import AddIcon from "@material-ui/icons/AddRounded"
import { DialogProps } from "../Dialog"

export type HeadCell<T> = {
  disablePadding: boolean
  id: keyof T
  label: string
  numeric: boolean
  minWidth: number
}

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

interface EnhancedTableToolbarProps {
  numSelected: number
  setDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
)

type TableDialogProps<T> = {
  open: DialogProps["open"]
  onClose: DialogProps["onClose"]
  selected: T[]
  setSelected: React.Dispatch<React.SetStateAction<T[]>>
}

type TableChildren<T> = {
  data: T
  handleClick: (event: React.MouseEvent<unknown>, task: T) => void
  handleChange: (d: T) => Promise<T[]>
  key: number
}

type TableProps<T> = {
  title: string
  headcells: HeadCell<T>[]
  data: T[]
  children: (props: TableChildren<T>) => JSX.Element
  setData?: (data: T[]) => void
  resetNewState: () => void
  CreateDialog: (props: TableDialogProps<T>) => JSX.Element
  DeleteDialog: (props: TableDialogProps<T>) => JSX.Element
  text?: {
    new: string
    empty: string
  }
  BottomActions?: () => JSX.Element
}

function Table<T extends { id: number }>(props: TableProps<T>) {
  const {
    title,
    headcells,
    data,
    children,
    setData,
    resetNewState,
    CreateDialog,
    DeleteDialog,
    text,
    BottomActions,
  } = props
  const [selected, setSelected] = useState<T[]>([])

  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!createDialogOpen) {
      resetNewState()
    }
  }, [createDialogOpen])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, task: T) => {
    const selectedIndex = selected.indexOf(task)
    let newSelected: T[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, task)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChange = (d: T): Promise<T[]> => {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === "development")
        console.log(`[${d.id}] changed`)

      let new_data = [...data]
      const index = new_data.findIndex((t) => t.id === d.id)
      new_data[index] = d

      resolve(new_data)
    })
  }

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headcells.map((headcell) => (
            <TableCell
              key={`${headcell.id}-${headcell.label}`}
              padding={headcell.disablePadding ? "none" : "default"}
              style={{ minWidth: `${headcell.minWidth}px` }}
            >
              {headcell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles()
    const { numSelected, setDeleteConfirmationDialog } = props

    return (
      <Toolbar>
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} geselecteerd
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="Verwijder">
            <IconButton
              aria-label="Verwijder"
              onClick={() => setDeleteConfirmationDialog(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    )
  }

  const isSelected = (d: T) => selected.indexOf(d) !== -1

  return (
    <>
      <Paper elevation={0} variant="outlined" className="detachering-table">
        <EnhancedTableToolbar
          numSelected={selected.length}
          setDeleteConfirmationDialog={setDeleteDialogOpen}
        />
        <Divider />
        <TableContainer>
          <MdTable>
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {data.length > 0 ? (
                <>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <IconButton onClick={() => setCreateDialogOpen(true)}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      colSpan={headcells.length + 1}
                      component="th"
                      scope="row"
                    >
                      {text?.new ? text.new : "Maak er een aan."}
                    </TableCell>
                  </TableRow>
                  {data.map((d, key) => {
                    const isItemSelected = isSelected(d)

                    return (
                      <TableRow key={`${key}-${d.id}`}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, d)}
                          />
                        </TableCell>
                        {children({ data: d, handleClick, handleChange, key })}
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <IconButton onClick={() => setCreateDialogOpen(true)}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      colSpan={headcells.length + 1}
                      component="th"
                      scope="row"
                    >
                      {text?.new ? text.new : "Maak er een aan."}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={headcells.length + 1}>
                      {text?.empty
                        ? text.empty
                        : "Er bestaat nog niks. Maak er een aan."}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </MdTable>
        </TableContainer>
        {DeleteDialog({
          open: deleteDialogOpen,
          onClose: () => setDeleteDialogOpen(false),
          selected: selected,
          setSelected: setSelected,
        })}
        {CreateDialog({
          open: createDialogOpen,
          onClose: () => setCreateDialogOpen(false),
          selected: selected,
          setSelected: setSelected,
        })}
      </Paper>
      {BottomActions && <BottomActions />}
    </>
  )
}

export default Table
