import React, { useEffect, useState } from "react"
import useSWR, { mutate } from "swr"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentCurriculumState,
  searchbarState,
  selectedCourseForDetailsState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import {
  Checkbox,
  Divider,
  IconButton,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import { Dialog } from "../Dialog"
import { Button } from "../Buttons"
import { ExceptionResponse, useFetchState } from "../../utils"
import { currentCurriculumAndKindObject } from "../../state/planning"
import InformationCard from "../InformationCard"

type CourseType = {
  id: number
  code: string
  workmethod: string
  op: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof CourseType
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  { id: "code", numeric: false, disablePadding: false, label: "Cursuscode" },
  { id: "op", numeric: false, disablePadding: false, label: "OP" },
  {
    id: "workmethod",
    numeric: false,
    disablePadding: false,
    label: "Werkmethode",
  },
]

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  setDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
)

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected, setDeleteConfirmationDialog } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Cursussen
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Verwijder">
          <IconButton
            aria-label="Verwijder"
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

type CourseDeleteErrors = {
  code: string
  year_and_op: {
    year: number
    op: number
  }[]
}

export const CourseTable = () => {
  const setSelectedCourseForDetails = useSetRecoilState(
    selectedCourseForDetailsState
  )
  const searchQuery = useRecoilValue(searchbarState)
  const currentCurriculum = useRecoilValue(currentCurriculumAndKindObject)
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [deleteCourseStatus, setDeleteCourseStatus] = useFetchState()
  const { data, error } = useSWR<CourseType[]>(
    currentCurriculum
      ? `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}`
      : null
  )
  const [selected, setSelected] = React.useState<CourseType[]>([])
  const [courseUsedInPlanningError, setCourseUsedInPlanningError] = useState<
    boolean
  >(false)
  const [
    courseUsedInPlanningStudyYearsAndOPs,
    setCourseUsedInPlanningStudyYearAndOPs,
  ] = useState<CourseDeleteErrors[]>([])
  const [
    deleteConfirmationDialog,
    setDeleteConfirmationDialog,
  ] = React.useState<boolean>(false)

  useEffect(() => {
    if (!deleteConfirmationDialog) {
      setCourseUsedInPlanningError(false)
      setCourseUsedInPlanningStudyYearAndOPs([])
    }
  }, [deleteConfirmationDialog])

  if (!data) return <Loader />

  if (error) return <ErrorState />

  const dataFiltered = data.filter(
    (c) =>
      c.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
      c.workmethod.toLowerCase().includes(searchQuery.toLowerCase()) ||
      c.op.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = dataFiltered.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    curriculum: CourseType
  ) => {
    const selectedIndex = selected.indexOf(curriculum)
    let newSelected: CourseType[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, curriculum)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const deleteSelected = () => {
    setDeleteCourseStatus("loading")
    fetch("/api/course/delete-multiple", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selected),
    }).then(async (r) => {
      if (r.ok) {
        setDeleteCourseStatus("success")
        setDeleteConfirmationDialog(false)
        setSnackbarText(
          `De geselecteerde ${
            selected.length > 1 ? "cursussen zijn" : "cursus is"
          } verwijderd`
        )
        setSnackbarVisible(true)
        setSelected([])
        setSelectedCourseForDetails(-1)
        mutate(
          `/api/course/${currentCurriculum.curriculum}/${currentCurriculum.kind}`
        )
      } else {
        setDeleteCourseStatus("error")
        if (r.status === 400) {
          const json: CourseDeleteErrors[] = await r.json()
          console.log(json)

          setCourseUsedInPlanningStudyYearAndOPs(json)
          setCourseUsedInPlanningError(true)
        } else {
          const json: ExceptionResponse = await r.json()
          console.log(json)
          setSnackbarText(
            `Is is iets fout gegaan bij het verwijderen van de ${
              selected.length > 1 ? "cursussen" : "cursus"
            }`
          )
          setSnackbarVisible(true)
        }
      }
    })
  }

  const isSelected = (curriculum: CourseType) =>
    selected.indexOf(curriculum) !== -1

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        setDeleteConfirmationDialog={setDeleteConfirmationDialog}
      />
      <Divider />
      <TableContainer className="table-container">
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={dataFiltered.length}
          />
          <TableBody>
            {data.length > 0 ? (
              dataFiltered.length > 0 ? (
                <>
                  {dataFiltered.map((curriculum, key) => {
                    const isItemSelected = isSelected(curriculum)

                    return (
                      <TableRow key={key} hover className="curricula-table-row">
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, curriculum)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            setSelectedCourseForDetails(curriculum.id)
                          }
                        >
                          {curriculum.code}
                        </TableCell>
                        <TableCell
                          scope="row"
                          onClick={() =>
                            setSelectedCourseForDetails(curriculum.id)
                          }
                        >
                          {curriculum.op}
                        </TableCell>
                        <TableCell
                          scope="row"
                          onClick={() =>
                            setSelectedCourseForDetails(curriculum.id)
                          }
                        >
                          {curriculum.workmethod}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    Geen resultaten
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>
                  Er zijn geen cursussen. Maak een cursus aan.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`${selected.length > 1 ? "Cursussen" : "Cursus"} verwijderen`}
        content={() => (
          <>
            <p>
              Weet je zeker dat je de geselecteerde{" "}
              {selected.length > 1 ? "cursussen" : "cursus"} wilt verwijderen?
            </p>
            {courseUsedInPlanningError && (
              <InformationCard
                type="error"
                content={() => (
                  <>
                    De {selected.length > 1 ? "cursussen zijn" : "cursus is"}{" "}
                    ingeplanned. Verwijder de{" "}
                    {selected.length > 1 ? "cursussen" : "cursus"} uit de
                    planning om het te verwijderen.
                    <br />
                    <br />
                    {courseUsedInPlanningStudyYearsAndOPs.map((c, key) => (
                      <React.Fragment key={key}>
                        <span>{c.code}</span>
                        <ul>
                          {c.year_and_op.map((yao, key) => (
                            <li key={key}>
                              Studiejaar {yao.year} - OP{yao.op}
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    ))}
                  </>
                )}
              />
            )}
          </>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteCourseStatus.loading}
              onClick={() => deleteSelected()}
              color="primary"
              variant="contained"
            >
              Verwijder {selected.length > 1 ? "cursussen" : "cursus"}
            </Button>
          </>
        )}
      />
    </>
  )
}
