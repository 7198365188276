import React, { useEffect, useState } from "react"
import {
  Button as MdButton,
  ButtonProps as MdButtonProps,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"
import { FetchStateType, useFetchState } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },
    wrapperFullWidth: {
      width: "-webkit-fill-available",
    },
    buttonFullWidth: {
      width: "inherit",
    },
    buttonSuccessContained: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    buttonErrorContained: {
      backgroundColor: red[500],
      "&:hover": {
        backgroundColor: red[700],
      },
    },
    buttonSuccessOutlined: {
      color: green[500],
      borderColor: green[500],
      "&:hover": {
        borderColor: green[700],
      },
    },
    buttonErrorOutlined: {
      color: red[500],
      borderColor: red[500],
      "&:hover": {
        borderColor: red[700],
      },
    },
    buttonSuccessText: {
      color: green[500],
      "&:hover": {
        backgroundColor: green[100],
      },
    },
    buttonErrorText: {
      color: red[500],
      "&:hover": {
        backgroundColor: red[100],
      },
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
)

export type ButtonProps = {
  onClick?: MdButtonProps["onClick"]
  className?: MdButtonProps["className"]
  variant?: MdButtonProps["variant"]
  color?: MdButtonProps["color"]
  size?: MdButtonProps["size"]
  fetchStatus?: ReturnType<typeof useFetchState>
  disabled?: MdButtonProps["disabled"]
  fullWidth?: MdButtonProps["fullWidth"]
  type?: MdButtonProps["type"]
  innerProps?: MdButtonProps
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    onClick,
    variant,
    color,
    size,
    fetchStatus,
    disabled,
    fullWidth,
    type,
    innerProps,
  } = props
  const classes = useStyles()

  const [success, setSuccess] = useState<boolean>(
    fetchStatus?.[0].success || false
  )
  const [error, setError] = useState<boolean>(fetchStatus?.[0].error || false)
  const timer = React.useRef<number>()

  useEffect(() => {
    if (fetchStatus?.[0].loading) {
      timer.current = window.setTimeout(() => {
        setSuccess(false)
        setError(false)
        fetchStatus[1]("initial")
      }, 0)
    }
  }, [fetchStatus?.[0].loading])

  useEffect(() => {
    if (fetchStatus?.[0].success) {
      setSuccess(true)
      timer.current = window.setTimeout(() => {
        setSuccess(false)
        fetchStatus[1]("initial")
      }, 3000)
    }

    if (fetchStatus?.[0].error) {
      setError(true)
      timer.current = window.setTimeout(() => {
        setError(false)
        fetchStatus[1]("initial")
      }, 3000)
    }

    return function cleanup() {
      setSuccess(false)
      setError(false)
      clearTimeout(timer.current)
    }
  }, [fetchStatus?.[0].success, fetchStatus?.[0].error])

  const getSuccessOrErrorClass = () => {
    switch (variant) {
      case "contained":
        return `${success ? classes.buttonSuccessContained : ""} ${
          error ? classes.buttonErrorContained : ""
        }`
      case "outlined":
        return `${success ? classes.buttonSuccessOutlined : ""} ${
          error ? classes.buttonErrorOutlined : ""
        }`
      case "text":
        return `${success ? classes.buttonSuccessText : ""} ${
          error ? classes.buttonErrorText : ""
        }`
      default:
        return `${success ? classes.buttonSuccessContained : ""} ${
          error ? classes.buttonErrorContained : ""
        }`
    }
  }

  return (
    <div
      className={`${className} ${classes.wrapper} ${
        fullWidth ? classes.wrapperFullWidth : ""
      }`}
    >
      <MdButton
        className={`${className} ${
          fullWidth ? classes.buttonFullWidth : ""
        } ${getSuccessOrErrorClass()}`}
        onClick={onClick}
        variant={variant || "contained"}
        color={color || "primary"}
        size={size || "medium"}
        disabled={disabled || fetchStatus?.[0].loading}
        fullWidth={fullWidth}
        disableElevation
        type={type}
        {...innerProps}
      >
        {children}
      </MdButton>
      {fetchStatus?.[0].loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}
