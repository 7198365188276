import { date, number, object, string } from "yup"
import { CurriculumDetailsType } from "../CurriculumDetails"

export const newCurriculumValidationObject = object<
  Omit<CurriculumDetailsType, "mot_tasks" | "availability_type">
>({
  id: number().required(),
  name: string().required("Dit veld is verplicht"),
  default_fte: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_fte_hours: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_di: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_organisational: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  default_professional: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_w: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_ct: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_opslag: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_n: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_cor: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  course_formula_r: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  start_date: date().required(),
  end_date: date().required(),
})
