import { number, object, string } from "yup"
import { CourseDetailsType } from "."

export const courseDetailsValidationObject = object<
  Omit<
    CourseDetailsType,
    "workmethod" | "course_owner" | "team" | "teached_on_day"
  >
>({
  code: string().typeError("Dit veld is verplicht").required(),
  name: string().typeError("Dit veld is verplicht").required(),
  ects: number()
    .min(0, "Studiepunten kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  raise_factor: number()
    .min(0, "De opslagfactor kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  meetings: number()
    .min(0, "Bijeenkomsten kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  meeting_duration: number()
    .min(0, `Contactduur bijeenkomsten kan geen negatief getal zijn`)
    .typeError("Dit veld is verplicht")
    .required(),
  teachers_simultaniously_teaching: number()
    .min(0, `Dit kan geen negatief getal zijn`)
    .typeError("Dit veld is verplicht")
    .required(),
  contact_students: number()
    .min(0, `Contacttijd studenten kan geen negatief getal zijn`)
    .typeError("Dit veld is verplicht")
    .required(),
  study_year: number().typeError("Dit veld is verplicht").required(),
  op: number().typeError("Dit veld is verplicht").required(),
  correction_time: number().typeError("Dit veld is verplicht").required(),
  travel_time: number().typeError("Dit veld is verplicht").required(),
  kind: string().required(),
})
