import React from "react"
import { Topbar } from "../../components/Layout"
import { useHistory } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { createDialogState, searchbarState } from "../../state/app"
import { TeacherTable } from "../../components/TeacherTable"
import { CreateTeacherDialog } from "../../components/CreateTeacherDialog"

export const Teachers = () => {
  const setSearchbar = useSetRecoilState(searchbarState)
  const setCreateDialog = useSetRecoilState(createDialogState)

  let history = useHistory()
  history.listen(() => {
    setCreateDialog(false)
    setSearchbar("")
  })

  return (
    <div id="content-container" className="topbar">
      <Topbar currentCurriculum searchbar createDialog="Maak docent aan" />
      <div id="content" className="content-divided-1">
        <div id="teacher-overview" className="card-container">
          <TeacherTable />
        </div>
      </div>
      <CreateTeacherDialog />
    </div>
  )
}
