import React from "react"
import { NavLink } from "react-router-dom"

import "./NavigationBar.css"
import HRLogo from "../../images/hr-logo-plain@4x.png"

import PlanningOverviewIcon from "@material-ui/icons/ViewDayRounded"
import TeachersIcon from "@material-ui/icons/SupervisorAccountRounded"
import CourseIcon from "@material-ui/icons/ClassRounded"
import CurriculaIcon from "@material-ui/icons/SchoolRounded"
import EducationSettingsIcon from "@material-ui/icons/SettingsRounded"
import LogOutIcon from "@material-ui/icons/ExitToAppRounded"
import ButtonBase from "@material-ui/core/ButtonBase"
import { version } from "../../../package.json"
import GitInfo from "react-git-info/macro"

export const NavigationBar = () => {
  const logOut = () => {
    fetch("/api/app/logout").then(() => {
      window.location.reload()
    })
  }

  const gitInfo = GitInfo()

  return (
    <div id="navigation-bar">
      <div id="navigation-bar--logo-and-system">
        <div>
          <img id="navigation-bar--logo" src={HRLogo} alt="HR" />
          <h2>PTD</h2>
        </div>
      </div>
      <div id="navigation-bar--links">
        <NavLink activeClassName="navigation-bar-link-active" exact to="/">
          <PlanningOverviewIcon />
          Planningsoverzicht
        </NavLink>
        <NavLink activeClassName="navigation-bar-link-active" to="/teachers">
          <TeachersIcon />
          Docenten
        </NavLink>
        <NavLink activeClassName="navigation-bar-link-active" to="/courses">
          <CourseIcon />
          Cursussen
        </NavLink>
        <NavLink activeClassName="navigation-bar-link-active" to="/curricula">
          <CurriculaIcon />
          Curricula
        </NavLink>
        {/* <NavLink activeClassName="navigation-bar-link-active" to="/settings">
          <EducationSettingsIcon />
          Instellingen opleiding
        </NavLink> */}
        <div>
          <span className="navigation-bar-version">{version}</span>
          <span className="navigation-bar-commit-hash">
            {gitInfo.commit.hash.slice(0, 8)}
          </span>
        </div>
      </div>
      <ButtonBase id="navigation-bar--log-out" onClick={() => logOut()}>
        <div>
          <LogOutIcon />
          Log uit
        </div>
      </ButtonBase>
    </div>
  )
}
