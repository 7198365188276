import React from "react"
import useSWR, { mutate } from "swr"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentCurriculumState,
  searchbarState,
  selectedCurriculumForDetailsState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import {
  Checkbox,
  Divider,
  IconButton,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import { Dialog } from "../Dialog"
import { ExceptionResponse, useFetchState } from "../../utils"
import { useHistory } from "react-router-dom"
import { Button } from "../Buttons"

type TeacherType = {
  id: number
  personnel_code: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof TeacherType
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: "personnel_code",
    numeric: false,
    disablePadding: false,
    label: "Docentcode",
  },
]

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  setDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
)

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected, setDeleteConfirmationDialog } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Docenten
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Verwijder">
          <IconButton
            aria-label="Verwijder"
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

export const TeacherTable = () => {
  const setSelectedCurriculumForDetails = useSetRecoilState(
    selectedCurriculumForDetailsState
  )
  const currentCurriculum = useRecoilValue(currentCurriculumState)
  const searchQuery = useRecoilValue(searchbarState)
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const { data, error } = useSWR<TeacherType[]>(
    currentCurriculum.id !== -1
      ? `/api/teacher/all/${currentCurriculum.id}`
      : null
  )
  const [deleteTeacherStatus, setDeleteTeacherStatus] = useFetchState()
  const [selected, setSelected] = React.useState<TeacherType[]>([])
  const [
    deleteConfirmationDialog,
    setDeleteConfirmationDialog,
  ] = React.useState<boolean>(false)
  let history = useHistory()

  if (!data) return <Loader />

  if (error) return <ErrorState />

  const dataFiltered = data.filter((c) =>
    c.personnel_code.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = dataFiltered.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    teacher: TeacherType
  ) => {
    const selectedIndex = selected.indexOf(teacher)
    let newSelected: TeacherType[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, teacher)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const deleteSelected = () => {
    setDeleteTeacherStatus("loading")
    const payload = selected
    fetch("/api/teacher/delete-multiple", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (r) => {
        if (r.ok) {
          setDeleteTeacherStatus("success")
          setDeleteConfirmationDialog(false)
          setSnackbarText(
            `De geselecteerde ${
              selected.length > 1 ? "docenten zijn" : "docent is"
            } verwijderd.`
          )
          setSnackbarVisible(true)
          setSelected([])
          mutate(`/api/teacher/all/${currentCurriculum.id}`)
        } else {
          setDeleteTeacherStatus("error")
          const json = (await r.json()) as ExceptionResponse
          console.log(json)
        }
      })
      .catch((e) => {
        setDeleteTeacherStatus("error")
        console.log(e)
        setSnackbarText(
          `Is is iets fout gegaan bij het verwijderen van de ${
            selected.length > 1 ? "docenten" : "docent"
          }.`
        )
        setSnackbarVisible(true)
      })
  }

  const isSelected = (teacher: TeacherType) => selected.indexOf(teacher) !== -1

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        setDeleteConfirmationDialog={setDeleteConfirmationDialog}
      />
      <Divider />
      <TableContainer className="table-container">
        <Table stickyHeader>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={dataFiltered.length}
          />
          <TableBody>
            {data.length > 0 ? (
              dataFiltered.length > 0 ? (
                <>
                  {dataFiltered.map((teacher, key) => {
                    const isItemSelected = isSelected(teacher)

                    return (
                      <TableRow key={key} hover className="curricula-table-row">
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, teacher)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            history.push(`/teachers/${teacher.id}`)
                          }
                        >
                          {teacher.personnel_code}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    Geen resultaten
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>
                  Er zijn geen docenten. Maak een docent aan.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`${selected.length > 1 ? "Docenten" : "Docent"} verwijderen`}
        content={() => (
          <p>
            Weet je zeker dat je de geselecteerde{" "}
            {selected.length > 1 ? "docenten" : "docent"} wilt verwijderen?
          </p>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteTeacherStatus.loading}
              onClick={() => deleteSelected()}
              color="primary"
              variant="contained"
            >
              Verwijder {selected.length > 1 ? "docenten" : "docent"}
            </Button>
          </>
        )}
      />
    </>
  )
}
