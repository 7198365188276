import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ExportTeacherPersonaliaType } from "./ExportTeacher"
import { ExportDefaultSection } from "."

const ExportTeacherPersonaliaStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "24pt",
  },
  labels: {
    fontWeight: "bold",
    marginRight: "20pt",
  },
  values: {
    marginRight: "100pt",
  },
})

type ExportTeacherPersonaliaProps = {
  personalia: ExportTeacherPersonaliaType
}

const ExportTeacherPersonalia = (props: ExportTeacherPersonaliaProps) => {
  const { personalia } = props
  const styles = ExportTeacherPersonaliaStyles

  return (
    <ExportDefaultSection name="Personalia">
      <View style={styles.container}>
        <View style={styles.labels}>
          <Text>Docentcode</Text>
          <Text>Naam</Text>
          <Text>Achternaam</Text>
        </View>
        <View style={styles.values}>
          <Text>{personalia.personnel_code}</Text>
          <Text>{personalia.name}</Text>
          <Text>{personalia.surname}</Text>
        </View>
        <View style={styles.labels}>
          <Text>Functie</Text>
          <Text>Team</Text>
          <Text>Type aanstelling</Text>
        </View>
        <View style={styles.values}>
          <Text>{personalia.position}</Text>
          <Text>{personalia.team}</Text>
          <Text>{personalia.contract}</Text>
        </View>
      </View>
    </ExportDefaultSection>
  )
}

export default ExportTeacherPersonalia
