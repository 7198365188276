import { object, string, number, bool, mixed, array, boolean } from "yup"
import {
  Organisation,
  TeacherDetailsType,
  Team,
  Position,
  TeacherAanstellingType,
  MOTTask,
} from "."
import { newMOTTaskType } from "./MOT"

export const teacherDetailsValidationObject = object<TeacherDetailsType>({
  personnel_code: string()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  first_name: string()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  last_name: string()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  isPlaceholder: bool().required(),
  organisation: object<Organisation>({
    id: number()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
    name: string()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
  }).defined(),
  team: object<Team>({
    id: number()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
    name: string()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
  }).defined(),
  position: object<Position>({
    id: number()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
    name: string()
      .typeError("Dit veld is verplicht")
      .required("Dit veld is verplicht"),
  }).defined(),
})

export const teacherAanstellingValidationObject = object<
  Omit<TeacherAanstellingType, "contract" | "mot_tasks">
>({
  months_active: number()
    .min(0, ">= 0")
    .typeError("Dit veld is verplicht")
    .required(),
  fte_op1: number()
    .min(0, ">= 0")
    .typeError("Dit veld is verplicht")
    .required(),
  fte_op2: number()
    .min(0, ">= 0")
    .typeError("Dit veld is verplicht")
    .required(),
  fte_op3: number()
    .min(0, ">= 0")
    .typeError("Dit veld is verplicht")
    .required(),
  fte_op4: number()
    .min(0, ">= 0")
    .typeError("Dit veld is verplicht")
    .required(),
  organisational: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  professional: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  di_op1: number().min(0, ">= 0").typeError("Dit veld is verplicht").required(),
  di_op2: number().min(0, ">= 0").typeError("Dit veld is verplicht").required(),
  di_op3: number().min(0, ">= 0").typeError("Dit veld is verplicht").required(),
  di_op4: number().min(0, ">= 0").typeError("Dit veld is verplicht").required(),
})

export const teacherAanstellingMOTValidationObject = object<MOTTask>({
  id: number().required(),
  name: string().required("Dit veld is verplicht"),
  type: string().required("Dit veld is verplicht"),
  hours: number()
    .typeError("Dit veld is verplicht")
    .min(0, ">= 0")
    .required("Dit veld is verplicht"),
  active_op1: boolean().required(),
  active_op2: boolean().required(),
  active_op3: boolean().required(),
  active_op4: boolean().required(),
})

export const teacherAanstellingMOTArrayValidationObject = array<MOTTask>().of(
  teacherAanstellingMOTValidationObject
)

export const newMOTTaskValidationObject = object<Omit<MOTTask, "id">>({
  name: string().required("Dit veld is verplicht"),
  type: string().required("Dit veld is verplicht"),
  hours: number()
    .min(0, "Uren kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  active_op1: boolean().required(),
  active_op2: boolean().required(),
  active_op3: boolean().required(),
  active_op4: boolean().required(),
})
