import { atom, selector } from "recoil"

export const currentCurriculumAndKindState = atom<string>({
  key: "currentCurriculumAndKindState",
  default: "",
})

export const currentCurriculumAndKindObject = selector({
  key: "currentCurriculumAndKindObject",
  get: ({ get }) => {
    const currentCurriculumAndKindString = get(currentCurriculumAndKindState)
    return {
      curriculum: currentCurriculumAndKindString.slice(0, 5),
      kind: currentCurriculumAndKindString.slice(6),
    }
  },
})

export const planningViewState = atom<"study-year" | "op">({
  key: "planningViewState",
  default: "op",
})

export const CurrentCellOverlayOpenState = atom<number>({
  key: "CurrentCellOverlayOpenState",
  default: -1,
})
