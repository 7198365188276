import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ExportDefaultSection, ExportInformationCard } from "."
import { ExportTeacherTaaktoedelingType } from "./ExportTeacher"

const ExportTeacherTaaktoedelingStyles = StyleSheet.create({
  container: {
    marginBottom: "100%",
    display: "flex",
    flexDirection: "row",
  },
  values_container: {
    display: "flex",
    flexDirection: "row",
  },
  label_container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    width: 30,
    fontWeight: "bold",
  },
  value: {
    width: 30,
  },
  value_container: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    marginHorizontal: 20,
    width: 2,
    height: "100%",
    backgroundColor: "#fff",
  },
  taaktoedeling_container: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "2pt",
    minHeight: "20pt",
  },
  header_row: {
    fontWeight: "bold",
  },
  header_cell: {
    fontWeight: "bold",
    borderBottomWidth: "1pt",
    borderBottomStyle: "solid",
    borderBottomColor: "#e6e6e6",
  },
  body_cell: {
    borderBottomWidth: "1pt",
    borderBottomStyle: "solid",
    borderBottomColor: "#e6e6e6",
  },
  title1: {
    width: "170pt",
  },
  title2: {
    width: "240pt",
  },
  total: {
    width: "50pt",
    textAlign: "right",
  },
  op1: {
    width: "40pt",
    textAlign: "right",
  },
  op2: {
    width: "40pt",
    textAlign: "right",
  },
  op3: {
    width: "40pt",
    textAlign: "right",
  },
  op4: {
    width: "40pt",
    textAlign: "right",
  },
})

type ExportTeacherTaaktoedelingProps = {
  data: ExportTeacherTaaktoedelingType
}

const ExportTeacherTaaktoedeling = (props: ExportTeacherTaaktoedelingProps) => {
  const { data } = props
  const styles = ExportTeacherTaaktoedelingStyles

  return (
    <ExportDefaultSection name="Taaktoedeling">
      <ExportInformationCard type="info" maxWidth={{ value: 620, unit: "pt" }}>
        <Text>
          Uren worden op hele getallen afgerond en waarnodig naar beneden.
        </Text>
      </ExportInformationCard>
      <View style={styles.container}>
        {/* <View style={styles.values_container}>
          <View debug style={styles.label_container}>
            <Text style={styles.label}>FTE</Text>
            <Text style={styles.label}>OP1</Text>
            <Text style={styles.label}>OP2</Text>
            <Text style={styles.label}>OP3</Text>
            <Text style={styles.label}>OP4</Text>
            <Text style={{ ...styles.label, marginTop: 6 }}>DI</Text>
            <Text style={styles.label}>OP1</Text>
            <Text style={styles.label}>OP2</Text>
            <Text style={styles.label}>OP3</Text>
            <Text style={styles.label}>OP4</Text>
            <Text>Organisatorische taak ALG (%)</Text>
            <Text>Basisrecht professionalisering (%)</Text>
          </View>
          <View debug style={styles.value_container}>
            <Text style={styles.value}>  </Text>
            <Text style={styles.value}>{data.fte_op1}</Text>
            <Text style={styles.value}>{data.fte_op2}</Text>
            <Text style={styles.value}>{data.fte_op3}</Text>
            <Text style={styles.value}>{data.fte_op4}</Text>
            <Text style={{ ...styles.value, marginTop: 6 }}>  </Text>
            <Text style={styles.value}>{data.di_op1}</Text>
            <Text style={styles.value}>{data.di_op2}</Text>
            <Text style={styles.value}>{data.di_op3}</Text>
            <Text style={styles.value}>{data.di_op4}</Text>
            <Text>{data.organisational}%</Text>
            <Text>{data.professional}%</Text>
          </View>
        </View>
        <View style={styles.divider} /> */}
        <View style={styles.taaktoedeling_container}>
          <View style={{ ...styles.row, ...styles.header_row }}>
            <Text style={styles.title1}></Text>
            <Text style={styles.title2}></Text>
            <Text style={styles.total}>Totaal</Text>
            <Text style={styles.op1}>OP1</Text>
            <Text style={styles.op2}>OP2</Text>
            <Text style={styles.op3}>OP3</Text>
            <Text style={styles.op4}>OP4</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Beschikbare uren
            </Text>
            <Text
              style={{ ...styles.body_cell, ...styles.title2, fontSize: 8 }}
            >
              OP1: {data.fte_op1} | OP2: {data.fte_op2} | OP3: {data.fte_op3} |
              OP4: {data.fte_op4}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.total_available_hours}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.total_available_hours_op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.total_available_hours_op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.total_available_hours_op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.total_available_hours_op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>DI</Text>
            <Text
              style={{ ...styles.body_cell, ...styles.title2, fontSize: 8 }}
            >
              OP1: {data.di_op1} | OP2: {data.di_op2} | OP3: {data.di_op3} |
              OP4: {data.di_op4}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.di.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.di.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.di.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.di.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.di.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Detachering
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.secondments.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.secondments.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.secondments.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.secondments.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.secondments.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Organisatorische taak
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Organisatorische taak ALG ({data.organisational}%)
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.organizational_tasks.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.organizational_tasks.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.organizational_tasks.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.organizational_tasks.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.organizational_tasks.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Overige taken
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.organization_tasks_other.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.organization_tasks_other.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.organization_tasks_other.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.organization_tasks_other.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.organization_tasks_other.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Professionaliseringstaak
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Basisrecht professionalisering ({data.professional}%)
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.professionalization_tasks.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.professionalization_tasks.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.professionalization_tasks.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.professionalization_tasks.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.professionalization_tasks.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Overige taken
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.professionalization_tasks_other.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.professionalization_tasks_other.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.professionalization_tasks_other.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.professionalization_tasks_other.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.professionalization_tasks_other.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Onderwijstaken
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Contacttijd
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.contact_time.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.contact_time.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.contact_time.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.contact_time.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.contact_time.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Contacttijd inclusief opslag
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.contact_time_with_factor.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.contact_time_with_factor.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.contact_time_with_factor.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.contact_time_with_factor.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.contact_time_with_factor.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Begeleiding
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Stagiairs
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.supervision.interns.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.supervision.interns.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.supervision.interns.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.supervision.interns.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.supervision.interns.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}>
              Afstudeerders
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.supervision.graduates.total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.supervision.graduates.op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.supervision.graduates.op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.supervision.graduates.op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.supervision.graduates.op4}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.header_cell, ...styles.title1 }}>
              Restant beschikbare uren
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.title2 }}></Text>
            <Text style={{ ...styles.body_cell, ...styles.total }}>
              {data.taaktoedeling.oddment_total}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op1 }}>
              {data.taaktoedeling.oddment_op1}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op2 }}>
              {data.taaktoedeling.oddment_op2}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op3 }}>
              {data.taaktoedeling.oddment_op3}
            </Text>
            <Text style={{ ...styles.body_cell, ...styles.op4 }}>
              {data.taaktoedeling.oddment_op4}
            </Text>
          </View>
        </View>
      </View>
    </ExportDefaultSection>
  )
}

export default ExportTeacherTaaktoedeling
