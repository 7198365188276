import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ExportDefaultSection } from "."
import { ExportTeacherSecondmentType } from "./ExportTeacher"

const ExportTeacherSecondmentsStyle = StyleSheet.create({
  container: {
    marginBottom: "24pt",
  },
  row_container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5pt",
  },
  row_header: {
    fontWeight: "bold",
  },
  hours: {
    width: 40,
  },
  reason: {
    width: 130,
  },
})

type ExportTeacherSecondmentsProps = {
  data: ExportTeacherSecondmentType[]
}
const ExportTeacherSecondments = (props: ExportTeacherSecondmentsProps) => {
  const { data } = props
  const styles = ExportTeacherSecondmentsStyle

  return (
    <View style={styles.container}>
      <ExportDefaultSection name="Detachering">
        <View style={{ ...styles.row_container, ...styles.row_header }}>
          <Text style={styles.hours}>Uren</Text>
          <Text style={styles.reason}>Reden</Text>
          <Text style={styles.op}>OP</Text>
        </View>
        {data.map((secondment, key) => (
          <View key={key} style={styles.row_container}>
            <Text style={styles.hours}>{secondment.hours}</Text>
            <Text style={styles.reason}>{secondment.reason}</Text>
            <Text style={styles.op}>
              {[
                secondment.op1,
                secondment.op2,
                secondment.op3,
                secondment.op4,
              ].map((op, i, a) =>
                op
                  ? `OP${i + 1}${
                      a.filter((o) => o).length > 1 && i !== 3 ? ", " : ""
                    }`
                  : ""
              )}
            </Text>
          </View>
        ))}
      </ExportDefaultSection>
    </View>
  )
}

export default ExportTeacherSecondments
