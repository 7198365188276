import "./CurriculumDetails.css"
import React, { useEffect, useState } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import {
  createStyles,
  Divider,
  lighten,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  selectedCurriculumForDetailsState,
  snackbarTextState,
  snackbarVisibleState,
} from "../../state/app"
import useSWR, { mutate } from "swr"
import { EmptyDetailsState } from "../EmptyDetailsState"
import { Loader } from "../Loader"
import { ErrorState } from "../ErrorState"
import { DatePicker } from "@material-ui/pickers/DatePicker/DatePicker"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { CurriculumMOTTable } from "./MOT"
import { ValidationError } from "yup"
import {
  checkArrayValidationErrors,
  checkValidationErrors,
  Errors,
  ErrorsArray,
  ExceptionResponse,
  useFetchState,
} from "../../utils"
import {
  curriculumDetailsMOTArrayValidationObject,
  curriculumDetailsValidationObject,
} from "./ValidationObjects"
import { Dialog } from "../Dialog"
import { Button } from "../Buttons"
import { totalHours } from "../CreateCurriculumDialog"
import InformationCard from "../InformationCard"

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      borderBottom: "1px solid rgba(0, 0, 0, .12)",
      zIndex: 2,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flexGrow: 1,
    },
    deleteButton: {
      marginRight: "16px",
    },
  })
)

export type CurriculumDetailsType = {
  id: number
  name: string
  default_fte: number
  default_fte_hours: number
  default_di: number
  default_organisational: number
  default_professional: number
  course_formula_w: number
  course_formula_ct: number
  course_formula_opslag: number
  course_formula_n: number
  course_formula_cor: number
  course_formula_r: number
  start_date: Date
  end_date: Date
  mot_tasks: CurriculumDetailsMOT[]
  availability_type: string
}

export type CurriculumDetailsMOT = {
  id: number
  type: string
  name: string
  hours: number
  active_op1: boolean
  active_op2: boolean
  active_op3: boolean
  active_op4: boolean
}

export const CurriculumDetails = () => {
  const classes = useToolbarStyles()
  const [selectedCurriculum, setSelectedCurriculum] = useRecoilState(
    selectedCurriculumForDetailsState
  )
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [updateCurriculumStatus, setUpdateCurriculumStatus] = useFetchState()
  const [deleteCurriculumStatus, setDeleteCurriculumStatus] = useFetchState()
  const [
    deleteConfirmationDialog,
    setDeleteConfirmationDialog,
  ] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors<CurriculumDetailsType>[]>([])
  const [errorsMOT, setErrorsMOT] = useState<
    ErrorsArray<CurriculumDetailsMOT>[]
  >([])
  const [
    curriculumDetails,
    setCurriculumDetails,
  ] = useState<CurriculumDetailsType>({
    id: -1,
    name: "",
    default_fte: -1,
    default_fte_hours: -1,
    default_di: -1,
    default_organisational: -1,
    default_professional: -1,
    course_formula_w: -1,
    course_formula_ct: -1,
    course_formula_opslag: -1,
    course_formula_n: -1,
    course_formula_cor: -1,
    course_formula_r: -1,
    start_date: new Date(),
    end_date: new Date(),
    mot_tasks: [],
    availability_type: "",
  })
  const { data, error } = useSWR<CurriculumDetailsType>(() =>
    selectedCurriculum !== -1 ? `/api/curricula/${selectedCurriculum}` : null
  )

  useEffect(() => {
    if (typeof data !== "undefined") {
      setCurriculumDetails(data)
    }
  }, [data])

  useEffect(() => {
    if (selectedCurriculum) {
      setUpdateCurriculumStatus("initial")
      setDeleteCurriculumStatus("initial")
    }
  }, [selectedCurriculum])

  if (selectedCurriculum === -1) return <EmptyDetailsState kind="curriculum" />

  if (!data) return <Loader />

  if (error) return <ErrorState />

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)
    setCurriculumDetails({
      ...curriculumDetails,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const handleDateChange = (
    d: MaterialUiPickersDate,
    type: "start_date" | "end_date"
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${type}: ${d?.toLocaleDateString()}`)
    setCurriculumDetails({ ...curriculumDetails, [type]: d })
  }

  const updateCurriculum = () => {
    curriculumDetailsValidationObject
      .validate(curriculumDetails, { abortEarly: false })
      .then(() => {
        curriculumDetailsMOTArrayValidationObject
          .validate(curriculumDetails.mot_tasks, { abortEarly: false })
          .then(() => {
            setUpdateCurriculumStatus("loading")
            setErrors([])
            setErrorsMOT([])
            fetch(`/api/curricula/${selectedCurriculum}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(curriculumDetails),
            })
              .then(async (r) => {
                if (r.ok) {
                  setUpdateCurriculumStatus("success")
                  setSnackbarText(`De wijzigingen zijn opgeslagen`)
                  setSnackbarVisible(true)
                  mutate(`/api/curricula/${selectedCurriculum}`)
                } else {
                  setUpdateCurriculumStatus("error")
                  const json = (await r.json()) as ExceptionResponse
                  console.log(json)
                }
              })
              .catch((e) => {
                setUpdateCurriculumStatus("error")
                console.log(e)
                setSnackbarText(
                  `Er is iets fout gegaan bij het opslaan van de wijzigingen`
                )
                setSnackbarVisible(true)
              })
          })
          .catch((ve: ValidationError) =>
            checkArrayValidationErrors<CurriculumDetailsMOT>(ve, setErrorsMOT)
          )
      })
      .catch((ve: ValidationError) =>
        checkValidationErrors<CurriculumDetailsType>(ve, setErrors)
      )
  }

  const deleteCurriculum = () => {
    setDeleteCurriculumStatus("loading")
    fetch(`/api/curricula/delete/${selectedCurriculum}`, {
      method: "DELETE",
    })
      .then(async (r) => {
        if (r.ok) {
          setDeleteCurriculumStatus("success")
          setSelectedCurriculum(-1)
          setSnackbarText(`Het curriculum is verwijderd`)
          setSnackbarVisible(true)
          mutate("/api/curricula")
        } else {
          setDeleteCurriculumStatus("error")
          const json = (await r.json()) as ExceptionResponse
          console.log(json)
        }
      })
      .catch((e) => {
        setDeleteCurriculumStatus("error")
        console.log(e)
        setSnackbarText(
          `Is is iets fout gegaan bij het verwijderen van de curriculum`
        )
        setSnackbarVisible(true)
      })
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {curriculumDetails.name}
        </Typography>
        <Button
          disabled={deleteCurriculumStatus.loading}
          variant="outlined"
          color="primary"
          onClick={() => setDeleteConfirmationDialog(true)}
          className={classes.deleteButton}
        >
          Verwijder curriculum
        </Button>
        <Button
          fetchStatus={[updateCurriculumStatus, setUpdateCurriculumStatus]}
          variant="contained"
          color="primary"
          onClick={() => updateCurriculum()}
        >
          Sla wijzigingen op
        </Button>
      </Toolbar>
      <div className="card-content">
        <h6 className="subkop">Standaard waardes</h6>
        <InformationCard
          type="info"
          content={() => (
            <>
              <div id="curricula-formula">
                <strong className="formula-variable">
                  Totaal aantal uren per schooljaar
                </strong>{" "}
                x <strong className="formula-variable">FTE</strong> x{" "}
                <strong className="formula-variable">(DI | %)</strong> = X uren
              </div>
            </>
          )}
        />
        <div className="column-2">
          <InformationCard
            type="info"
            disableIcon
            content={() => (
              <>
                <TextField
                  fullWidth
                  label="FTE"
                  variant="outlined"
                  type="number"
                  name="default_fte"
                  value={curriculumDetails.default_fte}
                  onChange={(e) => handleChangeNumber(e)}
                  error={errors.some((e) => e.path === "default_fte")}
                  helperText={
                    errors.find((e) => e.path === "default_fte")?.message
                      ? errors.find((e) => e.path === "default_fte")?.message
                      : "Voor elk OP"
                  }
                />
                <span style={{ marginTop: "6px", display: "block" }}>
                  1 FTE = 1659 uren per schooljaar
                </span>
              </>
            )}
          />
          {/* <InformationCard
            type="info"
            disableIcon
            content={() => (
              <>
                <TextField
                  label="Duurzaam Inzetbaarheid (DI)"
                  fullWidth
                  variant="outlined"
                  type="number"
                  name="default_di"
                  value={curriculumDetails.default_di}
                  onChange={(e) => handleChangeNumber(e)}
                  error={errors.some((e) => e.path === "default_di")}
                  helperText={
                    errors.find((e) => e.path === "default_di")?.message
                      ? errors.find((e) => e.path === "default_di")?.message
                      : "Voor elk OP"
                  }
                />
                <span style={{ marginTop: "6px", display: "block" }}>
                  {totalHours} x {curriculumDetails.default_fte} x{" "}
                  {curriculumDetails.default_di} ={" "}
                  {Math.ceil(
                    curriculumDetails.default_di *
                      totalHours *
                      curriculumDetails.default_fte
                  )}{" "}
                  uur per OP
                </span>
                <span style={{ marginTop: "6px", display: "block" }}>
                  {Math.ceil(
                    curriculumDetails.default_di *
                      totalHours *
                      curriculumDetails.default_fte
                  )}{" "}
                  x 4 ={" "}
                  {Math.ceil(
                    curriculumDetails.default_di *
                      totalHours *
                      curriculumDetails.default_fte
                  ) * 4}{" "}
                  uur per schooljaar
                </span>
              </>
            )}
          /> */}
        </div>
        <div className="column-2">
          <InformationCard
            type="info"
            style={{ marginTop: "12px" }}
            disableIcon
            content={() => (
              <>
                <TextField
                  label="Organisatorische taak ALG (%)"
                  fullWidth
                  variant="outlined"
                  type="number"
                  name="default_organisational"
                  value={curriculumDetails.default_organisational}
                  onChange={(e) => handleChangeNumber(e)}
                  error={errors.some(
                    (e) => e.path === "default_organisational"
                  )}
                  helperText={
                    errors.find((e) => e.path === "default_organisational")
                      ?.message
                  }
                />
                <span style={{ marginTop: "6px", display: "block" }}>
                  {totalHours} x {curriculumDetails.default_fte} x{" "}
                  {curriculumDetails.default_organisational}% ={" "}
                  {Math.round(
                    totalHours *
                      (curriculumDetails.default_organisational / 100) *
                      curriculumDetails.default_fte
                  )}{" "}
                  uren per schooljaar
                </span>
                <span style={{ marginTop: "6px", display: "block" }}>
                  {Math.round(
                    totalHours *
                      (curriculumDetails.default_organisational / 100) *
                      curriculumDetails.default_fte
                  )}{" "}
                  / 4 ={" "}
                  {Math.round(
                    (totalHours *
                      (curriculumDetails.default_organisational / 100) *
                      curriculumDetails.default_fte) /
                      4
                  )}{" "}
                  uren per OP
                </span>
              </>
            )}
          />
          <InformationCard
            type="info"
            style={{ marginTop: "12px" }}
            disableIcon
            content={() => (
              <>
                <TextField
                  label="Basisrecht professionalisering (%)"
                  fullWidth
                  variant="outlined"
                  type="number"
                  name="default_professional"
                  value={curriculumDetails.default_professional}
                  onChange={(e) => handleChangeNumber(e)}
                  error={errors.some((e) => e.path === "default_professional")}
                  helperText={
                    errors.find((e) => e.path === "default_professional")
                      ?.message
                  }
                />
                <span style={{ marginTop: "6px", display: "block" }}>
                  {totalHours} x {curriculumDetails.default_fte} x{" "}
                  {curriculumDetails.default_professional}% ={" "}
                  {Math.round(
                    totalHours *
                      (curriculumDetails.default_professional / 100) *
                      curriculumDetails.default_fte
                  )}{" "}
                  uren per schooljaar
                </span>
                <span style={{ marginTop: "6px", display: "block" }}>
                  {Math.round(
                    totalHours *
                      (curriculumDetails.default_professional / 100) *
                      curriculumDetails.default_fte
                  )}{" "}
                  / 4 ={" "}
                  {Math.round(
                    (totalHours *
                      (curriculumDetails.default_professional / 100) *
                      curriculumDetails.default_fte) /
                      4
                  )}{" "}
                  per OP
                </span>
              </>
            )}
          />
        </div>
        <div>
          <InformationCard
            type="info"
            style={{ marginTop: "12px" }}
            content={() => (
              <>
                <span>
                  Uren worden op hele getallen afgerond en waarnodig naar
                  beneden.
                </span>
              </>
            )}
          />
        </div>
      </div>
      <Divider variant="middle" />
      <div className="card-content">
        <InformationCard
          type="info"
          content={() => (
            <div id="curricula-formula">
              Formule cursus = <strong className="formula-variable">w</strong> x{" "}
              <strong className="formula-variable">Ct</strong> x{" "}
              <strong className="formula-variable">Opslag</strong> +{" "}
              <strong className="formula-variable">n</strong> x{" "}
              <strong className="formula-variable">Cor</strong> +{" "}
              <strong className="formula-variable">R</strong> = y min / 60 = z
              uur
            </div>
          )}
        />
        <div id="curricula-inputs">
          <TextField
            label="w"
            variant="outlined"
            type="number"
            name="course_formula_w"
            value={curriculumDetails.course_formula_w}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_w")}
            helperText={
              errors.find((e) => e.path === "course_formula_w")
                ? errors.find((e) => e.path === "course_formula_w")?.message
                : "Aantal lesmomenten. Bijv.: 4"
            }
          />
          <TextField
            label="Ct"
            variant="outlined"
            type="number"
            name="course_formula_ct"
            value={curriculumDetails.course_formula_ct}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_ct")}
            helperText={
              errors.find((e) => e.path === "course_formula_ct")
                ? errors.find((e) => e.path === "course_formula_ct")?.message
                : "Contacttijd lesmoment met studenten (min). Bijv.: 150"
            }
          />
          <TextField
            label="Opslag"
            variant="outlined"
            type="number"
            name="course_formula_opslag"
            value={curriculumDetails.course_formula_opslag}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_opslag")}
            helperText={
              errors.find((e) => e.path === "course_formula_opslag")
                ? errors.find((e) => e.path === "course_formula_opslag")
                    ?.message
                : "Opslagfactor. Bijv.: 1,4 of 1,6"
            }
          />
          <TextField
            label="n"
            variant="outlined"
            type="number"
            name="course_formula_n"
            value={curriculumDetails.course_formula_n}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_n")}
            helperText={
              errors.find((e) => e.path === "course_formula_n")
                ? errors.find((e) => e.path === "course_formula_n")?.message
                : "Studenten in een klas. Bijv.: 30"
            }
          />
          <TextField
            label="Cor"
            variant="outlined"
            type="number"
            name="course_formula_cor"
            value={curriculumDetails.course_formula_cor}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_cor")}
            helperText={
              errors.find((e) => e.path === "course_formula_cor")
                ? errors.find((e) => e.path === "course_formula_cor")?.message
                : "Correctietijd per student (min). Bijv.: 10"
            }
          />
          <TextField
            label="R"
            variant="outlined"
            type="number"
            name="course_formula_r"
            value={curriculumDetails.course_formula_r}
            onChange={(e) => handleChangeNumber(e)}
            error={errors.some((e) => e.path === "course_formula_r")}
            helperText={
              errors.find((e) => e.path === "course_formula_r")
                ? errors.find((e) => e.path === "course_formula_r")?.message
                : "Reistijd. Bijv.: 0"
            }
          />
        </div>
      </div>
      <Divider variant="middle" />
      <div className="card-content">
        <div className="column-2">
          <DatePicker
            label="Startdatum"
            variant="dialog"
            inputVariant="outlined"
            name="start_date"
            value={curriculumDetails.start_date}
            onChange={(d) => handleDateChange(d, "start_date")}
            format="dd-MM-yyyy"
            cancelLabel="Annuleer"
            okLabel="Selecteer datum"
          />
          <DatePicker
            label="Einddatum"
            variant="dialog"
            inputVariant="outlined"
            name="end_date"
            value={curriculumDetails.end_date}
            onChange={(d) => handleDateChange(d, "end_date")}
            format="dd-MM-yyyy"
            cancelLabel="Annuleer"
            okLabel="Selecteer datum"
          />
        </div>
      </div>
      <Divider variant="middle" />
      <div className="card-content">
        <div className="column-2">
          <TextField
            label="Beschikbaarheid type"
            variant="outlined"
            disabled
            type="text"
            name="availability_type"
            value={curriculumDetails.availability_type}
          />
        </div>
      </div>
      <Divider variant="middle" />
      <div className="card-content">
        <CurriculumMOTTable
          errors={errorsMOT}
          mot_tasks={curriculumDetails.mot_tasks}
          setCurriculumMOTTasks={(mot_tasks: CurriculumDetailsMOT[]) =>
            setCurriculumDetails({ ...curriculumDetails, mot_tasks })
          }
        />
      </div>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`Curriculum verwijderen`}
        content={() => (
          <p>Weet je zeker dat je het curriculum wilt verwijderen?</p>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteCurriculumStatus.loading}
              onClick={() => deleteCurriculum()}
              color="primary"
              variant="contained"
            >
              Verwijder curriculum
            </Button>
          </>
        )}
      />
    </>
  )
}
