import "./Cells.css"
import React, { useEffect, useState } from "react"
import {
  StudyYearCell,
  StudyYearClassCell,
  StudyYearCoursCell,
} from "./StudyYear"
import AddIcon from "@material-ui/icons/AddRounded"
import { Popover, PopoverOrigin } from "@material-ui/core"
import { CellOverlay, ClassOverlay, CourseOverlay } from "./CellOverlays"
import { hex2rgba } from "../../utils"
import { useRecoilState } from "recoil"
import { CurrentCellOverlayOpenState } from "../../state/planning"

const popoverOrigin: {
  anchorOrigin: PopoverOrigin
  transformOrigin: PopoverOrigin
} = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
}

type HeadCellClassProps = {
  year: number
  op: number
  _class: StudyYearClassCell
  first: boolean
  last: boolean
  length: number
  selectedStudyYear: number[]
  selectedOP: number[]
  classesPlannedInOP: StudyYearClassCell[]
}

export type HeadCellClassType = {
  left: boolean
  right: boolean
  update: boolean
}

export const HeadCellClass = (props: HeadCellClassProps) => {
  const {
    year,
    op,
    _class,
    first,
    last,
    length,
    selectedStudyYear,
    selectedOP,
    classesPlannedInOP,
  } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [cellType, setCellType] = useState<HeadCellClassType>({
    left: false,
    right: false,
    update: false,
  })

  const handleClose = () => {
    setAnchorEl(null)
    setCellType({
      left: false,
      right: false,
      update: false,
    })
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <th className="planning-study-year-table-class">
        <div>
          <div
            className={`table-class-left ${cellType.left && "cell-active"}`}
            onClick={(event) => {
              if (!first) {
                setCellType({
                  ...cellType,
                  left: true,
                })
                setAnchorEl(event.currentTarget)
              }
            }}
          />
          <div
            className={`table-class-name ${cellType.update && "cell-active"}`}
            onClick={(event) => {
              setCellType({
                ...cellType,
                update: true,
              })
              setAnchorEl(event.currentTarget)
            }}
          >
            {_class.name}
          </div>
          <div
            className={`table-class-right ${cellType.right && "cell-active"}`}
            onClick={(event) => {
              if (!last) {
                setCellType({
                  ...cellType,
                  right: true,
                })
                setAnchorEl(event.currentTarget)
              }
            }}
          />
        </div>
      </th>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverOrigin.anchorOrigin}
        transformOrigin={popoverOrigin.transformOrigin}
      >
        <ClassOverlay
          year={year}
          op={op}
          handleClose={handleClose}
          _class={_class}
          cellType={cellType}
          create={!cellType.update}
          length={length}
          selectedStudyYear={selectedStudyYear}
          selectedOP={selectedOP}
          classesPlannedInOP={classesPlannedInOP}
        />
      </Popover>
    </>
  )
}

type HeadCellCourseProps = {
  year: number
  op: number
  course: StudyYearCoursCell
  first: boolean
  last: boolean
  length: number
}

export type HeadCellCourseType = {
  top: boolean
  bottom: boolean
  update: boolean
}

export const HeadCellCourse = (props: HeadCellCourseProps) => {
  const { year, op, course, first, last, length } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [cellType, setCellType] = useState<HeadCellCourseType>({
    top: false,
    bottom: false,
    update: false,
  })

  const handleClose = () => {
    setAnchorEl(null)
    setCellType({
      top: false,
      bottom: false,
      update: false,
    })
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <th className="planning-study-year-table-course">
        <div>
          <div
            className={`table-course-top ${cellType.top && "cell-active"}`}
            onClick={(event) => {
              if (!first) {
                setCellType({
                  ...cellType,
                  top: true,
                })
                setAnchorEl(event.currentTarget)
              }
            }}
          />
          <div
            className={`table-course-name ${cellType.update && "cell-active"}`}
            onClick={(event) => {
              setCellType({
                ...cellType,
                update: true,
              })
              setAnchorEl(event.currentTarget)
            }}
          >
            <span>{course.name}</span>
            <span
              className={`planning-study-year-table-course-workmethod ${
                cellType.update && "cell-workmethod-active"
              }`}
            >
              {course.work_method !== "Standaard" && course.work_method}
            </span>
          </div>
          <div
            className={`table-course-bottom ${
              cellType.bottom && "cell-active"
            }`}
            onClick={(event) => {
              if (!last) {
                setCellType({
                  ...cellType,
                  bottom: true,
                })
                setAnchorEl(event.currentTarget)
              }
            }}
          />
        </div>
      </th>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverOrigin.anchorOrigin}
        transformOrigin={popoverOrigin.transformOrigin}
      >
        <CourseOverlay
          year={year}
          op={op}
          handleClose={handleClose}
          course={course}
          cellType={cellType}
          create={!cellType.update}
          length={length}
        />
      </Popover>
    </>
  )
}

type BodyCellProps = {
  cell: StudyYearCell
  course: StudyYearCoursCell
  year: number
  op: number
}

export const BodyCell = (props: BodyCellProps) => {
  const { cell, course, year, op } = props
  const [currentCellOverlayOpen, setCurrentCellOverlayOpen] = useRecoilState(
    CurrentCellOverlayOpenState
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const ref = React.useRef(null)

  const handleClose = () => {
    setCurrentCellOverlayOpen(-1)
  }

  useEffect(() => {
    setAnchorEl(ref.current)
  }, [])

  const teachers = cell && cell.teachers.slice(0, 2)
  const open = currentCellOverlayOpen === cell.id

  return (
    <>
      <td
        className="planning-study-year-table-cell"
        onClick={(event) => {
          setCurrentCellOverlayOpen(cell.id)
          setAnchorEl(event.currentTarget)
        }}
        ref={ref}
      >
        <div
          style={{
            border: cell.teachers.some((v) => v.available_hours < 0)
              ? `2px solid #cc0033`
              : cell.label.color
              ? `2px solid ${cell.label.color}`
              : `none`,
            backgroundColor: cell.teachers.some((v) => v.available_hours < 0)
              ? hex2rgba("#cc0033", 0.2)
              : cell.label.color
              ? hex2rgba(cell.label.color, 0.2)
              : "white",
            height: "71px",
          }}
        >
          {cell &&
            teachers &&
            teachers.map((teacher, key) => (
              <span key={`${key}-${teacher.id}-${teacher.personnel_code}`}>
                {teacher.personnel_code}{" "}
                {cell.teachers.length > 2 &&
                  teachers.length === key + 1 &&
                  `, +${cell.teachers.length - 2}`}
              </span>
            ))}
        </div>
      </td>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverOrigin.anchorOrigin}
        transformOrigin={popoverOrigin.transformOrigin}
      >
        <CellOverlay cell={cell!} course={course} year={year} op={op} />
      </Popover>
    </>
  )
}

type CreateCellProps = {
  type: "class" | "course"
  year: number
  op: number
  selectedStudyYear: number[]
  selectedOP: number[]
  classesPlannedInOP: StudyYearClassCell[]
}

export const CreateCell = (props: CreateCellProps) => {
  const {
    type,
    year,
    op,
    selectedStudyYear,
    selectedOP,
    classesPlannedInOP,
  } = props
  const [
    anchorEl,
    setAnchorEl,
  ] = React.useState<HTMLTableHeaderCellElement | null>(null)

  const handleClick = (
    event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <th className="planning-study-year-table-add" onClick={handleClick}>
        <AddIcon />
      </th>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverOrigin.anchorOrigin}
        transformOrigin={popoverOrigin.transformOrigin}
      >
        {type === "class" ? (
          <ClassOverlay
            year={year}
            op={op}
            handleClose={handleClose}
            create
            length={0}
            selectedStudyYear={selectedStudyYear}
            selectedOP={selectedOP}
            classesPlannedInOP={classesPlannedInOP}
          />
        ) : (
          <CourseOverlay
            year={year}
            op={op}
            handleClose={handleClose}
            create
            length={0}
          />
        )}
      </Popover>
    </>
  )
}
