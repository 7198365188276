import React, { useCallback, useState } from "react"
import { ValidationError } from "yup"

export type ExceptionResponse = {
  type: string
  message: string
}

export type ExceptionResponseState = {
  status: number
  statusText: string
  type: string
  message: string
}

export type Errors<T> = {
  message: string
  path: keyof T
}

export type ErrorsArray<T> = {
  index: number
  message: string
  path: keyof T
}

export function checkValidationErrors<T>(
  err: ValidationError,
  setState: React.Dispatch<React.SetStateAction<Errors<T>[]>>
) {
  setState([])
  let tempArray: Errors<T>[] = []

  err.inner.forEach((e) => {
    tempArray.push({
      message: e.message,
      path: e.path as keyof T,
    })
  })

  setState(tempArray)
}

export function checkArrayValidationErrors<T>(
  err: ValidationError,
  setState: React.Dispatch<React.SetStateAction<ErrorsArray<T>[]>>
) {
  setState([])
  let tempArray: ErrorsArray<T>[] = []

  err.inner.forEach((e) => {
    const { index, path } = getIndexAndPath(e.path)
    tempArray.push({
      message: e.message,
      index,
      path: path as keyof T,
    })
  })

  setState(tempArray)
}

const getIndexAndPath = (i: string): { index: number; path: string } => {
  let index: string = ""
  let indexStartPath: number = 0

  i.split("").forEach((char, j) => {
    const n = Number(char)
    if (Number.isNaN(n)) {
      if (char === ".") {
        return (indexStartPath = j + 1)
      }
    } else {
      index = `${index}${n}`
    }
  })

  return { index: Number(index), path: i.slice(indexStartPath) }
}

type DialogErrorProps = {
  error: ExceptionResponseState
}

export const DialogError = (props: DialogErrorProps) => {
  const { error } = props
  return (
    <div className="dialog-error">
      <span className="dialog-error-type">{error.type}</span>
      <span className="dialog-error-message">{error.message}</span>
      <span className="dialog-error-status">
        {error.status} - {error.statusText}
      </span>
    </div>
  )
}

export const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const getBase64Logo = () =>
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI8AAACPCAYAAADDY4iTAAAACXBIWXMAACxKAAAsSgF3enRNAAAEI0lEQVR4nO2dfXLCIBBHk05vpPc/gZ4pHa06aZoP2LCwu7w30//aWOTxYxMBx2mahpLcx2vZC0JRLtNtLHW9U/Igin/OyCSSB2niIZEoSx6kiU+OREnyIE1/pEj0dfQLiNMnKf2+mTxIA8NBAq0mD+LAmz0XDqctgC2B/slD6kAqf+RBHNhizY2PPIgDRywdoeYBMU95SB1IZe4KyQNikAfEjLfhwpQF2TyePJM8IAZ5QAzygBhqHhBD8oAY5AExyANikAfEIA+IQR4QgzwgBnlADPKAGOTJ4DLdnj/wyzfvwzEIsw7y7IA0+yDPCkiTBvLMQJo8KJhfIE4+3a/nkUpzH6+qr5N7/RZ0O21pJ83Z68//3qpI3cmjKY3Wtd/XtSZRVzWPZufWqJms1WUUzCdo8cTZkkDII6RlJ1oRCHlATJiCeTkaPdzqnuHR3tZtDJE8qTEeXajauE4engq3xaU8SGMDV/IgjS1c1DyS5yk9iNa6jeblIW3sYlYerae33HGVw1zNQ9L4wYw8SJNP6xRtLo/2EomUN3jtd5D5mKY1T+sOekizJRe10TFNkseCNHCeqsnjbcclku1TRR626ZbnrNgl+kN12kKY2KglD+LoYWU6LZ48lqShZlmnVB8VTZ5oaWOxPZYGRNg1zKTOOiUHREh5oooTetNfpE6LWPCXblO45CF11tEYDKHkQZx1tFKUTX8rRJqyttpSYqAhzwJr4pzpZO22FJen1dQRccqStqnWZ4kkz4wI01VKG0oNNA60fOF9umrx/4eQx+rdyBlSTwNredYhyWMcy1OpSs3jrXjt5XOw0u2kYAYxXZ3P0zMa6cppqB2gNS2HkWdPDBbg66D29QEtO+sx0vZef2skRhRM82ZALXla3sGQMr9o9wF3W0GpMXiRB8QgT0BqlQzIMyPCk+aabVCVx2pnRC2oa7/fJE8QWgxU5AlAq4RHHue0LA2QZ4Gnojn8t95QNJdn7yzFmpA8zuCUDBDBtxs7wFonWZmmlnS5AJ6iuAxq63mWWChQPeyFeuNB8C6SR9oRR4vKNPCUiuFrnrOdUaszrdY1e4RNnpIdoZVA3j/FDyePVoe8r3tWokgbDKsVzINyAWptzXQPu1DdJ4+FTur12F7XBTNnLbfFZfIgjQ1cyYM0tnAhD9LYpOrd1pB5x4U0tjFbMCOOfcxNW0jjBzPyII0/msuDNH6pXjAPr6IZafzTRB6IAWuYQQzygBjkATHIA2KQB8QgD4hBHhCDPCAGeUAM8oAY5AExyANikAfEfF2m28jbBxJIHhCDPCDmKQ9TF0ggeUDMRx7SB3IheUDMH3lIH8iB5AEx/+QhfSCV1eRBIEhhc9pCIDhit+ZBINjjsGBGINhinKb0rer38cq+dviQJc8bJIJBKs8cROqUYRh+ADMzljjuHK1PAAAAAElFTkSuQmCC"

export type FetchStateType = {
  loading: boolean
  success: boolean
  error: boolean
}

type FetchStateActions = "initial" | "loading" | "success" | "error"

export const useFetchState = () => {
  const [fetchState, setFetchState] = useState<FetchStateType>({
    loading: false,
    success: false,
    error: false,
  })

  const setFetchStateCallback = (status: FetchStateActions) => {
    if (status === "loading") {
      setFetchState({ loading: true, success: false, error: false })
    } else if (status === "success") {
      setFetchState({ loading: false, success: true, error: false })
    } else if (status === "error") {
      setFetchState({ loading: false, success: false, error: true })
    } else {
      setFetchState({ loading: false, success: false, error: false })
    }
  }

  return [fetchState, setFetchStateCallback] as const
}
