import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TableCell,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import { object, string, boolean, number, array, ValidationError } from "yup"
import { snackbarTextState, snackbarVisibleState } from "../../../state/app"
import {
  checkArrayValidationErrors,
  checkValidationErrors,
  Errors,
  ErrorsArray,
  useFetchState,
} from "../../../utils"
import { Dialog } from "../../Dialog"
import Table, { HeadCell } from "../../Table"
import { Button } from "../../Buttons"

type TabMOT = {
  id: number
  name: string
  hours: number
  type: string
  active_op1: boolean
  active_op2: boolean
  active_op3: boolean
  active_op4: boolean
}

export const TabMOT = () => {
  let { id }: { id: string } = useParams()

  const [MOTS, setMOTS] = useState<TabMOT[]>([])
  const [MOTSErrors, setMOTSErrors] = useState<ErrorsArray<TabMOT>[]>([])
  const { data, error } = useSWR<TabMOT[]>(id ? `/api/teacher/${id}/mot` : null)

  const [MOTOptions, setMOTOptions] = useState<TabMOT[]>([])
  const { data: dataMOTOptions, error: errorMOTOptions } = useSWR<TabMOT[]>(
    id ? `/api/teacher/${id}/mot/options` : null
  )

  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [createMOTTaskStatus, setCreateMOTTaskStatus] = useFetchState()
  const [updateMOTTaskStatus, setUpdateMOTTaskStatus] = useFetchState()
  const [deleteMOTTaskStatus, setDeleteMOTTaskStatus] = useFetchState()
  const [newMOTTask, setNewMOTTask] = useState<Omit<TabMOT, "id">>({
    name: "",
    hours: 0,
    type: "Organisatorische",
    active_op1: false,
    active_op2: false,
    active_op3: false,
    active_op4: false,
  })
  const [newMOTTaskErrors, setnewMOTTaskErrors] = useState<
    Errors<Omit<TabMOT, "id">>[]
  >([])

  useEffect(() => {
    if (typeof data !== "undefined") {
      setMOTS(data)
    }
    if (typeof dataMOTOptions !== "undefined") {
      setMOTOptions(dataMOTOptions)
    }
  }, [data, dataMOTOptions])

  const headcells: HeadCell<TabMOT>[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Naam",
      minWidth: 200,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Type",
      minWidth: 100,
    },
    {
      id: "hours",
      numeric: false,
      disablePadding: false,
      label: "Uren",
      minWidth: 100,
    },
    {
      id: "active_op1",
      numeric: false,
      disablePadding: false,
      label: "OP1",
      minWidth: 50,
    },
    {
      id: "active_op2",
      numeric: false,
      disablePadding: false,
      label: "OP2",
      minWidth: 50,
    },
    {
      id: "active_op3",
      numeric: false,
      disablePadding: false,
      label: "OP3",
      minWidth: 50,
    },
    {
      id: "active_op4",
      numeric: false,
      disablePadding: false,
      label: "OP4",
      minWidth: 50,
    },
  ]

  const resetNewState = () => {
    setNewMOTTask({
      name: "",
      hours: 0,
      type: "Organisatorische",
      active_op1: false,
      active_op2: false,
      active_op3: false,
      active_op4: false,
    })
  }

  const saveChanges = () => {
    MOTTasksArrayValidationObject.validate(MOTS, { abortEarly: false })
      .then(() => {
        setUpdateMOTTaskStatus("loading")
        fetch(`/api/teacher/${id}/mot/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(MOTS),
        })
          .then(async (r) => {
            if (r.ok) {
              setUpdateMOTTaskStatus("success")
              mutate(`/api/teacher/${id}/mot`)
              setSnackbarText(`De wijzigingen zijn opgeslagen`)
              setSnackbarVisible(true)
            } else {
              setUpdateMOTTaskStatus("error")
            }
          })
          .catch((e) => console.log(e))
      })
      .catch((e: ValidationError) =>
        checkArrayValidationErrors(e, setMOTSErrors)
      )
  }

  const createMOTTask = (onClose: () => void) => {
    newMOTTaskValidationObject
      .validate(newMOTTask, { abortEarly: false })
      .then(() => {
        setCreateMOTTaskStatus("loading")
        setnewMOTTaskErrors([])
        fetch(`/api/teacher/${id}/mot/new`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newMOTTask),
        })
          .then(async (r) => {
            if (r.ok) {
              setCreateMOTTaskStatus("success")
              mutate(`/api/teacher/${id}/mot`)
              setSnackbarText(`De MOT taak is aangemaakt`)
              setSnackbarVisible(true)
              onClose()
            }
          })
          .catch((e) => {
            setCreateMOTTaskStatus("error")
            console.log(e)
          })
      })
      .catch((e: ValidationError) =>
        checkValidationErrors<Omit<TabMOT, "id">>(e, setnewMOTTaskErrors)
      )
  }

  const deleteSelected = (
    selected: TabMOT[],
    onClose: () => void,
    setSelected: React.Dispatch<React.SetStateAction<TabMOT[]>>
  ) => {
    setDeleteMOTTaskStatus("loading")
    fetch(`/api/teacher/${id}/mot/delete-multiple`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selected),
    })
      .then(async (r) => {
        if (r.ok) {
          setDeleteMOTTaskStatus("success")
          mutate(`/api/teacher/${id}/mot`)
          setSelected([])
          setSnackbarText(
            `De MOT ${
              selected.length > 1 ? "taken zijn" : "taak is"
            } verwijderd`
          )
          setSnackbarVisible(true)
          onClose()
        } else {
          setDeleteMOTTaskStatus("error")
        }
      })
      .catch((e) => {
        console.log(e)
        setSnackbarText(
          `Is is iets fout gegaan bij het verwijderen van de curriculum`
        )
        setSnackbarVisible(true)
      })
  }

  return (
    <>
      <div>
        <Table<TabMOT>
          title="MOT taken"
          headcells={headcells}
          data={MOTS}
          resetNewState={() => resetNewState()}
          CreateDialog={({ open, onClose }) => (
            <Dialog
              open={open}
              onClose={() => onClose()}
              title={`Nieuwe MOT taak`}
              content={() => (
                <div className="card-content">
                  <div className="column">
                    <Autocomplete
                      multiple={false}
                      options={MOTOptions}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v, r) => {
                        if (v) {
                          setNewMOTTask(v)
                        } else {
                          resetNewState()
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="MOT taken uit het curriculum"
                          variant="outlined"
                          type="text"
                          fullWidth
                        />
                      )}
                      fullWidth
                    />
                  </div>
                  <div className="column">
                    <p>Kies een uit het curriculum of maak een nieuwe aan</p>
                  </div>
                  <div className="column">
                    <TextField
                      label="Naam"
                      variant="outlined"
                      type="text"
                      name="name"
                      value={newMOTTask.name}
                      onChange={(e) =>
                        setNewMOTTask({ ...newMOTTask, name: e.target.value })
                      }
                      error={newMOTTaskErrors.some((e) => e.path === "name")}
                      helperText={
                        newMOTTaskErrors.find((e) => e.path === "name")?.message
                      }
                      fullWidth
                    />
                  </div>
                  <div className="column">
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={newMOTTask.type}
                        onChange={(e) => {
                          setNewMOTTask({
                            ...newMOTTask,
                            type: e.target.value as string,
                          })
                        }}
                      >
                        <MenuItem value={"Organisatorische"}>
                          Organisatorische
                        </MenuItem>
                        <MenuItem value={"Professionalisering"}>
                          Professionalisering
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="column">
                    <TextField
                      label="Uren"
                      variant="outlined"
                      type="number"
                      name="hours"
                      value={newMOTTask.hours}
                      onChange={(e) =>
                        setNewMOTTask({
                          ...newMOTTask,
                          hours:
                            e.target.value.length > 0
                              ? Number(e.target.value)
                              : ((e.target.value as unknown) as number),
                        })
                      }
                      error={newMOTTaskErrors.some((e) => e.path === "hours")}
                      helperText={
                        newMOTTaskErrors.find((e) => e.path === "hours")
                          ? newMOTTaskErrors.find((e) => e.path === "hours")
                              ?.message
                          : "Uren worden per OP toegewezen"
                      }
                      fullWidth
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Checkbox
                            checked={newMOTTask.active_op1}
                            onChange={(e) =>
                              setNewMOTTask({
                                ...newMOTTask,
                                active_op1: e.target.checked,
                              })
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="OP1"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            checked={newMOTTask.active_op2}
                            onChange={(e) =>
                              setNewMOTTask({
                                ...newMOTTask,
                                active_op2: e.target.checked,
                              })
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="OP2"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="bottom"
                        control={
                          <Checkbox
                            checked={newMOTTask.active_op3}
                            onChange={(e) =>
                              setNewMOTTask({
                                ...newMOTTask,
                                active_op3: e.target.checked,
                              })
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="OP3"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            checked={newMOTTask.active_op4}
                            onChange={(e) =>
                              setNewMOTTask({
                                ...newMOTTask,
                                active_op4: e.target.checked,
                              })
                            }
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label="OP4"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
              actions={() => (
                <>
                  <Button onClick={() => onClose()}>Annuleer</Button>
                  <Button
                    disabled={createMOTTaskStatus.loading}
                    onClick={() => createMOTTask(onClose)}
                    color="primary"
                    variant="contained"
                  >
                    Maak taak aan
                  </Button>
                </>
              )}
            />
          )}
          DeleteDialog={({ open, onClose, selected, setSelected }) => (
            <Dialog
              open={open}
              onClose={() => onClose()}
              title={`MOT ${
                selected.length > 1 ? "taken" : "taak"
              } verwijderen`}
              content={() => (
                <p>
                  Weet je zeker dat je de geselecteerde MOT{" "}
                  {selected.length > 1 ? "taken" : "taak"} wilt verwijderen?
                </p>
              )}
              actions={() => (
                <>
                  <Button onClick={() => onClose()}>Annuleer</Button>
                  <Button
                    disabled={deleteMOTTaskStatus.loading}
                    onClick={() =>
                      deleteSelected(selected, onClose, setSelected)
                    }
                    color="primary"
                    variant="contained"
                  >
                    Verwijder MOT {selected.length > 1 ? "taken" : "taak"}
                  </Button>
                </>
              )}
            />
          )}
          text={{
            new: `Maak een nieuwe MOT taak aan`,
            empty: `Er zijn geen MOT taken. Maak een MOT taak aan.`,
          }}
          BottomActions={() => (
            <div style={{ marginTop: "24px" }}>
              <Button
                fetchStatus={
                  [updateMOTTaskStatus, setUpdateMOTTaskStatus] as const
                }
                variant="contained"
                color="primary"
                onClick={() => saveChanges()}
              >
                Sla wijzigingen op
              </Button>
            </div>
          )}
        >
          {({ data, handleChange, key }) => (
            <>
              <TableCell
                component="th"
                scope="row"
                key={`${key}-${data.id}-name`}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  value={data.name}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      name: e.target.value,
                    }).then((data) => setMOTS(data))
                  }
                  type="text"
                  error={MOTSErrors.some(
                    (e) => e.index === key && e.path === "name"
                  )}
                  helperText={
                    MOTSErrors.find((e) => e.index === key && e.path === "name")
                      ?.message
                  }
                />
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-type`}>
                <FormControl variant="outlined" size="small">
                  <Select
                    value={data.type}
                    onChange={(e) => {
                      handleChange({
                        ...data,
                        type: e.target.value as string,
                      }).then((data) => setMOTS(data))
                    }}
                  >
                    <MenuItem value={"Organisatorische"}>
                      Organisatorische
                    </MenuItem>
                    <MenuItem value={"Professionalisering"}>
                      Professionalisering
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-hours`}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={data.hours}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      hours:
                        e.target.value.length > 0
                          ? Number(e.target.value)
                          : ((e.target.value as unknown) as number),
                    }).then((data) => setMOTS(data))
                  }
                  type="number"
                  error={MOTSErrors.some(
                    (e) => e.index === key && e.path === "hours"
                  )}
                  helperText={
                    MOTSErrors.find(
                      (e) => e.index === key && e.path === "hours"
                    )?.message
                  }
                />
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-op1`}>
                <Checkbox
                  checked={data.active_op1}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      active_op1: e.target.checked,
                    }).then((data) => setMOTS(data))
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-op2`}>
                <Checkbox
                  checked={data.active_op2}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      active_op2: e.target.checked,
                    }).then((data) => setMOTS(data))
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-op3`}>
                <Checkbox
                  checked={data.active_op3}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      active_op3: e.target.checked,
                    }).then((data) => setMOTS(data))
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
              <TableCell scope="row" key={`${key}-${data.id}-op4`}>
                <Checkbox
                  checked={data.active_op4}
                  onChange={(e) =>
                    handleChange({
                      ...data,
                      active_op4: e.target.checked,
                    }).then((data) => setMOTS(data))
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </>
          )}
        </Table>
      </div>
    </>
  )
}

export const newMOTTaskValidationObject = object<Omit<TabMOT, "id">>({
  name: string().required("Dit veld is verplicht"),
  type: string().required(),
  hours: number()
    .min(0, "Uren kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  active_op1: boolean().required(),
  active_op2: boolean().required(),
  active_op3: boolean().required(),
  active_op4: boolean().required(),
})

export const MOTTasksValidationObject = object<TabMOT>({
  id: number().required(),
  name: string().required("Dit veld is verplicht"),
  type: string().required(),
  hours: number()
    .min(0, "Uren kan geen negatief getal zijn")
    .typeError("Dit veld is verplicht")
    .required(),
  active_op1: boolean().required(),
  active_op2: boolean().required(),
  active_op3: boolean().required(),
  active_op4: boolean().required(),
})

export const MOTTasksArrayValidationObject = array<TabMOT>().of(
  MOTTasksValidationObject
)
