import "./Layout.css"
import React, { useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useGetAllCurricula } from "../../api/app"
import {
  createDialogState,
  currentCurriculumState,
  searchbarState,
  selectedCurriculumForDetailsState,
  selectedCourseForDetailsState,
} from "../../state/app"
import { useGetAllCurriculumsAndKinds } from "../../api/planning"
import {
  currentCurriculumAndKindState,
  planningViewState,
  currentCurriculumAndKindObject,
} from "../../state/planning"

import Skeleton from "@material-ui/lab/Skeleton"
import MenuItem from "@material-ui/core/MenuItem"
import ListSubheader from "@material-ui/core/ListSubheader"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"

export const Sidebar: React.FC = ({ children }) => {
  return <div id="sidebar">{children}</div>
}

type TopbarProps = {
  currentCurriculum?: boolean
  currentCurriculumAndKind?: boolean
  searchbar?: boolean
  createDialog?: string
}

export const Topbar = (props: React.PropsWithChildren<TopbarProps>) => {
  const {
    currentCurriculum,
    currentCurriculumAndKind,
    searchbar,
    createDialog,
  } = props

  return (
    <div
      id="topbar"
      className={`normal topbar-normal-${currentCurriculum} topbar-normal-${searchbar} topbar-normal-${createDialog}`}
    >
      <div id="topbar-normal-left">
        {currentCurriculum && <CurrentCurriculum />}
        {currentCurriculumAndKind && <CurrentCurriculumAndKinds />}
        {searchbar && <Searchbar />}
      </div>
      <div id="topbar-normal-right">
        {createDialog && (
          <CreateDialogButton>{createDialog}</CreateDialogButton>
        )}
      </div>
    </div>
  )
}

const CurrentCurriculum = () => {
  const [currentCurriculum, setCurrentCurriculum] = useRecoilState(
    currentCurriculumState
  )
  const setCurrentCurriculumAndKinds = useSetRecoilState(
    currentCurriculumAndKindState
  )
  const selectedCurriculumForDetails = useSetRecoilState(
    selectedCurriculumForDetailsState
  )
  const selectedCourseForDetails = useSetRecoilState(
    selectedCourseForDetailsState
  )
  const { data, error } = useGetAllCurricula()

  useEffect(() => {
    if (!!data && !error && data.length > 0) {
      if (currentCurriculum.id === -1) {
        setCurrentCurriculum({
          id: data[0].id,
          name: data[0].name,
          kind: data[0].kinds[0],
        })
        setCurrentCurriculumAndKinds(`${data[0].name}-${data[0].kinds[0]}`)
      }
    }
  }, [data, error, setCurrentCurriculum])

  if (error) return <div>err</div>

  if (!data) return <Skeleton variant="rect" width={150} height={30} />

  return (
    <FormControl size="small" id="topbar-current-curriculum" color="primary">
      <Select
        variant="outlined"
        value={currentCurriculum.name}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          selectedCurriculumForDetails(-1)
          selectedCourseForDetails(-1)
          setCurrentCurriculum({
            ...currentCurriculum,
            id: data.find((v) => v.name === event.target.value)!.id,
            name: event.target.value as string,
          })
        }}
      >
        {data.map((curriculum, key) => (
          <MenuItem value={curriculum.name} key={key}>
            {curriculum.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const Searchbar = () => {
  const [searchbar, setSearchbar] = useRecoilState(searchbarState)
  return (
    <input
      id="topbar-searchbar"
      type="search"
      value={searchbar}
      placeholder="Zoeken"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setSearchbar(event.target.value)
      }
    />
  )
}

const CreateDialogButton: React.FC = ({ children }) => {
  const setCreateDialogState = useSetRecoilState(createDialogState)
  return (
    <Button
      id="topbar-create-dialog-button"
      variant="contained"
      color="primary"
      disableElevation
      onClick={() => setCreateDialogState(true)}
    >
      {children}
    </Button>
  )
}

type TopbarPlanningProps = {}

export const TopbarPlanning = (
  props: React.PropsWithChildren<TopbarPlanningProps>
) => {
  return (
    <div id="topbar" className="planning">
      <div id="topbar-planning-left">
        <CurrentCurriculumAndKinds />
      </div>
      <div id="topbar-planning-right">
        <SwitchPlanningView />
      </div>
    </div>
  )
}

const CurrentCurriculumAndKinds = () => {
  const [currentCurriculum, setCurrentCurriculum] = useRecoilState(
    currentCurriculumState
  )
  const setCurrentCurriculumAndKinds = useSetRecoilState(
    currentCurriculumAndKindState
  )
  const currentCurriculumObject = useRecoilValue(currentCurriculumAndKindObject)
  const { data, error } = useGetAllCurriculumsAndKinds()

  useEffect(() => {
    if (!!data && !error) {
      if (data.length > 0) {
        if (currentCurriculum.id === -1) {
          const dataSorted = data.sort((a, b) =>
            a.curriculum < b.curriculum ? 1 : -1
          )

          setCurrentCurriculum({
            id: dataSorted[0].id,
            name: dataSorted[0].curriculum,
            kind: dataSorted[0].kinds[0],
          })
          setCurrentCurriculumAndKinds(
            `${dataSorted[0].curriculum}-${dataSorted[0].kinds[0]}`
          )
        }
      }
    }
  }, [data, error])

  if (error) return <div>err</div>

  if (!data) return <Skeleton variant="rect" width={150} height={30} />

  return (
    <FormControl
      size="small"
      id="topbar-current-curriculum-and-kind"
      color="primary"
    >
      <Select
        variant="outlined"
        value={`${currentCurriculum.name}-${currentCurriculum.kind}`}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          let value = event.target.value as string
          let curriculum = value.slice(0, 5)
          let kind = value.slice(6)
          let id = data.findIndex((v) => v.curriculum === curriculum)

          setCurrentCurriculum({
            id: data[id].id,
            name: curriculum,
            kind: kind,
          })
          setCurrentCurriculumAndKinds(`${curriculum}-${kind}`)
        }}
      >
        {data
          ?.sort((a, b) => (a.curriculum < b.curriculum ? 1 : -1))
          .map((curriculum) => {
            let items: any = []
            items.push(<ListSubheader>{curriculum.curriculum}</ListSubheader>)

            curriculum.kinds.forEach((kind, key) => {
              items.push(
                <MenuItem
                  key={`${key}-${curriculum.id}`}
                  value={`${curriculum.curriculum}-${kind}`}
                >
                  {curriculum.curriculum} - {kind}
                </MenuItem>
              )
            })

            return items
          })}
      </Select>
    </FormControl>
  )
}

const SwitchPlanningView = () => {
  const [view, setView] = useRecoilState(planningViewState)

  return (
    <FormControl variant="outlined" size="small" color="primary">
      <Select
        value={view}
        onChange={(e) => setView(e.target.value as "study-year" | "op")}
      >
        <MenuItem value={"study-year"}>Elk studiejaar met een OP</MenuItem>
        <MenuItem value={"op"}>Elk OP met een studiejaar</MenuItem>
      </Select>
    </FormControl>
  )
}
