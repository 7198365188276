import {
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  lighten,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import useSWR, { mutate } from "swr"
import { array, bool, number, object, string, ValidationError } from "yup"
import { snackbarTextState, snackbarVisibleState } from "../../../state/app"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import AddIcon from "@material-ui/icons/AddRounded"
import {
  checkValidationErrors,
  Errors,
  ErrorsArray,
  useFetchState,
} from "../../../utils"
import { ErrorState } from "../../ErrorState"
import { Loader } from "../../Loader"
import { Dialog } from "../../Dialog"
import { Button } from "../../Buttons"
import HelpRoundedIcon from "@material-ui/icons/HelpRounded"
import InformationCard from "../../InformationCard"

type TabAanstellingType = {
  contract: string
  default_fte: number
  fte_op1: number
  fte_op2: number
  fte_op3: number
  fte_op4: number
  default_di: number
  di_op1: number
  di_op2: number
  di_op3: number
  di_op4: number
  organisational: number
  professional: number
  hours_subtracted_from_available_hours: number
  secondments: DetacheringType[]
}

export const TabAanstelling = () => {
  let { id }: { id: string } = useParams()

  const snackbarText = useSetRecoilState(snackbarTextState)
  const snackbarVisible = useSetRecoilState(snackbarVisibleState)

  const [updateAanstellingStatus, setUpdateAanstellingStatus] = useFetchState()
  const [tabAanstellingErrors, setTabAanstellingErrors] = useState<
    Errors<TabAanstellingType>[]
  >([])
  const [errorsDetachering, setErrorsDetachering] = useState<
    ErrorsArray<DetacheringType>[]
  >([])
  const [tabAanstelling, setTabAanstelling] = useState<TabAanstellingType>({
    contract: "",
    default_fte: -1,
    fte_op1: -1,
    fte_op2: -1,
    fte_op3: -1,
    fte_op4: -1,
    default_di: -1,
    di_op1: -1,
    di_op2: -1,
    di_op3: -1,
    di_op4: -1,
    organisational: -1,
    professional: -1,
    hours_subtracted_from_available_hours: -1,
    secondments: [],
  })

  const { data, error } = useSWR<TabAanstellingType>(
    `/api/teacher/${id}/aanstelling`
  )

  useEffect(() => {
    if (typeof data !== "undefined") {
      setTabAanstelling(data)
    }
  }, [data])

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setTabAanstelling({
      ...tabAanstelling,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (process.env.NODE_ENV === "development")
      console.log(`${e.target.name}: ${e.target.value}`)

    setTabAanstelling({
      ...tabAanstelling,
      [e.target.name]:
        e.target.value.length > 0 ? Number(e.target.value) : e.target.value,
    })
  }

  const updateAanstelling = () => {
    tabAanstellingValidationObject
      .validate(tabAanstelling, { abortEarly: false })
      .then(() => {
        setUpdateAanstellingStatus("loading")
        setTabAanstellingErrors([])
        fetch(`/api/teacher/${id}/aanstelling/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tabAanstelling),
        }).then(async (r) => {
          if (r.ok) {
            setUpdateAanstellingStatus("success")
            snackbarText("De wijzigingen zijn opgeslagen")
            snackbarVisible(true)
            mutate(`/api/teacher/${id}/aanstelling`)
            mutate(`/api/teacher/${id}/taaktoedeling`)
          } else {
            setUpdateAanstellingStatus("error")
            console.log(r.status)
            snackbarText(
              "Er was een probleem met het opslaan van de aanstelling"
            )
            snackbarVisible(true)
          }
        })
      })
      .catch((e: ValidationError) =>
        checkValidationErrors(e, setTabAanstellingErrors)
      )
  }

  if (!data) return <Loader />

  if (error) return <ErrorState />

  return (
    <>
      <div id="teacher-aanstelling-top-fields-container">
        <FormControl
          fullWidth
          variant="outlined"
          style={{ width: "210px", marginRight: "10px" }}
        >
          <InputLabel id="teacher-contract-label">Type aanstelling</InputLabel>
          <Select
            labelId="teacher-contract-label"
            id="teacher-contract"
            value={tabAanstelling.contract}
            onChange={(e) =>
              setTabAanstelling({
                ...tabAanstelling,
                contract: e.target.value as TabAanstellingType["contract"],
              })
            }
          >
            <MenuItem value={"Vast"}>Vast</MenuItem>
            <MenuItem value={"Tijdelijk"}>Tijdelijk</MenuItem>
          </Select>
        </FormControl>
      </div>
      <h6 className="subkop">Organisatorische en professionalisering</h6>
      <div>
        <InformationCard
          type="info"
          disableIcon
          style={{ marginBottom: "12px" }}
          content={() => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "12px" }}
              >
                <TextField
                  style={{ width: "200px" }}
                  variant="outlined"
                  type="number"
                  label="Organisatorische taak ALG (%)"
                  name="organisational"
                  value={tabAanstelling.organisational}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      setTabAanstelling({
                        ...tabAanstelling,
                        organisational: 0,
                      })
                    } else {
                      handleChangeNumber(e)
                    }
                  }}
                  error={tabAanstellingErrors.some(
                    (e) => e.path === "organisational"
                  )}
                  helperText={
                    tabAanstellingErrors.find(
                      (e) => e.path === "organisational"
                    )?.message
                  }
                />
                <TextField
                  fullWidth
                  style={{ width: "200px" }}
                  variant="outlined"
                  type="number"
                  label="Basisrecht professionalisering (%)"
                  name="professional"
                  value={tabAanstelling.professional}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      setTabAanstelling({ ...tabAanstelling, professional: 0 })
                    } else {
                      handleChangeNumber(e)
                    }
                  }}
                  error={tabAanstellingErrors.some(
                    (e) => e.path === "professional"
                  )}
                  helperText={
                    tabAanstellingErrors.find((e) => e.path === "professional")
                      ?.message
                  }
                />
              </div>
              <span style={{ marginTop: "12px" }}>
                Vul hier het percentage in voor het hele curriculum.
              </span>
            </div>
          )}
        />
      </div>
      <h6 className="subkop">
        FTE per periode (Standaard per OP: {data.default_fte})
      </h6>
      <div>
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP1"
          name="fte_op1"
          value={tabAanstelling.fte_op1}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "fte_op1")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "fte_op1")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP2"
          name="fte_op2"
          value={tabAanstelling.fte_op2}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "fte_op2")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "fte_op2")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP3"
          name="fte_op3"
          value={tabAanstelling.fte_op3}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "fte_op3")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "fte_op3")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP4"
          name="fte_op4"
          value={tabAanstelling.fte_op4}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "fte_op4")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "fte_op4")?.message
          }
        />
      </div>
      <h6 className="subkop">DI per periode in uren</h6>
      <div>
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP1"
          name="di_op1"
          value={tabAanstelling.di_op1}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "di_op1")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "di_op1")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP2"
          name="di_op2"
          value={tabAanstelling.di_op2}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "di_op2")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "di_op2")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP3"
          name="di_op3"
          value={tabAanstelling.di_op3}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "di_op3")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "di_op3")?.message
          }
        />
        <TextField
          style={{ width: "100px", marginRight: "10px" }}
          variant="outlined"
          type="number"
          label="OP4"
          name="di_op4"
          value={tabAanstelling.di_op4}
          onChange={(e) => handleChangeNumber(e)}
          error={tabAanstellingErrors.some((e) => e.path === "di_op4")}
          helperText={
            tabAanstellingErrors.find((e) => e.path === "di_op4")?.message
          }
        />
      </div>
      <Divider />
      <Detachering
        errors={errorsDetachering}
        detachering={tabAanstelling.secondments}
        setDetachering={(deta: DetacheringType[]) =>
          setTabAanstelling({ ...tabAanstelling, secondments: deta })
        }
      />
      <div>
        <Button
          fetchStatus={[updateAanstellingStatus, setUpdateAanstellingStatus]}
          color="primary"
          variant="contained"
          onClick={() => updateAanstelling()}
        >
          Sla wijzigingen op
        </Button>
      </div>
      <Divider />
      <h6 className="subkop">Taaktoedeling</h6>
      <InformationCard
        type="info"
        style={{ marginTop: "12px" }}
        content={() => (
          <>
            <span>
              Uren worden op hele getallen afgerond en waarnodig naar beneden.
            </span>
          </>
        )}
      />
      <TaaktoedelingTable />
    </>
  )
}

const tabAanstellingValidationObject = object<
  Omit<
    TabAanstellingType,
    "contract" | "default_fte" | "default_di" | "secondments"
  >
>({
  fte_op1: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  fte_op2: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  fte_op3: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  fte_op4: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  di_op1: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  di_op2: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  di_op3: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  di_op4: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  organisational: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  professional: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  hours_subtracted_from_available_hours: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
})

export const DetacheringValidationObject = object<DetacheringType>({
  id: number().required(),
  hours: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  reason: string().required("Dit veld is verplicht"),
  active_op1: bool(),
  active_op2: bool(),
  active_op3: bool(),
  active_op4: bool(),
})

export const DetacheringArrayValidationObject = array<DetacheringType>().of(
  DetacheringValidationObject
)

export const newDetacheringValidationObject = object<newDetacheringType>({
  hours: number()
    .typeError("Dit veld is verplicht")
    .required("Dit veld is verplicht"),
  reason: string().required("Dit veld is verplicht"),
  active_op1: bool(),
  active_op2: bool(),
  active_op3: bool(),
  active_op4: bool(),
})

type DetacheringType = {
  id: number
  hours: number
  reason: string
  active_op1: boolean
  active_op2: boolean
  active_op3: boolean
  active_op4: boolean
}

interface HeadCell {
  disablePadding: boolean
  id: keyof DetacheringType
  label: string
  numeric: boolean
  minWidth: number
}

const headCells: HeadCell[] = [
  {
    id: "hours",
    numeric: true,
    disablePadding: false,
    label: "Uren",
    minWidth: 100,
  },
  {
    id: "reason",
    numeric: false,
    disablePadding: false,
    label: "Reden",
    minWidth: 200,
  },
  {
    id: "active_op1",
    numeric: false,
    disablePadding: false,
    label: "OP1",
    minWidth: 50,
  },
  {
    id: "active_op2",
    numeric: false,
    disablePadding: false,
    label: "OP2",
    minWidth: 50,
  },
  {
    id: "active_op3",
    numeric: false,
    disablePadding: false,
    label: "OP3",
    minWidth: 50,
  },
  {
    id: "active_op4",
    numeric: false,
    disablePadding: false,
    label: "OP4",
    minWidth: 50,
  },
]

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ minWidth: `${headCell.minWidth}px` }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  setDeleteConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
)

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected, setDeleteConfirmationDialog } = props

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Detachering
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Verwijder">
          <IconButton
            aria-label="Verwijder"
            onClick={() => setDeleteConfirmationDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

type DetacheringProps = {
  errors: { message: string; index: number; path: string }[]
  detachering: DetacheringType[]
  setDetachering: (detachering: DetacheringType[]) => void
}

export type newDetacheringType = {
  hours: number
  reason: string
  active_op1: boolean
  active_op2: boolean
  active_op3: boolean
  active_op4: boolean
}

const Detachering = (props: DetacheringProps) => {
  let { id }: { id: string } = useParams()
  const { errors, detachering, setDetachering } = props
  const setSnackbarText = useSetRecoilState(snackbarTextState)
  const setSnackbarVisible = useSetRecoilState(snackbarVisibleState)
  const [deleteSelectedStatus, setDeleteSelectedStatus] = useFetchState()
  const [createDetacheringStatus, setCreateDetacheringStatus] = useFetchState()
  const [selected, setSelected] = useState<DetacheringType[]>([])
  const [
    createDetacheringDialog,
    setCreateDetacheringDialog,
  ] = useState<boolean>(false)
  const [newDetachering, setNewDetachering] = useState<newDetacheringType>({
    hours: 0,
    reason: "",
    active_op1: false,
    active_op2: false,
    active_op3: false,
    active_op4: false,
  })
  const [newDetacheringErrors, setNewDetacheringErrors] = useState<
    Errors<newDetacheringType>[]
  >([])
  const [
    deleteConfirmationDialog,
    setDeleteConfirmationDialog,
  ] = useState<boolean>(false)

  useEffect(() => {
    if (!createDetacheringDialog) {
      setNewDetachering({
        hours: 0,
        reason: "",
        active_op1: false,
        active_op2: false,
        active_op3: false,
        active_op4: false,
      })
      setNewDetacheringErrors([])
    }
  }, [createDetacheringDialog])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = detachering.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (
    event: React.MouseEvent<unknown>,
    task: DetacheringType
  ) => {
    const selectedIndex = selected.indexOf(task)
    let newSelected: DetacheringType[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, task)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChange = (d: DetacheringType) => {
    if (process.env.NODE_ENV === "development")
      console.log(`[${d.id}] ${d.reason}: ${d.hours}`)

    let new_detachering = detachering
    const index = new_detachering.findIndex((t) => t.id === d.id)
    new_detachering[index] = d

    setDetachering(new_detachering)
  }

  const createDetachering = () => {
    newDetacheringValidationObject
      .validate(newDetachering, { abortEarly: false })
      .then(() => {
        setCreateDetacheringStatus("loading")
        setNewDetacheringErrors([])
        fetch(`/api/teacher/${id}/detachering`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newDetachering),
        })
          .then(async (r) => {
            if (r.ok) {
              setCreateDetacheringStatus("success")
              mutate(`/api/teacher/${id}/aanstelling`)
              mutate(`/api/teacher/${id}/taaktoedeling`)
              setCreateDetacheringDialog(false)
              setSnackbarText("De detachering is aangemaakt")
              setSnackbarVisible(true)
            } else {
              setCreateDetacheringStatus("error")
            }
          })
          .catch((e) => {
            setCreateDetacheringStatus("error")
            console.log(e)
          })
      })
      .catch((err: ValidationError) =>
        checkValidationErrors<newDetacheringType>(err, setNewDetacheringErrors)
      )
  }

  const deleteSelected = () => {
    setDeleteSelectedStatus("loading")
    const payload = selected
    fetch(`/api/teacher/${id}/detachering/delete-multiple`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((r) => {
        if (r.ok) {
          setDeleteSelectedStatus("success")
          setDeleteConfirmationDialog(false)
          setSnackbarText(
            `De geselecteerde detachering${
              selected.length > 1 ? "en zijn" : " zijn"
            } verwijderd.`
          )
          setSnackbarVisible(true)
          setSelected([])
          mutate(`/api/teacher/${id}/aanstelling`)
          mutate(`/api/teacher/${id}/taaktoedeling`)
        } else {
          setDeleteSelectedStatus("error")
        }
      })
      .catch((e) => {
        setDeleteSelectedStatus("error")
        console.log(e)
        setSnackbarText(
          `Is is iets fout gegaan bij het verwijderen van de detachering${
            selected.length > 1 ? "en" : ""
          }.`
        )
        setSnackbarVisible(true)
      })
  }

  const isSelected = (detachering: DetacheringType) =>
    selected.indexOf(detachering) !== -1

  return (
    <Paper elevation={0} variant="outlined" className="detachering-table">
      <EnhancedTableToolbar
        numSelected={selected.length}
        setDeleteConfirmationDialog={setDeleteConfirmationDialog}
      />
      <Divider />
      <TableContainer>
        <Table>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={detachering.length}
          />
          <TableBody>
            {detachering.length > 0 ? (
              <>
                <TableRow>
                  <TableCell padding="checkbox">
                    <IconButton
                      onClick={() => setCreateDetacheringDialog(true)}
                    >
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    colSpan={headCells.length + 1}
                    component="th"
                    scope="row"
                  >
                    Maak een nieuwe detachering aan
                  </TableCell>
                </TableRow>
                {detachering.map((deta, key) => {
                  const isItemSelected = isSelected(deta)

                  return (
                    <TableRow key={key}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, deta)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          variant="outlined"
                          size="small"
                          value={deta.hours}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              hours:
                                e.target.value.length > 0
                                  ? Number(e.target.value)
                                  : ((e.target.value as unknown) as number),
                            })
                          }
                          type="number"
                          error={errors.some(
                            (e) => e.index === key && e.path === "hours"
                          )}
                          helperText={
                            errors.find(
                              (e) => e.index === key && e.path === "hours"
                            )?.message
                          }
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <TextField
                          variant="outlined"
                          size="small"
                          value={deta.reason}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              reason: e.target.value,
                            })
                          }
                          type="text"
                          error={errors.some(
                            (e) => e.index === key && e.path === "reason"
                          )}
                          helperText={
                            errors.find(
                              (e) => e.index === key && e.path === "reason"
                            )?.message
                          }
                          multiline
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={deta.active_op1}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              active_op1: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={deta.active_op2}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              active_op2: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={deta.active_op3}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              active_op3: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                      <TableCell scope="row">
                        <Checkbox
                          checked={deta.active_op4}
                          onChange={(e) =>
                            handleChange({
                              ...deta,
                              active_op4: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell padding="checkbox">
                    <IconButton
                      onClick={() => setCreateDetacheringDialog(true)}
                    >
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    colSpan={headCells.length + 1}
                    component="th"
                    scope="row"
                  >
                    Maak een nieuwe detachering aan
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={headCells.length + 1}>
                    Er zijn geen detacheringen. Maak een detachering aan.
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteConfirmationDialog}
        onClose={() => setDeleteConfirmationDialog(false)}
        title={`Detachering${selected.length > 1 ? "en" : ""} verwijderen`}
        content={() => (
          <p>
            Weet je zeker dat je de geselecteerde detachering
            {selected.length > 1 ? "en" : ""} wilt verwijderen?
          </p>
        )}
        actions={() => (
          <>
            <Button onClick={() => setDeleteConfirmationDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={deleteSelectedStatus.loading}
              onClick={() => deleteSelected()}
              color="primary"
              variant="contained"
            >
              Verwijder detachering{selected.length > 1 ? "en" : ""}
            </Button>
          </>
        )}
      />
      <Dialog
        open={createDetacheringDialog}
        onClose={() => setCreateDetacheringDialog(false)}
        title={`Nieuwe detachering`}
        content={() => (
          <div className="card-content">
            <div className="column">
              <TextField
                label="Uren"
                variant="outlined"
                type="number"
                name="hours"
                value={newDetachering.hours}
                onChange={(e) =>
                  setNewDetachering({
                    ...newDetachering,
                    hours:
                      e.target.value.length > 0
                        ? Number(e.target.value)
                        : ((e.target.value as unknown) as number),
                  })
                }
                error={newDetacheringErrors.some((e) => e.path === "hours")}
                helperText={
                  newDetacheringErrors.find((e) => e.path === "hours")?.message
                }
                fullWidth
              />
            </div>
            <div className="column">
              <TextField
                label="Reden"
                variant="outlined"
                type="text"
                name="reason"
                value={newDetachering.reason}
                onChange={(e) =>
                  setNewDetachering({
                    ...newDetachering,
                    reason: e.target.value,
                  })
                }
                error={newDetacheringErrors.some((e) => e.path === "reason")}
                helperText={
                  newDetacheringErrors.find((e) => e.path === "reason")?.message
                }
                fullWidth
                multiline
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={
                    <Checkbox
                      checked={newDetachering.active_op1}
                      onChange={(e) =>
                        setNewDetachering({
                          ...newDetachering,
                          active_op1: e.target.checked,
                        })
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP1"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={newDetachering.active_op2}
                      onChange={(e) =>
                        setNewDetachering({
                          ...newDetachering,
                          active_op2: e.target.checked,
                        })
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP2"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="bottom"
                  control={
                    <Checkbox
                      checked={newDetachering.active_op3}
                      onChange={(e) =>
                        setNewDetachering({
                          ...newDetachering,
                          active_op3: e.target.checked,
                        })
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP3"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={newDetachering.active_op4}
                      onChange={(e) =>
                        setNewDetachering({
                          ...newDetachering,
                          active_op4: e.target.checked,
                        })
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="OP4"
                  labelPlacement="top"
                />
              </FormGroup>
            </div>
          </div>
        )}
        actions={() => (
          <>
            <Button onClick={() => setCreateDetacheringDialog(false)}>
              Annuleer
            </Button>
            <Button
              disabled={createDetacheringStatus.loading}
              onClick={() => createDetachering()}
              color="primary"
              variant="contained"
            >
              Maak detachering aan
            </Button>
          </>
        )}
      />
    </Paper>
  )
}

export type TaakToedelingType = {
  total_available_hours: number
  total_available_hours_op1: number
  total_available_hours_op2: number
  total_available_hours_op3: number
  total_available_hours_op4: number
  di: TaakToedelingTypeDetails
  secondments: TaakToedelingTypeDetails
  organizational_tasks: TaakToedelingTypeDetails
  organization_tasks_other: TaakToedelingTypeDetails
  professionalization_tasks: TaakToedelingTypeDetails
  professionalization_tasks_other: TaakToedelingTypeDetails
  contact_time: TaakToedelingTypeDetails
  contact_time_with_factor: TaakToedelingTypeDetails
  supervision: {
    interns: TaakToedelingTypeDetails
    graduates: TaakToedelingTypeDetails
  }
  mean_raise_factor: number
  oddment_total: number
  oddment_op1: number
  oddment_op2: number
  oddment_op3: number
  oddment_op4: number
}

type TaakToedelingTypeDetails = {
  total: number
  op1: number
  op2: number
  op3: number
  op4: number
}

const TaaktoedelingTable = () => {
  let { id }: { id: string } = useParams()
  const { data, error } = useSWR<TaakToedelingType>(
    `/api/teacher/${id}/taaktoedeling`
  )

  if (!data) return <Loader />

  if (error) return <ErrorState />

  return (
    <div id="tab-aanstelling-taaktoedeling-container">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="right">Totaal</TableCell>
            <TableCell align="right">OP1</TableCell>
            <TableCell align="right">OP2</TableCell>
            <TableCell align="right">OP3</TableCell>
            <TableCell align="right">OP4</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
              Beschikbare uren
            </TableCell>
            <TableCell align="right">{data.total_available_hours}</TableCell>
            <TableCell align="right">
              {data.total_available_hours_op1}
            </TableCell>
            <TableCell align="right">
              {data.total_available_hours_op2}
            </TableCell>
            <TableCell align="right">
              {data.total_available_hours_op3}
            </TableCell>
            <TableCell align="right">
              {data.total_available_hours_op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
              DI
            </TableCell>
            <TableCell align="right">{data.di.total}</TableCell>
            <TableCell align="right">{data.di.op1}</TableCell>
            <TableCell align="right">{data.di.op2}</TableCell>
            <TableCell align="right">{data.di.op3}</TableCell>
            <TableCell align="right">{data.di.op4}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
              Detachering
            </TableCell>
            <TableCell align="right">{data.secondments.total}</TableCell>
            <TableCell align="right">{data.secondments.op1}</TableCell>
            <TableCell align="right">{data.secondments.op2}</TableCell>
            <TableCell align="right">{data.secondments.op3}</TableCell>
            <TableCell align="right">{data.secondments.op4}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Organisatorische taak
            </TableCell>
            <TableCell component="th" scope="row">
              Organisatorische taak ALG
            </TableCell>
            <TableCell align="right">
              {data.organizational_tasks.total}
            </TableCell>
            <TableCell align="right">{data.organizational_tasks.op1}</TableCell>
            <TableCell align="right">{data.organizational_tasks.op2}</TableCell>
            <TableCell align="right">{data.organizational_tasks.op3}</TableCell>
            <TableCell align="right">{data.organizational_tasks.op4}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              Overige taken
            </TableCell>
            <TableCell align="right">
              {data.organization_tasks_other.total}
            </TableCell>
            <TableCell align="right">
              {data.organization_tasks_other.op1}
            </TableCell>
            <TableCell align="right">
              {data.organization_tasks_other.op2}
            </TableCell>
            <TableCell align="right">
              {data.organization_tasks_other.op3}
            </TableCell>
            <TableCell align="right">
              {data.organization_tasks_other.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Professionaliseringstaak
            </TableCell>
            <TableCell component="th" scope="row">
              Basisrecht professionalisering
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks.total}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks.op1}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks.op2}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks.op3}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              Overige taken
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks_other.total}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks_other.op1}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks_other.op2}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks_other.op3}
            </TableCell>
            <TableCell align="right">
              {data.professionalization_tasks_other.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Onderwijstaken
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              Contacttijd
              <Tooltip
                title="Contacttijd zonder opslag wordt niet meegenomen in de berekening"
                style={{ marginLeft: "6px" }}
              >
                <HelpRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </TableCell>
            <TableCell
              align="right"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              {data.contact_time.total}
            </TableCell>
            <TableCell
              align="right"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              {data.contact_time.op1}
            </TableCell>
            <TableCell
              align="right"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              {data.contact_time.op2}
            </TableCell>
            <TableCell
              align="right"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              {data.contact_time.op3}
            </TableCell>
            <TableCell
              align="right"
              className="tab-aanstelling-taaktoedeling-not-in-calculation"
            >
              {data.contact_time.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              Contacttijd inclusief opslag
            </TableCell>
            <TableCell align="right">
              {data.contact_time_with_factor.total}
            </TableCell>
            <TableCell align="right">
              {data.contact_time_with_factor.op1}
            </TableCell>
            <TableCell align="right">
              {data.contact_time_with_factor.op2}
            </TableCell>
            <TableCell align="right">
              {data.contact_time_with_factor.op3}
            </TableCell>
            <TableCell align="right">
              {data.contact_time_with_factor.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Begeleiding
            </TableCell>
            <TableCell component="th" scope="row">
              Stagiairs
            </TableCell>
            <TableCell align="right">
              {data.supervision.interns.total}
            </TableCell>
            <TableCell align="right">{data.supervision.interns.op1}</TableCell>
            <TableCell align="right">{data.supervision.interns.op2}</TableCell>
            <TableCell align="right">{data.supervision.interns.op3}</TableCell>
            <TableCell align="right">{data.supervision.interns.op4}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              Afstudeerders
            </TableCell>
            <TableCell align="right">
              {data.supervision.graduates.total}
            </TableCell>
            <TableCell align="right">
              {data.supervision.graduates.op1}
            </TableCell>
            <TableCell align="right">
              {data.supervision.graduates.op2}
            </TableCell>
            <TableCell align="right">
              {data.supervision.graduates.op3}
            </TableCell>
            <TableCell align="right">
              {data.supervision.graduates.op4}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2}>
              Restant beschikbare uren
            </TableCell>
            <TableCell align="right">{data.oddment_total}</TableCell>
            <TableCell align="right">{data.oddment_op1}</TableCell>
            <TableCell align="right">{data.oddment_op2}</TableCell>
            <TableCell align="right">{data.oddment_op3}</TableCell>
            <TableCell align="right">{data.oddment_op4}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
